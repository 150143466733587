import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
    
    },
    redAvatar: {
      backgroundColor: theme.palette.error.main
    },
    greenAvatar: {
      backgroundColor: theme.palette.success.main
    },
    yellowAvatar: {
      backgroundColor: theme.palette.warning.main
    },
    dialogContentRow: {
      margin: theme.spacing(1),
    },
    formularEditorRow: {
      // maxWidth: '860px'
    },
    dialogContentGrid:{
      flexGrow: 1
    }
  })
);

export default useStyles;