import React, { FunctionComponent } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  LinearProgress,
  Select,
  MenuItem,
  FormControl
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  ArrowBackIos as ArrowBackIcon,
  Brightness4 as SwitchToDarkModeIcon,
  Brightness7 as SwitchToLightModeIcon,
} from "@material-ui/icons";

import useStyles from "./Header.styles";
import classNames from "classnames";
import { RootState } from "../../store/reduxStore";
import { useSelector } from "react-redux";
import HeaderInfo from "./HeaderInfo";
import { useTranslation } from 'react-i18next';
import { Person, ViewType } from '@microsoft/mgt-react';

export type HeaderProps = {
  handleDrawerToggle: () => void
  darkState: boolean
  setDarkState: (setDarkState: boolean) => void
  open: boolean
};

const Header: FunctionComponent<HeaderProps> = ({
  handleDrawerToggle,
  open,
  darkState,
  setDarkState,
}) => {
  const classes = useStyles();
  const isPageLoading: boolean = useSelector((state: RootState) => state.ui.isPageLoading);
  const { i18n } = useTranslation('StartPage', { useSuspense: false });

  const changeLanguage = (event: React.ChangeEvent<{ value: unknown }>) => {
    i18n.changeLanguage(event.target.value as string);
  };

  return (
    <AppBar position="fixed" className={classes.selfservice_appBar}>
      {
        isPageLoading &&
        <div className={classes.selfservice_loadingContainer}>
          <LinearProgress color="secondary" />
        </div>
      }
      <Toolbar className={classes.selfservice_toolbar}>
        <IconButton
          color="inherit"
          onClick={handleDrawerToggle}
          className={classNames(
            classes.selfservice_headerMenuButton,
            classes.selfservice_headerMenuButtonCollapse
          )}
        >
          {open ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.selfservice_headerIcon,
                  classes.selfservice_headerIconCollapse
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.selfservice_headerIcon,
                  classes.selfservice_headerIconCollapse
                ),
              }}
            />
          )}
        </IconButton>
        <div className={classes.selfservice_logotype}>
          <HeaderInfo />
        </div>
        <div className={classes.selfservice_grow} />
        <FormControl className={classes.selfservice_formControl}>
          <Select
            labelId="language-selector-label"
            id="language-selector"
            value={i18n.language ?? "de-DE"}
            onChange={changeLanguage}
          >
            <MenuItem value="de-DE">Deutsch</MenuItem>
            <MenuItem value="en-EN">English</MenuItem>
          </Select>
        </FormControl>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.selfservice_headerMenuButton}
          aria-controls="light-dark-switch"
          onClick={() => setDarkState(darkState ? false : true)}
        >
          {darkState ? (
            <SwitchToLightModeIcon
              classes={{ root: classes.selfservice_headerIcon }}
            />
          ) : (
            <SwitchToDarkModeIcon
              classes={{ root: classes.selfservice_headerIcon }}
            />
          )}
        </IconButton>
        <Person
          personQuery="me"
          view={ViewType.threelines}
          fetchImage={true}
        />
      </Toolbar>
    </AppBar>
  );
};
export default Header;
