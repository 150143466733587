import { OnLoadValues, TimelineObject } from "../../../components/Timeline/domain"
import { UpdateTimelineValues } from "../../../domain/StartPage/Timeline"
import { ReduxEnums } from "../../../enums"

export const pushDataToTimeline = (data: TimelineObject | undefined) => ({
  type: ReduxEnums.StartPageActionsEnum.pushDataToTimeline,
  payload: data
})

export const maxStartEndOfCanvas = (dates: OnLoadValues) => ({
  type: ReduxEnums.StartPageActionsEnum.maxStartEndOfCanvas,
  payload: dates
})

export const updateTimeline = (data : UpdateTimelineValues) => ({
  type: ReduxEnums.StartPageActionsEnum.updateTimeline,
  payload: data
})

export const updateYearsToLoad = (years : number[]) => ({
  type: ReduxEnums.StartPageActionsEnum.updateYearsToLoad,
  payload: years
})