import { ReduxEnums } from '../../../enums'
import { UiActionTypes } from './types'

export const toggleIsPageLoading = (isPageLoading: boolean) : UiActionTypes => ({
  type: ReduxEnums.UiActionsEnum.togglePageIsLoading,
  payload: isPageLoading
})

export const toggleIsDrawerOpen = (isDrawerOpen: boolean) : UiActionTypes => ({
  type: ReduxEnums.UiActionsEnum.toggleDrawerIsOpen,
  payload: isDrawerOpen
})