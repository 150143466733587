import { createFormularElement, FormularElement, Operator, OperatorMetaData, Variable } from ".";
import { FormularEditorConstants } from "../../constants";
import { FormularElementType } from "../../enums/FormularEditor";

type OperatorFormularElement = FormularElement & {
  operator: Operator
  metaData?: OperatorMetaData
};

export default OperatorFormularElement;

export function createOperatorFormularElement(operator: Operator, id: number | FormularElement[], metaData?: OperatorMetaData): OperatorFormularElement {
  return {
    ...createFormularElement(operator.label, id),
    type: FormularElementType.operator,
    operator,
    metaData
  }
}

export function parseStringToOperatorFormularElement(stringToParse: string, id: number | FormularElement[], variables: Variable[], constants: Variable[]): OperatorFormularElement | undefined {
  const operator = FormularEditorConstants.parseMxStringToOperator(stringToParse, variables, constants);
  if (!operator)
    return;

  return createOperatorFormularElement(operator, id);
}