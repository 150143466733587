import React, { FunctionComponent, useEffect } from "react";
import { Operator, Variable } from "../../../../domain/FormularEditor";
import { OperatorType } from "../../../../enums/FormularEditor";
import { OperatorInCreateDialog, OperatorNotInDialog } from "./components";

// operators, which have a dialog to be created
const specialOperator: OperatorType[] = [
  OperatorType.in,
  OperatorType.notIn
]

export type OperatorCreatorDialogProps = {
  onOperatCreateCalled: (operator?: Operator) => void
  operatorToCreate?: Operator
  variables: Variable[]
}


const OperatorCreatorDialog: FunctionComponent<OperatorCreatorDialogProps> = ({ operatorToCreate, variables, onOperatCreateCalled }) => {

  useEffect(() => {
    // if operatorToCreate has value and its type is not special one, call create methode
    // else create method will be called later
    if (operatorToCreate != null && !specialOperator.includes(operatorToCreate.operatorType))
      onOperatCreateCalled(operatorToCreate);
  }, [operatorToCreate])

  return (
    <>
      {
        operatorToCreate != null && operatorToCreate.operatorType === OperatorType.in &&
        <OperatorInCreateDialog
          onClose={onOperatCreateCalled}
          open
          operator={operatorToCreate}
          variables={variables}
        />
      }
      {
        operatorToCreate != null && operatorToCreate.operatorType === OperatorType.notIn &&
        <OperatorNotInDialog
          onClose={onOperatCreateCalled}
          open
          operator={operatorToCreate}
          variables={variables}
        />
      }
    </>
  );
}

export default OperatorCreatorDialog;