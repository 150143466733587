import { ParametrisationFeatureConfigurationPageActionTypes } from './types'
import { Bank, BankVisibilityParameterSets } from '../../../domain/Bank';
import { ReduxEnums } from '../../../enums'
import { StoryFeature } from '../../../domain/StoryFeature';
import { AlertObject, ParametrisationRule } from '../../../domain/Parametrisation';
import { Query } from 'material-table';

export const setSelectedBank = (bank?: Bank): ParametrisationFeatureConfigurationPageActionTypes => ({
  type: ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setSelectedBank,
  payload: bank
})

export const setSelectedParameter = (parameter?: BankVisibilityParameterSets): ParametrisationFeatureConfigurationPageActionTypes => ({
  type: ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setSelectedParameter,
  payload: parameter
})

export const setSelectedStoryFeature = (storyFeature?: StoryFeature): ParametrisationFeatureConfigurationPageActionTypes => ({
  type: ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setSelectedStoryFeature,
  payload: storyFeature
})

export const setParametrisationRules = (parametrisationRules: ParametrisationRule[]): ParametrisationFeatureConfigurationPageActionTypes => ({
  type: ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setParametrisationRules,
  payload: parametrisationRules
})

export const setAddParametrisationRuleCardShown = (addParametrisationRuleCardShown: boolean): ParametrisationFeatureConfigurationPageActionTypes => ({
  type: ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setAddParametrisationRuleCardShown,
  payload: addParametrisationRuleCardShown
})

export const setAlertObject = (alertObject?: AlertObject): ParametrisationFeatureConfigurationPageActionTypes => ({
  type: ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setAlertObject,
  payload: alertObject
})

export const setStoryFeatureParametrisationRulesQuery = (storyFeatureParametrisationRulesQuery: Query<ParametrisationRule>): ParametrisationFeatureConfigurationPageActionTypes => ({
  type: ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setStoryFeatureParametrisationRulesQuery,
  payload: storyFeatureParametrisationRulesQuery
})

export const setShowUpsertParametrisationRuleDialog = (showUpsertParametrisationRuleDialog: boolean): ParametrisationFeatureConfigurationPageActionTypes => ({
  type: ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setShowUpsertParametrisationRuleDialog,
  payload: showUpsertParametrisationRuleDialog
})

export const setShowImportParametrisationRulesDialog = (showImportParametrisationRulesDialog: boolean): ParametrisationFeatureConfigurationPageActionTypes => ({
  type: ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setShowImportParametrisationRulesDialog,
  payload: showImportParametrisationRulesDialog
})

export const setParametrisationRuleToEdit = (parametrisationRuleToEdit?: ParametrisationRule): ParametrisationFeatureConfigurationPageActionTypes => ({
  type: ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setParametrisationRuleToEdit,
  payload: parametrisationRuleToEdit
})

export const setParametrisationConnectedParametrisationRulesReload = (reloadDate?: Date): ParametrisationFeatureConfigurationPageActionTypes => ({
  type: ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setParametrisationConnectedParametrisationRulesReload,
  payload: reloadDate
})