// Orientated on https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/material-icons/SearchIcons.js
import React, { ChangeEvent, FunctionComponent } from 'react';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import debounce from 'lodash/debounce';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import FlexSearch from 'flexsearch';
import SearchIcon from '@material-ui/icons/Search';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import * as mui from '@material-ui/icons';
import synonyms from './synonyms';
import { ClassNameMap } from '@material-ui/styles';
import useStyles from './MaterialUiIconSearch.styles';
import i18n from "i18next";

type MuiIndexType = { [k: string]: typeof mui[keyof typeof mui] };

type indexKeyStringType = {
  [key: string]: any
}

if (process.env.NODE_ENV !== 'production') {
  Object.keys(synonyms).forEach((icon: string) => {
    if (!(mui as indexKeyStringType)[icon]) {
      throw new Error(`The icon ${icon} does no longer exist.`);
    }
  });
}

// Working on the logic? Uncomment these imports.
// It will be x10 faster than working with all of the icons.

// import Menu from '@material-ui/icons/Menu';
// import MenuOutlined from '@material-ui/icons/MenuOutlined';
// import MenuRounded from '@material-ui/icons/MenuRounded';
// import MenuTwoTone from '@material-ui/icons/MenuTwoTone';
// import MenuSharp from '@material-ui/icons/MenuSharp';
// import ExitToApp from '@material-ui/icons/ExitToApp';
// import ExitToAppOutlined from '@material-ui/icons/ExitToAppOutlined';
// import ExitToAppRounded from '@material-ui/icons/ExitToAppRounded';
// import ExitToAppTwoTone from '@material-ui/icons/ExitToAppTwoTone';
// import ExitToAppSharp from '@material-ui/icons/ExitToAppSharp';
// import Delete from '@material-ui/icons/Delete';
// import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
// import DeleteRounded from '@material-ui/icons/DeleteRounded';
// import DeleteTwoTone from '@material-ui/icons/DeleteTwoTone';
// import DeleteSharp from '@material-ui/icons/DeleteSharp';
// import DeleteForever from '@material-ui/icons/DeleteForever';
// import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
// import DeleteForeverRounded from '@material-ui/icons/DeleteForeverRounded';
// import DeleteForeverTwoTone from '@material-ui/icons/DeleteForeverTwoTone';
// import DeleteForeverSharp from '@material-ui/icons/DeleteForeverSharp';

// const mui = {
//   ExitToApp,
//   ExitToAppOutlined,
//   ExitToAppRounded,
//   ExitToAppTwoTone,
//   ExitToAppSharp,
//   Menu,
//   MenuOutlined,
//   MenuRounded,
//   MenuTwoTone,
//   MenuSharp,
//   Delete,
//   DeleteOutlined,
//   DeleteRounded,
//   DeleteTwoTone,
//   DeleteSharp,
//   DeleteForever,
//   DeleteForeverOutlined,
//   DeleteForeverRounded,
//   DeleteForeverTwoTone,
//   DeleteForeverSharp,
// };

function selectNode(node: EventTarget & HTMLParagraphElement) {
  // Clear any current selection
  const selection = window.getSelection();
  if (!selection)
    return;

  selection.removeAllRanges();

  // Select code
  const range = document.createRange();
  range.selectNodeContents(node);
  selection.addRange(range);
}

type IconsProps = {
  icons: IconType[]
  classes: ClassNameMap<string>
  selectedIcon: IconType | null
  handleOnSelectIcon: (icon: IconType) => void
};

const Icons = React.memo((props: IconsProps) => {
  const { icons, classes, handleOnSelectIcon, selectedIcon } = props;

  const handleClick = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    selectNode(event.currentTarget);
  };

  return (
    <div>
      {icons.map((icon: IconType) => {
        return (
          <span key={icon.key} className={clsx('markdown-body', classes.icon, {
            [classes.selectedIcon]: selectedIcon?.key === icon.key
          })}>
            <icon.icon
              tabIndex={-1}
              onClick={() => { handleOnSelectIcon(icon); }}
              title={icon.key}
              className={classes.iconSvg}
              data-ga-event-category="material-icons"
              data-ga-event-action="click"
              data-ga-event-label={icon.key}
            />
            <p onClick={handleClick}>{icon.key}</p>
          </span>
        );
      })}
    </div>
  );
});

const searchIndex = FlexSearch.create<string>({
  async: true,
  tokenize: 'full',
});

export type IconType = {
  key: string
  tag: string
  icon?: any
}

type IconMapType = {
  [key: number]: IconType
}

export function getIconTypeByKey(key: string): IconType | null {
  const icon = (mui as MuiIndexType)[key];
  if (!icon)
    return null;

  let tag: string;
  if (key.indexOf('Outlined') !== -1) {
    tag = 'Outlined';
  } else if (key.indexOf('TwoTone') !== -1) {
    tag = 'Two tone';
  } else if (key.indexOf('Rounded') !== -1) {
    tag = 'Rounded';
  } else if (key.indexOf('Sharp') !== -1) {
    tag = 'Sharp';
  } else {
    tag = 'Filled';
  }

  return {
    key,
    tag,
    icon,
  };
}

export function getIconSpanByIconType(iconType: IconType, iconSpanClassName: string, iconClassName: string): JSX.Element | undefined {
  if (!iconType)
    return;

  iconType.icon = (mui as MuiIndexType)[iconType.key];
  iconType.icon.className = iconClassName;
  return (
    <span key={iconType.key} className={iconSpanClassName}>
      <iconType.icon />
    </span>
  );
}

export function getIconSpanByIconKey(iconKey: string, iconSpanClassName: string, iconClassName: string): JSX.Element | undefined {
  const iconType: IconType | null = getIconTypeByKey(iconKey);
  if (!iconType)
    return;

  return getIconSpanByIconType(iconType, iconSpanClassName, iconClassName);
}

const allIconsMap: IconMapType = {};
const allIcons: IconType[] = Object.keys(mui)
  .sort()
  .map((key: string, index: number) => {


    let searchable = key.replace(/(Outlined|TwoTone|Rounded|Sharp)$/, '');
    if (synonyms[searchable]) {
      searchable += ` ${synonyms[searchable]}`;
    }
    searchIndex.add(index, searchable);

    const icon = getIconTypeByKey(key);
    if (!icon)
      return null;

    allIconsMap[index] = icon;
    return icon;
  })
  .filter((icon: IconType | null) => icon != null) as IconType[];

export type MaterialUiIconSearchProps = {
  onSelectedIconChanged: (icon: IconType) => void
  showSelectTag?: boolean
  selectedIcon: IconType | null
}

const MaterialUiIconSearch: FunctionComponent<MaterialUiIconSearchProps> = ({
  showSelectTag = true, selectedIcon, onSelectedIconChanged
}) => {
  const classes = useStyles();
  const [tag, setTag] = React.useState<string>('Filled');
  const [keys, setKeys] = React.useState<number[] | null>(null);

  const handleOnSelectIcon = (icon: IconType) => {
    onSelectedIconChanged(icon)
  };

  const isMounted = React.useRef(false);
  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleChange = React.useMemo(
    () =>
      debounce((value: string) => {
        if (!isMounted.current) {
          return;
        }

        if (value === '') {
          setKeys(null);
        } else {
          searchIndex.search(value).then((results: string[]) => {
            setKeys(results.map((result: string) => Number(result)));
          });
        }
      }, 220),
    [],
  );

  const icons = React.useMemo(
    () =>
      (keys === null ? allIcons : keys.map((key) => allIconsMap[key])).filter(
        (icon) => tag === icon.tag,
      ),
    [tag, keys],
  );

  return (
    <Grid container className={classes.root}>
      {
        showSelectTag &&
        <Grid item xs={12} sm={3}>
          <form className={classes.form}>
            <RadioGroup>
              {['Filled', 'Outlined', 'Rounded', 'Two tone', 'Sharp'].map((key) => {
                return (
                  <FormControlLabel
                    key={key}
                    control={<Radio checked={tag === key} onChange={() => setTag(key)} value={key} />}
                    label={key}
                  />
                );
              })}
            </RadioGroup>
          </form>
        </Grid>
      }
      <Grid item xs={12} sm={showSelectTag ? 9 : 12}>
        <Paper className={classes.paper}>
          <IconButton className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            autoFocus
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              handleChange(event.target.value);
            }}
            className={classes.input}
            placeholder={i18n.t('Components:iconSearch.searchIcon')}
            inputProps={{ 'aria-label': 'search icons' }}
          />
        </Paper>
        <Typography className={classes.results}>{`${icons.length} matching results`}</Typography>
        <Icons icons={icons} classes={classes} handleOnSelectIcon={handleOnSelectIcon} selectedIcon={selectedIcon} />
      </Grid>
    </Grid>
  );
}

export default MaterialUiIconSearch;