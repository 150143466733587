import { createSchema } from "beautiful-react-diagrams";
import { Link } from "beautiful-react-diagrams/@types/DiagramSchema";
import { FeatureNode } from "../../components/StoryBuilder";
import ApiError, { isApiCallError } from "../../domain/Api/ApiError";
import { Feature, FeatureNodeDto, GetDiagrammSchemaForStoryDto, Output, StoryFeature, StoryFeatureConnection } from "../../domain/StoryFeature";
import { currentFeatureApiService } from "../FeatureApiService";
import { currentStoryApiService } from "./StoryApi";
import { currentStoryFeatureConnectionApiService } from "./StoryFeatureConnectionApi";

export class StorySchemaBuilder {

  async getDiagrammSchemaForBankParameterSetsId(bankVisibilityParameterSetsId: string): Promise<GetDiagrammSchemaForStoryDto | null> {
    const storyResponse = await currentStoryApiService.getStoryForBankParameterStory(bankVisibilityParameterSetsId);
    if (!storyResponse)
      return null;

    if (isApiCallError(storyResponse))
      return {
        error: storyResponse as ApiError
      };

    const response = await this.getDiagrammSchemaForStory(storyResponse.id);
    response.story = storyResponse;
    return response;
  }

  async getDiagrammSchemaForStory(storyId: number): Promise<GetDiagrammSchemaForStoryDto> {
    const storyFeaturesResponse = await currentStoryApiService.getStoryFeatures(storyId);

    if (!storyFeaturesResponse)
      return {
        error: new ApiError(`No Story found for Id ${storyId}`)
      }

    if (isApiCallError(storyFeaturesResponse))
      return {
        error: storyFeaturesResponse as ApiError
      };

    const storyFeatures = storyFeaturesResponse as StoryFeature[];

    const nodes = await this.getFeatureNodeDtosForStoryFeatures(storyFeatures);
    const links = await this.getLinksForStoryFeatures(storyFeatures);


    const schema = createSchema<unknown>({
      nodes,
      links
    });

    return {
      schema,
      storyFeatures
    };
  }

  async getFeatureNodeDtosForStory(storyId: number): Promise<FeatureNodeDto[]> {
    const storyFeaturesResponse = await currentStoryApiService.getStoryFeatures(storyId);
    if (isApiCallError(storyFeaturesResponse))
      return [];
    return this.getFeatureNodeDtosForStoryFeatures(storyFeaturesResponse as StoryFeature[]);
  }

  async getFeatureNodeDtosForStoryFeatures(storyFeatures: StoryFeature[]): Promise<FeatureNodeDto[]> {
    const nodes: FeatureNodeDto[] = [];

    if (!storyFeatures)
      return nodes;

    const featureMap: Map<number, Feature> = new Map<number, Feature>();

    const storyFeaturesLength = storyFeatures.length;
    for (let sFeatureIndex = 0; sFeatureIndex < storyFeaturesLength; sFeatureIndex++) {
      const sFeature = storyFeatures[sFeatureIndex];
      if (!sFeature)
        continue;
      const currentFeatureId = sFeature.featureId;
      let currentFeature: Feature | null = null;
      if (featureMap.has(currentFeatureId))
        currentFeature = featureMap.get(currentFeatureId) ?? null;
      else {
        const currentFeatureResponse = await currentFeatureApiService.getFeature(currentFeatureId);
        if (!isApiCallError(currentFeatureResponse) && currentFeatureResponse) {
          currentFeature = currentFeatureResponse as Feature;
          featureMap.set(currentFeatureId, currentFeature);
        }
      }

      if (!currentFeature)
        continue;

      const newFeatureNodeResponse = await this.getFeatureNodeDtoForStoryFeatureAndFeature(sFeature, currentFeature, nodes.length === 0, true);
      if (isApiCallError(newFeatureNodeResponse))
        continue;
      nodes.push(newFeatureNodeResponse as FeatureNodeDto)
    }

    return nodes;
  }

  async getFeatureNodeDtoForStoryFeatureAndFeature(storyFeature: StoryFeature, feature: Feature, firstNode: boolean, nodeIsSaved: boolean): Promise<FeatureNodeDto | ApiError> {
    const currentFeatureOutputsResponse = await currentFeatureApiService.getOutputsOfFeature(feature.id);
    if (isApiCallError(currentFeatureOutputsResponse))
      return currentFeatureOutputsResponse;

    const currentFeatureOutputs = currentFeatureOutputsResponse;
    const newFeatureNode: FeatureNodeDto = {
      id: this.getNodeId(storyFeature.id),
      coordinates: [storyFeature.xcoordinate, storyFeature.ycoordinate],
      inputs: firstNode
        ? []
        : [
          { id: this.getInputId(storyFeature.id), alignment: 'left', isInput: true }
        ],
      outputs: currentFeatureOutputs.map((o: Output) => {
        return { id: this.getOutputId(storyFeature.id, o.id), alignment: 'right', name: o.name, outputType: o.type };
      }),
      data: {
        storyFeature: storyFeature,
        feature: feature,
        storyFeatureSaved: nodeIsSaved
      },
      render: FeatureNode
    }

    return newFeatureNode;
  }

  async getLinksForStoryFeatures(storyFeatures: StoryFeature[]): Promise<Link[]> {
    const links: Link[] = [];

    if (!storyFeatures)
      return links;


    const storyFeaturesLength = storyFeatures.length;
    for (let sFeatureIndex = 0; sFeatureIndex < storyFeaturesLength; sFeatureIndex++) {
      const sFeature = storyFeatures[sFeatureIndex];
      if (!sFeature)
        continue;

      const storyFeatureConnectionsResponse = await currentStoryFeatureConnectionApiService.getStoryFeatureConnectionsByTriggerStoryFeatureId(sFeature.id);
      if (!storyFeatureConnectionsResponse || isApiCallError(storyFeatureConnectionsResponse))
        continue;

      const storyFeatureConnections = storyFeatureConnectionsResponse as StoryFeatureConnection[];
      const storyFeatureConnectionsLength = storyFeatureConnections.length;
      for (let sFCIndex = 0; sFCIndex < storyFeatureConnectionsLength; sFCIndex++) {
        const sFConnection = storyFeatureConnections[sFCIndex];
        if (!sFConnection)
          continue;

        const currentLink: Link = {
          input: this.getInputId(sFConnection.triggeredStoryFeatureId),
          output: this.getOutputId(sFConnection.triggerStoryFeatureId, sFConnection.triggerOutputId)
        };
        links.push(currentLink)
      }
    }
    return links;
  }

  nodeIdStart = "StoryFeatureNode_"
  getNodeId(storyFeatureId: number): string {
    return `${this.nodeIdStart}${storyFeatureId}`
  }

  getStoryFeatureIdForNodeId(nodeId: string): number | null {
    if (!nodeId)
      return null;
    const withoutBeginning = nodeId.substring(this.nodeIdStart.length);
    const parsedStoryFeatureId = parseInt(withoutBeginning);

    return isNaN(parsedStoryFeatureId) ? null : parsedStoryFeatureId;
  }

  outputIdStart = 'port_out_'
  getOutputId(storyFeatureId: number, outputId: number): string {
    return `${this.outputIdStart}${storyFeatureId}_${outputId}`
  }

  getStoryFeatureIdAndOutputIdForOutputId(outputId: string): StoryFeatureIdOutputId | null {
    if (!outputId || !outputId.startsWith(this.outputIdStart))
      return null;

    const withoutBeginning = outputId.substring(this.outputIdStart.length);
    const content = withoutBeginning.split("_");
    if (content.length !== 2)
      return null;

    const parsedOutputId = parseInt(content[1]);
    const parsedStoryFeatureId = parseInt(content[0]);

    if (isNaN(parsedOutputId) || isNaN(parsedStoryFeatureId))
      return null;

    return {
      outputId: parsedOutputId,
      storyFeatureId: parsedStoryFeatureId
    };
  }

  inputIdStart = 'port_in_'
  getInputId(storyFeatureId: number): string {
    return `${this.inputIdStart}${storyFeatureId}`
  }

  getStoryFeatureIdForInputId(inputId: string): number | null {
    if (!inputId || !inputId.startsWith(this.inputIdStart))
      return null;

    const withoutBeginning = inputId.substring(this.inputIdStart.length);
    const parsedInputId = parseInt(withoutBeginning);

    return isNaN(parsedInputId) ? null : parsedInputId;
  }


}

export type StoryFeatureIdOutputId = {
  storyFeatureId: number
  outputId: number
}

export const currentStorySchemaBuilder = new StorySchemaBuilder();