import { makeStyles, Theme, createStyles, lighten } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controlBar: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    controlBarButton: {
      margin: theme.spacing(1)
    },
    autocompleteInputButtonGroup: {
      display: 'flex'
    },
    autocompleteInputTextField: {
      flexGrow: 1
    },
    controlBarAutocomplete: {
      width: "300px",
      margin: theme.spacing(1)
    },
    controlBarNumberField: {
      width: '200px',
      margin: theme.spacing(1)
    },
    calculationFormularValidationDiv: {
      color: theme.palette.error.main,
      fontSize: 15
    },
    rLDDContainer: {
      backgroundColor: lighten(theme.palette.primary.main, 0.5),
      borderRadius: "0.25rem",
      padding: "0.5rem",
      margin: 0,
      flexWrap: 'wrap',
      position: 'relative'
    },
    rLDDElement: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    formularElementItem: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '0.25rem',
      marginBottom: '0.2rem',
      marginRight: '0.2rem',
      padding: '0.5rem',
      paddingLeft: '0.25rem',
      opacity: 1,
      ".dragged &": {
        opacity: 0.1,
      },
      ".floating &": {
        backgroundColor: lighten(theme.palette.primary.main, 0.2),
        boxShadow: "0 0.2rem 2rem #666666",
        cursor: 'grabbing',
      }
    },
    selectedFormularElementItem: {
      backgroundColor: theme.palette.secondary.main,
      ".floating &": {
        backgroundColor: lighten(theme.palette.secondary.main, 0.2)
      }
    },
    formularElementItemTitle: {
      fontWeight: 'bold',
      margin: 0
    },
    addOperatorInCreateDialog: {
      maxWidth: '400px'
    }
  }),
);

export default useStyles;