export enum OperatorGroupType {
  calculation,
  comparsion,
  boolean
}

export enum OperatorType {
  and = "and",
  or = "or",
  bracketOpen = "bracketOpen",
  bracketClose = "bracketClose",
  plus = "plus",
  minus = "minus",
  divide = "divide",
  mulitply = "mulitply",
  equal = "equal",
  notEqual = "notEqual",
  greater = "greater",
  greaterEqual = "greaterEqual",
  lower = "lower",
  lowerEqual = "lowerEqual",
  in = "in",
  notIn = "notIn"
}

export enum FormularElementType {
  unknown,
  variable,
  operator,
  constant,
  number,
}