import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      selfservice_link: {
        textDecoration: "none",
        "&:hover, &:focus": {
          backgroundColor: theme.palette.primary.light,
        },
      },
      selfservice_linkActive: {
        fontWeight: "bold"
      },
      selfservice_linkNested: {
        paddingLeft: 0,
        "&:hover, &:focus": {
          backgroundColor: theme.palette.primary.light,
        },
      },
      selfservice_linkIcon: {
        marginRight: theme.spacing(1),
        color: theme.palette.text.secondary + "99",
        transition: theme.transitions.create("color"),
        width: 24,
        display: "flex",
        justifyContent: "center",
      },
      selfservice_linkIconActive: {
        color: theme.palette.secondary.contrastText,
      },
      selfservice_linkText: {
        padding: 0,
        color: theme.palette.text.secondary + "CC",
        transition: theme.transitions.create(["opacity", "color"]),
        fontSize: 16,
      },
      selfservice_linkTextActive: {
        fontWeight: "bold"
      },
      selfservice_linkTextHidden: {
        opacity: 0,
      },
      selfservice_nestedList: {
        paddingLeft: theme.spacing(2) + 30,
      },
      selfservice_sectionTitle: {
        marginLeft: theme.spacing(4.5),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      selfservice_divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        height: 1,
        backgroundColor: "#D8D8D880",
      },
    }
  ),
);

export default useStyles;