import { SelectOption } from "../domain/UI";
import i18n from "i18next";

export type ParameterConstantsType = {
  financialServiceProviderParametrisationParameters: SelectOption<string>[]
}

const ParameterConstants: ParameterConstantsType = {
  financialServiceProviderParametrisationParameters: [
    {
      label: `${i18n.t('Components:constants.parameter.minRequestVolume')}`,
      value: "_FinancialServiceProviderParametrisation_TotalRequestedVolumeFrom_"
    },
    {
      label: `${i18n.t('Components:constants.parameter.maxRequestVolume')}`,
      value: "_FinancialServiceProviderParametrisation_TotalRequestedVolumeTo_"
    },
    {
      label: `${i18n.t('Components:constants.parameter.bankPLZ')}`,
      value: "_BankAccount_ZipCodeArea_"
    },
    {
      label: `${i18n.t('Components:constants.parameter.financingAmount')}`,
      value: "_FinancialServiceProviderParametrisation_FinancingPurposes_"
    },
    {
      label: `${i18n.t('Components:constants.parameter.businessForm')}`,
      value: "_FinancialServiceProviderParametrisation_LegalForms_"
    },
    {
      label: `${i18n.t('Components:constants.parameter.creditRating')}`,
      value: "_FinancialServiceProviderParametrisation_MinimumCreditRating_"
    },
    {
      label: `${i18n.t('Components:constants.parameter.dateOfEstablishment')}`,
      value: "_FinancialServiceProviderParametrisation_MinimumCompanyAge_"
    },
    {
      label: `${i18n.t('Components:constants.parameter.salesClass')}`,
      value: "_FinancialServiceProviderParametrisation_TurnoverClasses_"
    },
    {
      label: `${i18n.t('Components:constants.parameter.products')}`,
      value: "_FinancialServiceProviderParametrisation_Products_"
    },
    {
      label: `${i18n.t('Components:constants.parameter.numberOfEmployees')}`,
      value: "_FinancialServiceProviderParametrisation_MinimumNumberOfEmployees_"
    },
    {
      label: `${i18n.t('Components:constants.parameter.branch')}`,
      value: "_FinancialServiceProviderParametrisation_ExclusionEconomicSectorsCodeLong_"
    },
    {
      label: `${i18n.t('Components:constants.parameter.minTerm')}`,
      value: "_FinancialServiceProviderParametrisation_TermInMonthsFrom_"
    },
    {
      label: `${i18n.t('Components:constants.parameter.maxTerm')}`,
      value: "_FinancialServiceProviderParametrisation_TermInMonthsTo_"
    },
  ]
}

export default ParameterConstants;