type Variable = {
  id: string
  label: string
};

export default Variable;

export function sortVariablesAlphabetical(a: Variable, b: Variable): number {
  //https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
  const nameA = a?.label?.toUpperCase(); // Groß-/Kleinschreibung ignorieren
  const nameB = b?.label?.toUpperCase(); // Groß-/Kleinschreibung ignorieren
  if (nameA < nameB)
    return -1;
  if (nameA > nameB)
    return 1;
  return 0; // Namen müssen gleich sein
}