import { createFormularElement, FormularElement } from ".";
import { FormularElementType } from "../../enums/FormularEditor";

type NumberFormularElement = FormularElement & {
  value: number
};

export default NumberFormularElement;

export function createNumberFormularElement(label: string, value: number, id: number | FormularElement[]): NumberFormularElement {
  return {
    ...createFormularElement(label, id),
    type: FormularElementType.number,
    value
  }
}

export function parseStringToNumberFormularElement(stringToParse: string, id: number | FormularElement[]): NumberFormularElement | undefined {
  if (!stringToParse || stringToParse === '' || isNaN(Number(stringToParse)))
    return;

  return createNumberFormularElement(stringToParse, Number(stringToParse), id);
}