import { Query } from "material-table";
import { Bank, BankVisibilityParameterSets } from "../../domain/Bank";
import { AlertObject, ParametrisationRule } from "../../domain/Parametrisation";
import { StoryFeature } from "../../domain/StoryFeature";
import { ReduxEnums } from "../../enums";
import { ActionTypes } from "../actions";

interface InitialStateType {
  selectedBank?: Bank
  selectedParameter?: BankVisibilityParameterSets
  selectedStoryFeature?: StoryFeature
  parametrisationRules: ParametrisationRule[]
  addParametrisationRuleCardShown: boolean
  alertObject?: AlertObject
  storyFeatureParametrisationRulesQuery: Query<ParametrisationRule>
  showUpsertParametrisationRuleDialog: boolean
  showImportParametrisationRulesDialog: boolean
  parametrisationRuleToEdit?: ParametrisationRule
  parametrisationConnectedParametrisationRulesReload?: Date
}

const initialState: InitialStateType = {
  selectedBank: undefined,
  selectedParameter: undefined,
  selectedStoryFeature: undefined,
  parametrisationRules: new Array<ParametrisationRule>(),
  addParametrisationRuleCardShown: false,
  alertObject: undefined,
  storyFeatureParametrisationRulesQuery: {
    filters: [],
    orderBy: { field: 'displayName' },
    orderDirection: 'asc',
    page: 0,
    pageSize: 5,
    search: '',
    totalCount: 5
  },
  showUpsertParametrisationRuleDialog: false,
  showImportParametrisationRulesDialog: false,
  parametrisationRuleToEdit: undefined,
  parametrisationConnectedParametrisationRulesReload: undefined
};

export default function parametrisationFeatureConfigurationPage(
  state = initialState,
  action: ActionTypes
): InitialStateType {
  switch (action.type) {
    case ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setSelectedBank:
      return {
        ...state,
        selectedBank: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    case ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setSelectedParameter:
      return {
        ...state,
        selectedParameter: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    case ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setSelectedStoryFeature:
      return {
        ...state,
        selectedStoryFeature: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    case ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setParametrisationRules:
      return {
        ...state,
        parametrisationRules: new Array<ParametrisationRule>().concat(action.payload),
      };
    case ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setAddParametrisationRuleCardShown:
      return {
        ...state,
        addParametrisationRuleCardShown: action.payload
      };
    case ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setAlertObject:
      return {
        ...state,
        alertObject: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    case ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setStoryFeatureParametrisationRulesQuery:
      return {
        ...state,
        storyFeatureParametrisationRulesQuery: Object.assign({}, action.payload),
      };
    case ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setShowUpsertParametrisationRuleDialog:
      return {
        ...state,
        showUpsertParametrisationRuleDialog: action.payload,
      };
    case ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setShowImportParametrisationRulesDialog:
      return {
        ...state,
        showImportParametrisationRulesDialog: action.payload,
      };
    case ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setParametrisationRuleToEdit:
      return {
        ...state,
        parametrisationRuleToEdit: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    case ReduxEnums.ParametrisationFeatureConfigurationPageActionsEnum.setParametrisationConnectedParametrisationRulesReload:
      return {
        ...state,
        parametrisationConnectedParametrisationRulesReload: action.payload,
      }
    default:
      return state;
  }
}
