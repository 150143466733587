import Parameter from "../../domain/Parametrisation/Parameter";
import { ParameterType } from "../../enums/Parametrisation";

export const parameterData: Parameter[] = [
  {
    displayName: 'Total amount',
    id: 1,
    type: ParameterType.totalAmount
  },
  {
    displayName: 'Postal code',
    id: 2,
    type: ParameterType.postalCode
  },
  {
    displayName: 'Legal form',
    id: 3,
    type: ParameterType.legalForm
  },
  {
    displayName: 'Crefo',
    id: 4,
    type: ParameterType.crefo
  },
  {
    displayName: 'Founding year',
    id: 5,
    type: ParameterType.foundingYear
  },
  {
    displayName: 'Turnover class',
    id: 6,
    type: ParameterType.turnoverClass
  },
  {
    displayName: 'Financing product',
    id: 7,
    type: ParameterType.financingProduct
  },
  {
    displayName: 'Minimum number of employees',
    id: 8,
    type: ParameterType.minimumNumberOfEmployees
  },
  {
    displayName: 'Excluded industry',
    id: 9,
    type: ParameterType.excludedIndustry
  },
  {
    displayName: 'Term',
    id: 10,
    type: ParameterType.term
  }
]