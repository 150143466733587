import React, { FunctionComponent, useState } from 'react';
import { List } from '@material-ui/core';
import SidebarLink, { SidebarLinkProps } from '../../SidebarLink';
import { getSidebarStructure } from '../../../../pages/routes';
import { RootState } from '../../../../store/reduxStore';
import * as H from 'history';
import { checkAllowedAppRoleTypesInUserRoles } from '../../../../enums/Authentication';
import { getUserRoles } from '../../../../auth/authProvider';
import { useSelector } from 'react-redux';

export type DefaultSidebarProps = {
  location: H.Location<H.LocationState>
};

const DefaultSidebar: FunctionComponent<DefaultSidebarProps> = ({ location }) => {
  const isDrawerOpen = useSelector((state: RootState) => state.ui.isDrawerOpen);
  const structure: SidebarLinkProps[] = getSidebarStructure();

  const [filterSidebarStructure] = useState<string>('');
  const userRoles = getUserRoles();

  const allowedStructure = structure.filter((s: SidebarLinkProps) => checkAllowedAppRoleTypesInUserRoles(s.allowedAppRoles,userRoles));
  allowedStructure.map((s: SidebarLinkProps) => 
    s.children = s.children?.filter((child: SidebarLinkProps ) => checkAllowedAppRoleTypesInUserRoles(child.allowedAppRoles,userRoles)));
  const currentStructure = filterSidebarStructure ? allowedStructure.filter((s: SidebarLinkProps) => s.label && s.label.includes(filterSidebarStructure)) : allowedStructure;

  return (
    <>
      <List>
        {currentStructure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isDrawerOpen}
            {...link}
          />
        ))}
      </List>
    </>
  );
}

export default DefaultSidebar; 