
const RoutesConstants = {
  pageTitle: {
    startPage: "RoutesConstants:startPage",
    editorPage: "RoutesConstants:editorPage",
    notFoundPage: "RoutesConstants:notFoundPage",
    banksPage: "RoutesConstants:banksPage",
    bankParameterPage: "RoutesConstants:bankParameterPage",
    documentsPage: "RoutesConstants:documentsPage",
    documentEditPage: "RoutesConstants:documentEditPage",
    parametrisationFeatureConfigurationPage: "RoutesConstants:parametrisationFeatureConfigurationPage",
    creditProcessPage: "RoutesConstants:creditProcessPage",
    kpiCheckPage: "RoutesConstants:kpiCheckPage",
    crefoPage: "RoutesConstants:crefoPage",
    crefoInformationPage: "RoutesConstants:crefoInformationPage",
    crefoInvoicePage: "RoutesConstants:crefoInvoicePage",
    applicationPage: "RoutesConstants:applicationPage",
    creditProcessDemoPage: "RoutesConstants:creditProcessDemoPage",
    callInfoPage: "RoutesConstants:callInfoPage",
    offerCalculatorPage: "RoutesConstants:offerCalculatorPage",
    // testPage: "RoutesConstants:testPage",
    notAllowedRoleErrorPage: "RoutesConstants:notAllowedRoleErrorPage",
    // dataCenterPage: "RoutesConstants:dataCenterPage"
    dataCenterPage: "RoutesConstants:testPage",
    documentParsingPage: "RoutesConstants:documentParsingPage"
  },
  routePath: {
    startPage: "/",
    notFoundPage: "*",
    banksPage: "/banken",
    bankParameterPage: "/banken/:bankId/:productId",
    documentsPage: "/documents",
    documentEditPage: "/documentEdit",
    documentEditPageWithDocumentID: "/documentEdit/:documentId",
    parametrisationFeatureConfigurationPage: "/banken/:bankId/:productId/:storyFeatureId/parametrisation",
    creditProcessPage: "/creditProcess",
    kpiCheckPage: "/kpiCheck",
    crefoInformationPage: "/crefoInformation",
    crefoInvoicePage: "/crefoInvoice",
    applicationPageWithInquiryID: "/application/:inquiryId",
    creditProcessDemoPage: "/creditProcessDemo",
    callInfoPage: "/callInfo",
    offerCalculatorPage: "/offerCalculator",
    //testPage: "/testPage",
    notAllowedRoleErrorPage: "/notAllowedRoleErrorPage",
    dataCenterPage: "/datacenter",
    documentParsingPage: "/documentParsing/:documentId",
    documentParsingInquiryPage: "/documentParsing/:documentId/:inquiryId",
    documentParsingStartPage: "/documentParsing",
  },
};

export default RoutesConstants;
