import React, { FunctionComponent } from 'react';
import * as H from 'history';
import { DefaultSidebar } from './DefaultSidebar';

export type SidebarInfosProps = {
  location: H.Location<H.LocationState>
};

const SidebarInfos: FunctionComponent<SidebarInfosProps> = ({ location }) => {
  const { pathname } = location;

  switch (pathname) {
    default:
      return <DefaultSidebar
        location={location}
      />;
  }
}

export default SidebarInfos;