export enum WidgetKeys {
  Hello = "Hello",
  Protocol = "Protocol",
  Datacenter = "Datacenter",
  Timeline = 'Timeline',
}

export enum SizeKeys {
  Lg = "lg",
  Md = "md",
  Sm = "sm",
  Xs = "xs"
}