import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ApplicationConstants } from '../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selfservice_menuButton: {
      marginLeft: 12,
      marginRight: 36,
    },
    selfservice_hide: {
      display: "none",
    },
    selfservice_drawer: {
      width: ApplicationConstants.drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap"
    },
    selfservice_drawerOpen: {
      width: ApplicationConstants.drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      })
    },
    selfservice_drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 40,
      [theme.breakpoints.down("sm")]: {
        width: ApplicationConstants.drawerWidth,
      }
    },
    selfservice_toolbar: {
      ...theme.mixins.toolbar,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    selfservice_content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    selfservice_sidebarList: {
      // marginTop: theme.spacing(6),
    },
    selfservice_mobileBackButton: {
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(3),
      [theme.breakpoints.only("sm")]: {
        marginTop: theme.spacing(0.625),
      },
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    selfservice_headerIcon: {
      fontSize: 28,
    },
    selfservice_headerIconCollapse: {
    },
    speedDial: {
      position: 'absolute',
      bottom: "40px",
      '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: 70 + 'px',
        right: 50 + '%',
        left: 50 + '%',
      }
    },
    selfservice_footerIcon: {
      ...theme.drawerFooter,
      position: "absolute",
      bottom: "10px",
    }
  }),
);

export default useStyles;