import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    parametrisationCards: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'start',
      width: '100%'
    },
    parametrisationAddCard: {
      maxWidth: '800px'
    }
  }),
);

export default useStyles;