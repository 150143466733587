import { CreditProcessPageActionTypes } from './types'
import { Bank, BankVisibilityParameterSets } from '../../../domain/Bank';
import { ReduxEnums } from '../../../enums';
import { StoryResult, StoryFeatureResult } from '../../../domain/CreditProcess';
import { StoryFeature } from '../../../domain/StoryFeature';
import { Inquiry } from '../../../domain/Marketplace';

export const setSelectedBank = (bank?: Bank): CreditProcessPageActionTypes => ({
  type: ReduxEnums.CreditProcessPageActionsEnum.setSelectedBank,
  payload: bank
})

export const setSelectedParameter = (parameter?: BankVisibilityParameterSets): CreditProcessPageActionTypes => ({
  type: ReduxEnums.CreditProcessPageActionsEnum.setSelectedParameter,
  payload: parameter
})

export const setLatestStoryResult = (latestStoryResult?: StoryResult): CreditProcessPageActionTypes => ({
  type: ReduxEnums.CreditProcessPageActionsEnum.setLatestStoryResult,
  payload: latestStoryResult
})

export const setLatestStoryFeatureResults = (storyFeatureResults: StoryFeatureResult[]): CreditProcessPageActionTypes => ({
  type: ReduxEnums.CreditProcessPageActionsEnum.setLatestStoryFeatureResults,
  payload: storyFeatureResults
})

export const updateLatestStoryFeatureResult = (storyFeatureResult: StoryFeatureResult): CreditProcessPageActionTypes => ({
  type: ReduxEnums.CreditProcessPageActionsEnum.updateLatestStoryFeatureResult,
  payload: storyFeatureResult
})

export const setStoryFeatures = (storyFeatures?: StoryFeature[]): CreditProcessPageActionTypes => ({
  type: ReduxEnums.CreditProcessPageActionsEnum.setStoryFeatures,
  payload: storyFeatures
})

export const setSelectedInquiry = (inquiry?: Inquiry): CreditProcessPageActionTypes => ({
  type: ReduxEnums.CreditProcessPageActionsEnum.setSelectedInquiry,
  payload: inquiry
})
