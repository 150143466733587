import { ApiError } from "../../domain/Api";
import { isApiCallError } from "../../domain/Api/ApiError";
import { Story, StoryFeature, StoryFeatureConnection } from "../../domain/StoryFeature";
import { ServiceName } from "../../enums/Services";
import { ApiService } from "../ApiService";

type PostStoryFeaturesAndConnectionsDto = {
  storyFeatures: StoryFeature[]
  storyFeatureConnections: StoryFeatureConnection[]
}

export class StoryApiService extends ApiService {

  async getStories(): Promise<Story[] | ApiError> {
    try {
      return await this.GetOData<Story>('Story', {}, []);
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async getStory(storyId: number): Promise<Story | null | ApiError> {
    try {
      return await this.GetRequest<Story | null>(`Story(${storyId})`, null);
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async getStoryForBankParameterStory(bankParameterStoryId: string): Promise<Story | null | ApiError> {
    try {
      const storyResponse = await this.GetOData<Story>('Story', { top: 1, count: true, filter: [{ expression: `BankParameterStory/any(bankParameterStory: bankParameterStory/BankVisibilityParameterSetsId eq '${bankParameterStoryId}')` }] }, []);
      if (!storyResponse)
        return null;
      if (isApiCallError(storyResponse))
        return storyResponse;

      return storyResponse.length ? storyResponse[0] : null;
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async getStoryFeatures(storyId: number): Promise<StoryFeature[] | ApiError> {
    try {
      return await this.GetOData<StoryFeature>('StoryFeature', { filter: [{ expression: `StoryId eq ${storyId}` }] }, []);
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async updateStoryFeaturesAndConnectionsForStory(storyId: number, storyFeatures: StoryFeature[], storyFeatureConnections: StoryFeatureConnection[]): Promise<void | ApiError> {
    try {
      await this.Post<PostStoryFeaturesAndConnectionsDto, unknown>('StoryManager/SaveStoryFeaturesAndConnections', {
        storyId,
        storyFeatureConnections,
        storyFeatures
      });
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async crateStory(story: Story): Promise<Story | ApiError> {
    try {
      return await this.Post<Story, Story | ApiError>('Story', story);
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async updateStory(story: Story): Promise<Story | ApiError> {
    try {
      return await this.OdataPatch<Story, Story | ApiError>(story.id, 'Story', story);
    }
    catch (error) {
      return error as ApiError;
    }
  }

}

export const currentStoryApiService = new StoryApiService(ServiceName.Api);