import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      border: '1px dashed gray',
      padding: '0.5rem 1rem',
      marginRight: '1.5rem',
      marginBottom: '1.5rem',
      cursor: 'move',
      float: 'left',
      opacity: 1
    },
    dragging: {
      opacity: 0.4
    }

  }),
);

export default useStyles;