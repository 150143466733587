import { apiGetEvent, apiPatchEvent, apiPostEvent, apiDeleteEvent } from "./apiCall";
import { ServiceName } from "../enums/Services";
import { AxiosResponse, CancelToken } from "axios";
import { HttpStatusCodeEventType, ApiError, OdataNextQuery } from "../domain/Api";
import {
  ODataWrapper,
  ODataOptions,
  ODataFilterOptions,
  ODataExpressionOptions,
  ODataBatchRequestBody,
  ODataBatchResponseBody,
  ODataBatchOption,
  ODataBatchRequestItem
} from "../domain/Api/OData";
import { isApiCallError } from "../domain/Api/ApiError";

export class ApiService {
  public HasMore = false;
  private odataGetNextData?: OdataNextQuery = undefined;
  private apiService = ServiceName.Api;

  constructor(apiService?: ServiceName) {
    if (apiService)
      this.apiService = apiService;
  }

  // inside typescript there is not default(type) function, so default value is passed as parameter,
  // which is returned on 404
  // https://stackoverflow.com/questions/43841506/typescript-equivalent-to-cs-defaultt#:~:text=2%20Answers&text=No%2C%20there%20is%20no%20such,having%20a%20special%20default%20operator
  public Get<T>(
    domainType: string,
    notFoundValue: T | undefined = undefined,
    cancelToken?: CancelToken,
    serviceName?: ServiceName
  ): Promise<T | ApiError> {
    return this.GetDefault<T>(domainType, notFoundValue, cancelToken, serviceName);
  }

  private async GetDefault<T>(
    domainType: string,
    notFoundValue: T | undefined,
    cancelToken?: CancelToken,
    serviceName?: ServiceName
  ): Promise<T | ApiError> {
    return await this.GetRequest(domainType, notFoundValue, cancelToken, serviceName);
  }

  protected GetRequest<T>(
    domainTypeOrUrl: string,
    notFoundValue: T | undefined,
    cancelToken?: CancelToken,
    serviceName?: ServiceName
  ): Promise<T | ApiError> {
    return new Promise(
      (
        resolve: (response: T) => void,
        reject: (response: ApiError) => void
      ) => {
        const eventElement: HttpStatusCodeEventType<T> = new HttpStatusCodeEventType<T>(
          (response: AxiosResponse<T>) => {
            reject(
              new ApiError(
                `Http Status Code ${response.status}: ${response.data}`
              )
            );
          },
          {
            404: (response: AxiosResponse<T>) => {
              if (notFoundValue) {
                resolve(notFoundValue);
              } else {
                reject(new ApiError(`Not found - ${response.statusText}`));
              }
            },
          },
          [
            {
              start: 200,
              end: 300,
              eventFunction: (response: AxiosResponse<T>) => {
                resolve(response.data);
              },
            },
          ]
        );

        const requestFailed = (error: ApiError) => {
          reject(error);
        };

        apiGetEvent<T>(
          domainTypeOrUrl,
          serviceName ?? this.apiService,
          eventElement,
          requestFailed,
          undefined,
          cancelToken
        );
      }
    );
  }

  private PrepareRequest(
    domainTypeOrUrl: string,
    options: ODataOptions
  ): string {
    let urlPath = "";
    if (options === undefined) options = {};

    if (domainTypeOrUrl && !domainTypeOrUrl.includes("?")) urlPath += "?";

    if (options.select && options.select.length > 0) {
      urlPath += `${urlPath.endsWith('?') ? '' : '&'}$select=`;
      urlPath += options.select.join(", ");
    }

    if (options.expand) {
      urlPath += `${urlPath.endsWith('?') ? '' : '&'}$expand=`;
      urlPath += options.expand.join(", ");
    }

    if (options.filter?.length) {
      // Go through each filter and format them correctly. Full string is not modified. ODataExpressionOptions will wrap a value string in ''. A number is not wrapped.
      urlPath += `${urlPath.endsWith('?') ? '' : '&'}$filter=`;
      options.filter.forEach((filter: ODataFilterOptions, index: number) => {
        let expression: string;
        const expressionOption: ODataExpressionOptions = filter.expression as ODataExpressionOptions;
        if (expressionOption && expressionOption.column) {
          let expValue = "";
          if (expressionOption.value) {
            const value = Number(expressionOption.value);
            if (isNaN(value)) expValue = `'${expressionOption.value}'`;
            else expValue = value.toString();
          }

          expression = `${expressionOption.column} ${expressionOption.operation ?? ""} ${expValue}`;
        } else {
          expression = filter.expression.toString();
        }

        urlPath += `${(index === 0 ? "" : " ")}${(index !== 0 && filter.operation ? `${filter.operation} ` : "")}${expression}`;
      });
    }

    if (options.orderby) {
      urlPath += `${urlPath.endsWith('?') ? '' : '&'}$orderby=`;
      urlPath += options.orderby.join(", ");
    }

    if (options.top != null) {
      urlPath += `${urlPath.endsWith('?') ? '' : '&'}$top=${options.top}`;
    }

    if (options.skip) {
      urlPath += `${urlPath.endsWith('?') ? '' : '&'}$skip=${options.skip}`;
    }

    if (options.count !== undefined) {
      urlPath += `${urlPath.endsWith('?') ? '' : '&'}$count=${options.count}`;
    }

    return domainTypeOrUrl + urlPath;
  }

  public Post<T, U>(
    domainType: string,
    postObject: U,
    cancelToken?: CancelToken
  ): Promise<T | ApiError> {
    return new Promise(
      (
        resolve: (response: T) => void,
        reject: (response: ApiError) => void
      ) => {
        if (!postObject) {
          reject(new ApiError("PostObject is not set"));
          return;
        }

        const eventElement: HttpStatusCodeEventType<T> = new HttpStatusCodeEventType<T>(
          (response: AxiosResponse<T>) => {
            reject(
              new ApiError(
                `Http Status Code ${response.status}: ${response.data}`
              )
            );
          },
          {
            404: (response: AxiosResponse<T>) => {
              reject(new ApiError(`Not found - ${response.statusText}`));
            },
          },
          [
            {
              start: 200,
              end: 300,
              eventFunction: (response: AxiosResponse<T>) => {
                resolve(response.data);
              },
            },
          ]
        );

        const requestFailed = (error: ApiError) => {
          reject(error);
        };

        apiPostEvent(
          domainType,
          this.apiService,
          postObject,
          eventElement,
          requestFailed,
          undefined,
          cancelToken
        );
      }
    );
  }

  public Patch<T, U>(
    domainType: string,
    patchObject: U
  ): Promise<T | ApiError> {
    return new Promise(
      (
        resolve: (response: T) => void,
        reject: (response: ApiError) => void
      ) => {
        if (!patchObject) {
          reject(new ApiError("PatchObject is not set"));
          return;
        }

        const eventElement: HttpStatusCodeEventType<T> = new HttpStatusCodeEventType<T>(
          (response: AxiosResponse<T>) => {
            reject(
              new ApiError(
                `Http Status Code ${response.status}: ${response.data}`
              )
            );
          },
          {
            404: (response: AxiosResponse<T>) => {
              reject(new ApiError(`Not found - ${response.statusText}`));
            },
          },
          [
            {
              start: 200,
              end: 300,
              eventFunction: (response: AxiosResponse<T>) => {
                resolve(response.data);
              },
            },
          ]
        );

        const requestFailed = (error: ApiError) => {
          reject(error);
        };

        apiPatchEvent(
          domainType,
          this.apiService,
          patchObject,
          eventElement,
          requestFailed
        );
      }
    );
  }

  public Delete<T>(
    domainType: string,
    cancelToken?: CancelToken
  ): Promise<T | ApiError> {
    return new Promise(
      (
        resolve: (response: T) => void,
        reject: (response: ApiError) => void
      ) => {
        const eventElement: HttpStatusCodeEventType<T> = new HttpStatusCodeEventType<T>(
          (response: AxiosResponse<T>) => {
            reject(
              new ApiError(
                `Http Status Code ${response.status}: ${response.data}`
              )
            );
          },
          {
            404: (response: AxiosResponse<T>) => {
              reject(new ApiError(`Not found - ${response.statusText}`));
            },
          },
          [
            {
              start: 200,
              end: 300,
              eventFunction: (response: AxiosResponse<T>) => {
                resolve(response.data);
              },
            },
          ]
        );

        const requestFailed = (error: ApiError) => {
          reject(error);
        };

        apiDeleteEvent(
          domainType,
          this.apiService,
          eventElement,
          requestFailed,
          undefined,
          cancelToken
        );
      }
    );
  }


  public async GetOData<T>(
    domainType: string,
    options: ODataOptions = {},
    notFoundValue?: T[],
    getAll?: boolean,
    cancelToken?: CancelToken
  ): Promise<T[] | ApiError> {
    let responseData = await this.GetODataObjectsWithOptions<T>(
      domainType, options, notFoundValue, cancelToken
    );

    if (isApiCallError(responseData))
      return responseData as ApiError;

    if (getAll) {
      let data = responseData as ODataWrapper<T>;
      let returnValue = data?.value ? data.value : new Array<T>();

      while (data && data["@odata.nextLink"] && data["@odata.nextLink"] !== "") {
        responseData = await this.GetODataObjectsWithUrl<T>(data["@odata.nextLink"], notFoundValue, cancelToken);
        if (isApiCallError(responseData))
          return responseData as ApiError;
        data = responseData as ODataWrapper<T>;
        if (data.value)
          returnValue = returnValue.concat(data.value);
      }

      return returnValue;
    }
    else {
      const data = responseData as ODataWrapper<T>;
      if (!data?.value)
        return new Array<T>();

      return data.value;
    }
  }

  public async GetODataObjectsWithOptions<T>(
    domainType: string,
    options: ODataOptions,
    notFoundValue?: T[],
    cancelToken?: CancelToken
  ): Promise<ODataWrapper<T> | ApiError> {
    return await this.GetODataObjectsWithUrl(this.PrepareRequest(domainType, options), notFoundValue, cancelToken);
  }

  public async GetODataObjectsWithUrl<T>(
    odataUrl: string,
    notFoundValue?: T[],
    cancelToken?: CancelToken
  ): Promise<ODataWrapper<T> | ApiError> {
    const responseData = await this.GetRequest<ODataWrapper<T>>(
      odataUrl,
      { value: notFoundValue, "@odata.count": 0, "@odata.nextLink": "" },
      cancelToken
    );

    this.odataGetNextData = undefined;
    if (isApiCallError(responseData))
      return responseData as ApiError;

    const data = responseData as ODataWrapper<T>;

    if (data) {
      this.odataGetNextData = {
        count: data["@odata.count"],
        nextUrl: data["@odata.nextLink"],
        hasMore: data["@odata.nextLink"] != null
      }
    }

    return data;
  }


  public async GetOdataNext<T>(
    notFoundValue?: T[],
    cancelToken?: CancelToken
  ): Promise<T[] | ApiError> {
    if (!this.odataGetNextData)
      return [];

    const { nextUrl, count, hasMore } = this.odataGetNextData;
    if (!hasMore || count === 0 || !nextUrl || nextUrl === '') return [];

    const responseData = await this.GetODataObjectsWithUrl<T>(nextUrl, notFoundValue, cancelToken);
    if (isApiCallError(responseData))
      return responseData as ApiError;

    const data = responseData as ODataWrapper<T>;
    if (data.value)
      return data.value;

    return notFoundValue ?? [];
  }

  public async GetOdataSingle<T>(
    domainType: string,
    options: ODataOptions = {},
    id?: string | number,
    notFoundValue: T[] | undefined = undefined
  ): Promise<T | ApiError> {
    if (options === undefined) options = {};

    // Add id filter
    if (id) {
      if (!options.filter) options.filter = [];
      const filter: ODataFilterOptions = {
        expression: `id eq ${id}`,
        operation: "",
      };
      if (options.filter != null && options.filter.length > 0) filter.operation = " and ";
      options.filter.push(filter);
    }

    const payload = await this.GetOData<T>(
      domainType,
      options,
      notFoundValue,
      false
    );
    const data = payload as T[];
    if (data) if (data.length > 0) return data[0];

    return payload as ApiError;
  }

  public OdataPatch<T, U>(
    domainId: number | string,
    domainType: string,
    patchObject: U
  ): Promise<T | ApiError> {
    return new Promise(
      (
        resolve: (response: T) => void,
        reject: (response: ApiError) => void
      ) => {
        if (!patchObject) {
          reject(new ApiError("PatchObject is not set"));
          return;
        }

        const eventElement: HttpStatusCodeEventType<T> = new HttpStatusCodeEventType<T>(
          (response: AxiosResponse<T>) => {
            reject(
              new ApiError(
                `Http Status Code ${response.status}: ${response.data}`
              )
            );
          },
          {
            404: (response: AxiosResponse<T>) => {
              reject(new ApiError(`Not found - ${response.statusText}`));
            },
          },
          [
            {
              start: 200,
              end: 300,
              eventFunction: (response: AxiosResponse<T>) => {
                resolve(response.data);
              },
            },
          ]
        );

        const requestFailed = (error: ApiError) => {
          reject(error);
        };

        //To get the changed entity don't forget to add the "prefer" header with the value "return=representation"
        //https://github.com/OData/WebApi/issues/824
        const additionalHeaders: { [key: string]: string } = { Prefer: "return=representation" };

        apiPatchEvent(
          `${domainType}(${domainId.toString()})`,
          this.apiService,
          patchObject,
          eventElement,
          requestFailed,
          undefined,
          undefined,
          undefined,
          additionalHeaders
        );
      }
    );
  }

  public OdataDelete<T>(
    domainId: number | string,
    domainType: string,
    cancelToken?: CancelToken
  ): Promise<boolean | ApiError> {
    return new Promise(
      (
        resolve: (response: boolean) => void,
        reject: (response: ApiError) => void
      ) => {
        const eventElement: HttpStatusCodeEventType<boolean> = new HttpStatusCodeEventType<boolean>(
          (response: AxiosResponse<boolean>) => {
            reject(
              new ApiError(
                `Http Status Code ${response.status}: ${response.data}`
              )
            );
          },
          {
            404: (response: AxiosResponse<boolean>) => {
              reject(new ApiError(`Not found - ${response.statusText}`));
            },
          },
          [
            {
              start: 200,
              end: 300,
              eventFunction: () => {
                resolve(true);
              },
            },
          ]
        );

        const requestFailed = (error: ApiError) => {
          reject(error);
        };

        apiDeleteEvent(
          `${domainType}(${domainId.toString()})`,
          this.apiService,
          eventElement,
          requestFailed,
          undefined,
          cancelToken
        );
      }
    );
  }

  public async ODataBatch<T, U>(
    requestOptions: ODataBatchOption<T>[],
    cancelToken?: CancelToken
  ): Promise<U[][] | ApiError> {

    if (!requestOptions)
      return new ApiError("Options are not set!");

    if (!requestOptions.length)
      return new ApiError("No requests were set!");

    const requestBody: ODataBatchRequestBody<T> = {
      requests: requestOptions.map((item, index): ODataBatchRequestItem<T> => {
        return {
          id: (index + 1).toString(),
          method: item.method,
          url: this.PrepareRequest(item.domainTypeOrUrl, item.options),
          body: item.body
        };
      })
    };

    const responseData = await this.Post<ODataBatchResponseBody<U>, ODataBatchRequestBody<T>>("$batch", requestBody, cancelToken);

    if (isApiCallError(responseData))
      return responseData;

    if (responseData.responses.length !== requestOptions.length)
      return new ApiError("Response length does not match request length!");

    if (responseData.responses.some(response => response.body?.value === undefined))
      return new ApiError("Some response bodys/values are undefined!");

    return responseData.responses.map(response => response.body?.value === undefined ? new Array<U>() : response.body.value);
  }
}