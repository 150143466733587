import { Theme, createMuiTheme, ThemeOptions } from "@material-ui/core/styles";
import { darkTheme } from "./CompeonTheme/darkTheme";
import { lightTheme } from "./CompeonTheme/lightTheme";
import { CSSProperties } from "@material-ui/styles";

const overrides = {
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "1.142rem",
    },
  },
};

export function getTheme(dark: boolean): Theme {
  const theme: ThemeOptions = dark ? darkTheme : lightTheme;
  return createMuiTheme({ ...theme, ...overrides });
}

interface HasMinHeight {
  minHeight?: number
}

export function getMaxOfMinHeightOfToolbar(theme: Theme): number {
  return Math.max(
    ...Object.keys(theme.mixins.toolbar).map((key: string) => {
      const value: unknown = theme.mixins.toolbar[key];
      if (value == null) return 0;

      const obj: HasMinHeight = value as HasMinHeight;
      if (obj && obj.minHeight != null) return obj.minHeight;

      const num: number = value as number;
      if (num != null) {
        return num;
      }

      return 0;
    })
  );
}

export interface SelfServiceShadow {
  widget: string
  widgetDark: string
  widgetWide: string
}

export interface SelfServiceContent {
  background: string
  backgroundSize: string
}

export interface SelfServicePageTitle {
  color: string
}

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    customShadows: {
      widget: string
      widgetDark: string
      widgetWide: string
    }
    content: {
      background: React.CSSProperties["background"]
      backgroundSize: React.CSSProperties["backgroundSize"]
    }
    pageTitle: {
      color: React.CSSProperties["color"]
    }
    drawerFooter: {
      background: React.CSSProperties["background"]
      height: React.CSSProperties["height"]
      width: React.CSSProperties["width"]
      backgroundRepeat: React.CSSProperties["backgroundRepeat"]
      backgroundSize: React.CSSProperties["backgroundSize"]
    }
    compeonLogo: {
      color: React.CSSProperties["color"]
    }

    chonky: {
      colors: {
        debugRed: React.CSSProperties["color"]
        debugBlue: React.CSSProperties["color"]
        debugGreen: React.CSSProperties["color"]
        debugPurple: React.CSSProperties["color"]
        debugYellow: React.CSSProperties["color"]
        datacenterBlue: React.CSSProperties["color"]

        textActive: React.CSSProperties["color"]
      }
      fontSizes: {
        rootPrimary: React.CSSProperties["fontSize"]
      }
      margins: {
        rootLayoutMargin: React.CSSProperties["margin"]
      }
      toolbar: {
        size: number
        lineHeight: React.CSSProperties["lineHeight"] // `px` suffix is required for `line-height` fields to work
        fontSize: React.CSSProperties["fontSize"]
        buttonRadius: number
      }
      dnd: {
        canDropColor: React.CSSProperties["color"]
        cannotDropColor: React.CSSProperties["color"]
        canDropMask: React.CSSProperties["color"]
        cannotDropMask: React.CSSProperties["color"]
        fileListCanDropMaskOne: React.CSSProperties["color"]
        fileListCanDropMaskTwo: React.CSSProperties["color"]
        fileListCannotDropMaskOne: React.CSSProperties["color"]
        fileListCannotDropMaskTwo: React.CSSProperties["color"]
      }
      dragLayer: {
        border: React.CSSProperties["border"]
        padding: React.CSSProperties["padding"]
        borderRadius: React.CSSProperties["borderRadius"]
      }
      fileList: {
        desktopGridGutter: number
        mobileGridGutter: number
      }
      gridFileEntry: {
        childrenCountSize: React.CSSProperties["fontSize"]
        iconColorFocused: React.CSSProperties["color"]
        iconSize: string
        iconColor: React.CSSProperties["color"]
        borderRadius: number
        fontSize: number

        fileColorTint: React.CSSProperties["color"]
        folderBackColorTint: React.CSSProperties["color"]
        folderFrontColorTint: React.CSSProperties["color"]
      }
      listFileEntry: {
        propertyFontSize: number
        iconFontSize: React.CSSProperties["fontSize"]
        iconBorderRadius: number
        fontSize: number
        propertyHeaderFontSize: number
      }
    }
  }

  interface ThemeOptions {
    customShadows: {
      widget: string
      widgetDark: string
      widgetWide: string
    }
    content: {
      background: React.CSSProperties["background"]
      backgroundSize: React.CSSProperties["backgroundSize"]
    }
    pageTitle: {
      color: React.CSSProperties["color"]
    }
    drawerFooter: {
      background: React.CSSProperties["background"]
      height: React.CSSProperties["height"]
      width: React.CSSProperties["width"]
      backgroundRepeat: React.CSSProperties["backgroundRepeat"]
      backgroundSize: React.CSSProperties["backgroundSize"]
    }
    compeonLogo: {
      color: React.CSSProperties["color"]
    }
    chonky: {
      colors: {
        debugRed: React.CSSProperties["color"]
        debugBlue: React.CSSProperties["color"]
        debugGreen: React.CSSProperties["color"]
        debugPurple: React.CSSProperties["color"]
        debugYellow: React.CSSProperties["color"]
        datacenterBlue: React.CSSProperties["color"]

        textActive: React.CSSProperties["color"]
      }
      fontSizes: {
        rootPrimary: React.CSSProperties["fontSize"]
      }
      margins: {
        rootLayoutMargin: React.CSSProperties["margin"]
      }
      toolbar: {
        size: number
        lineHeight: React.CSSProperties["lineHeight"] // `px` suffix is required for `line-height` fields to work
        fontSize: React.CSSProperties["fontSize"]
        buttonRadius: number
      }
      dnd: {
        canDropColor: React.CSSProperties["color"]
        cannotDropColor: React.CSSProperties["color"]
        canDropMask: React.CSSProperties["color"]
        cannotDropMask: React.CSSProperties["color"]
        fileListCanDropMaskOne: React.CSSProperties["color"]
        fileListCanDropMaskTwo: React.CSSProperties["color"]
        fileListCannotDropMaskOne: React.CSSProperties["color"]
        fileListCannotDropMaskTwo: React.CSSProperties["color"]
      }
      dragLayer: {
        border: React.CSSProperties["border"]
        padding: React.CSSProperties["padding"]
        borderRadius: React.CSSProperties["borderRadius"]
      }
      fileList: {
        desktopGridGutter: number
        mobileGridGutter: number
      }
      gridFileEntry: {
        childrenCountSize: React.CSSProperties["fontSize"]
        iconColorFocused: React.CSSProperties["color"]
        iconSize: string
        iconColor: React.CSSProperties["color"]
        borderRadius: number
        fontSize: number

        fileColorTint: React.CSSProperties["color"]
        folderBackColorTint: React.CSSProperties["color"]
        folderFrontColorTint: React.CSSProperties["color"]
      }
      listFileEntry: {
        propertyFontSize: number
        iconFontSize: React.CSSProperties["fontSize"]
        iconBorderRadius: number
        fontSize: number
        propertyHeaderFontSize: number
      }
    }
  }
}

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    header: PaletteColor
    link: PaletteColor
  }
  interface PaletteOptions {
    header: PaletteColorOptions
    link: PaletteColorOptions
  }

  interface TypeBackground {
    transparentPaper: string
  }
}
