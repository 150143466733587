import { RouteProps, Route, Redirect } from "react-router-dom";
import { ExtendedRouteProps } from ".";
import React from "react";
import { BasePage } from "../../components/BasePage";
import { checkAllowedAppRoleTypesInCurrentUserRoles } from "../../enums/Authentication";

export interface BaseProps extends RouteProps {
  component: any
  route: ExtendedRouteProps
}

const SelfServiceRoute = (props: BaseProps): JSX.Element => {
  const { component: Component, route, ...rest } = props;

  if (!checkAllowedAppRoleTypesInCurrentUserRoles(route.allowedAppRoles)) {
    return (
      <Redirect to={{
        pathname: "/notAllowedRoleErrorPage"
      }} />
    );
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        route.usesBasePage
          ?
          <BasePage titleKey={route.pageTitle} route={route}>
            <Component route={route} {...routeProps} />
          </BasePage>
          :
          <Component route={route} {...routeProps} />
      }
    />
  );
};

export default SelfServiceRoute;