import { Method } from 'axios';

export default class ApiCallRelationship {
  href: string;
  type: Method

  constructor(href: string, type: Method) {
    this.href = href;
    this.type = type;
  }
}