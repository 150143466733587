import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2)
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
