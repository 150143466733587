import { ApiError } from "../domain/Api";
import { CheckMathExpressionSyntaxInput, CheckMathExpressionSyntaxOutput } from "../domain/FormularEditor";
import { ApiService } from "./ApiService";

export class FormularEditorApi extends ApiService {

  async checkSyntax(input: CheckMathExpressionSyntaxInput): Promise<CheckMathExpressionSyntaxOutput | ApiError> {
    try {
      return await this.Post<CheckMathExpressionSyntaxOutput, CheckMathExpressionSyntaxInput>(
        '/MathExpressionParser/CheckMathExpressionSyntax',
        input,
      );
    }
    catch (error) {
      return error as ApiError;
    }
  }
}

export const currentFormularEditorApiService = new FormularEditorApi();