import { DataCenterDocument, DataCenterInputAttributes } from "../../domain/DataCenter";
import { ReduxEnums, DatacenterEnums } from "../../enums";
import { ActionTypes } from "../actions";

interface InitialStateType {
  datacenterPageMode?: DatacenterEnums.DatacenterPageModes
  currentDatacenterId?: string
  currentDatacenterAttributes?: DataCenterInputAttributes
  currentDatacenterDocuments?: DataCenterDocument[]
  runningDocumentDownloads: string[]
}

const initialState: InitialStateType = {
  datacenterPageMode: undefined,
  currentDatacenterId: undefined,
  currentDatacenterAttributes: undefined,
  currentDatacenterDocuments: undefined,
  runningDocumentDownloads: []
};

export default function creditProcessPage(
  state = initialState,
  action: ActionTypes
): InitialStateType {
  switch (action.type) {
    case ReduxEnums.DatacenterPageActionsEnum.setDatacenterPageMode:
      return {
        ...state,
        datacenterPageMode: action.payload ? action.payload : undefined,
      };
    case ReduxEnums.DatacenterPageActionsEnum.setCurrentDatacenterId:
      return {
        ...state,
        currentDatacenterId: action.payload,
      };
    case ReduxEnums.DatacenterPageActionsEnum.setCurrentDatacenterAttributes:
      return {
        ...state,
        currentDatacenterAttributes: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    case ReduxEnums.DatacenterPageActionsEnum.setCurrentDatacenterDocuments:
      return {
        ...state,
        currentDatacenterDocuments: action.payload ? new Array<DataCenterDocument>().concat(action.payload) : undefined,
      };
    case ReduxEnums.DatacenterPageActionsEnum.setRunningDocumentDownloads:
      return {
        ...state,
        runningDocumentDownloads: action.payload,
      };
    case ReduxEnums.DatacenterPageActionsEnum.deleteDocumentFromRunningDocumentDownloads:
      return {
        ...state,
        runningDocumentDownloads: [].filter((documentId: string) => documentId !== action.payload),
      };
    case ReduxEnums.DatacenterPageActionsEnum.addDocumentFromRunningDocumentDownloads:
      return {
        ...state,
        runningDocumentDownloads: new Array<string>().concat(state.runningDocumentDownloads).concat([action.payload]),
      };
    default:
      return state;
  }
}