import { ApiError } from "../domain/Api";
import {
  CreateParametrisationRuleForParametrisationFeatureInput, DeleteParametrisationRulesOfParametrisationFeatureInput, ImportParametrisationRulesForParametrisationFeatureInput,
  SaveParametrisationRulesForParametrisationFeatureInput
} from "../domain/Parametrisation";
import SaveParametrisationRulesForParametrisationFeatureOutput from "../domain/Parametrisation/SaveParametrisationRulesForParametrisationFeatureOutput";
import { ApiService } from "./ApiService";

export class ParametrisationFeatureApi extends ApiService {

  async saveParametrisationRulesForParametrisationFeature(input: SaveParametrisationRulesForParametrisationFeatureInput): Promise<SaveParametrisationRulesForParametrisationFeatureOutput | ApiError> {
    try {
      return await this.Post<SaveParametrisationRulesForParametrisationFeatureOutput, SaveParametrisationRulesForParametrisationFeatureInput>(
        '/ParametrisationFeature/SaveParametrisationRulesForParametrisationFeature',
        input,
      );
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async ensureStoryFeatureHasParametrisationRules(storyFeatureId: number): Promise<boolean | ApiError> {
    try {
      return await this.Get<boolean>(`/ParametrisationFeature/EnsureStoryFeatureHasParametrisationRules?storyFeatureId=${storyFeatureId}`);
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async deleteParametrisationRulesOfParametrisationFeature(input: DeleteParametrisationRulesOfParametrisationFeatureInput): Promise<void | ApiError> {
    try {
      await this.Post<unknown, DeleteParametrisationRulesOfParametrisationFeatureInput>(
        '/ParametrisationFeature/DeleteParametrisationRulesOfParametrisationFeature',
        input,
      );
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async createParametrisationRuleForParametrisationFeature(input: CreateParametrisationRuleForParametrisationFeatureInput): Promise<void | ApiError> {
    try {
      await this.Post<unknown, CreateParametrisationRuleForParametrisationFeatureInput>(
        '/ParametrisationFeature/CreateParametrisationRuleForParametrisationFeature',
        input,
      );
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async importParametrisationRulesForParametrisationFeature(input: ImportParametrisationRulesForParametrisationFeatureInput): Promise<void | ApiError> {
    try {
      await this.Post<unknown, ImportParametrisationRulesForParametrisationFeatureInput>(
        '/ParametrisationFeature/ImportParametrisationRulesForParametrisationFeature',
        input,
      );
    }
    catch (error) {
      return error as ApiError;
    }
  }
}

export const currentParametrisationFeatureApiService = new ParametrisationFeatureApi();