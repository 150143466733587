export enum UiActionsEnum {
  togglePageIsLoading = "TogglePageIsLoading",
  toggleDrawerIsOpen = "ToggleDrawerIsOpen",
}

export enum InquiryActionsEnum {
  isStepValid = "IsStepValid",
  nextStep = "nextStep",
  updateNewInquiry = "updateNewInquiry",
  updateInquiries = "updateInquiries",
  updateBasicNewInquiry = "updateBasicNewInquiry",
}

export enum ProductActionsEnum {
  storeProduct = "StoreProduct",
  updateProduct = "UpdateProduct",
  updateProducts = "UpdateProducts",
  updateProductAssessment = "UpdateProductAssessment",
  updateProductDetails = "UpdateProductDetails",
  storeInquiry = "StoreInquiry",
  storeInquiryMetadata = "StoreInquiryMetadata",
  storeSelectedProduct = "StoreSelectedProduct",
  storeSelectedProductAssessment = "StoreSelectedProductAssessment",
  setProductDetailsShown = "SetProductDetailsShown",
  setFulfillment = "SetFulfillment",
  setKpiSectionStructure = "SetKpiSectionStructure",
  onKpiPropertyChange = "OnKpiPropertyChange",
  setLimitProperties = "SetLimitProperties",
}

export enum ParameterisationAssessmentActionsEnum {
  setParameterisation = "SetParameterisation",
  setParameterisations = "SetParameterisations",
  setProductParameterisation = "SetProductParameterisation",
  setProductParameterisations = "SetProductParameterisations",
}

export enum BanksPageActionsEnum {
  setSelectedBank = "bankPage_setSelectedBank",
  setSelectedParameter = "bankPage_setSelectedParameter",
}

export enum BankParameterPageActionsEnum {
  setSelectedBank = "bankParameterPage_setSelectedBank",
  setSelectedParameter = "bankParameterPage_setSelectedParameter",
  setParameterDiagramSchema = "setParameterDiagramSchema",
  setParameterStoryFeatures = "setParameterStoryFeatures",
  setSelectStoryFeaturesFilter = "setSelectStoryFeaturesFilter",
  storeSelectFeatures = "storeSelectFeatures",
  setSelectedAddFeature = "setSelectedAddFeature",
  setStory = "setStory",
  setFeatureNodeToEdit = "setFeatureNodeToEdit",
  setStoryFeatureMetaDatas = "setStoryFeatureMetaDatas",
  setParametrisationRules = "setParametrisationRules",
}

export enum ParametrisationFeatureConfigurationPageActionsEnum {
  setSelectedBank = "parametrisationFeatureConfigurationPage_setSelectedBank",
  setSelectedParameter = "parametrisationFeatureConfigurationPage_setSelectedParameter",
  setSelectedStoryFeature = "setSelectedStoryFeature",
  setParametrisationRules = "setParametrisationRules",
  setAddParametrisationRuleCardShown = "setAddParametrisationRuleCardShown",
  setAlertObject = "setAlertObject",
  setStoryFeatureParametrisationRulesQuery = "setStoryFeatureParametrisationRulesQuery",
  setShowUpsertParametrisationRuleDialog = "setShowUpsertParametrisationRuleDialog",
  setShowImportParametrisationRulesDialog = "setShowImportParametrisationRulesDialog",
  setParametrisationRuleToEdit = "setParametrisationRuleToEdit",
  setParametrisationConnectedParametrisationRulesReload = "setParametrisationConnectedParametrisationRulesReload",
}

export enum CreditProcessPageActionsEnum {
  setSelectedBank = "creditProcessPageActionsEnum_setSelectedBank",
  setSelectedParameter = "creditProcessPageActionsEnum_setSelectedParameter",
  setLatestStoryResult = "setLatestStoryResult",
  setLatestStoryFeatureResults = "setLatestStoryFeatureResults",
  updateLatestStoryFeatureResult = "updateLatestStoryFeatureResult",
  setStoryFeatures = "setStoryFeatures",
  setSelectedInquiry = "setSelectedInquiry",
}

export enum KpiCheckPageActionsEnum {
  setSelectedBank = "bankParameterPage_setSelectedBank",
  setSelectedParameter = "creditProcessPageActionsEnum_setSelectedParameter",
  setSelectedTender = "setSelectedTender",
  setKpiCheck = "setKpiCheck",
  setKpiSections = "setKpiSections",
  changeKpiValueCalculationCounter = "changeKpiValueCalculationCounter",
  setCompleteKpisValidation = "setCompleteKpisValidation",
}

export enum AuthenticationActionsEnum {
  setUserAppRoleTypes = "setUserAppRoleTypes",
}

export enum CallInfoPageActionsEnum {
  setCallKeyFacts = "setCallKeyFacts",
  setContacts = "setContacts",
  setLeads = "setLeads",
  setCallProtocols = "setCallProtocols",
}

export enum DatacenterPageActionsEnum {
  setDatacenterPageMode = "setDatacenterPageMode",
  setCurrentDatacenterId = "setCurrentDatacenterId",
  setCurrentDatacenterAttributes = "setCurrentDatacenterAttributes",
  setCurrentDatacenterDocuments = "setCurrentDatacenterDocuments",
  setRunningDocumentDownloads = "setRunningDocumentDownloads",
  deleteDocumentFromRunningDocumentDownloads = "deleteDocumentFromRunningDocumentDownloads",
  addDocumentFromRunningDocumentDownloads = "addDocumentFromRunningDocumentDownloads"
}

export enum StartPageActionsEnum {
  maxStartEndOfCanvas = "maxStartEndOfCanvas",
  pushDataToTimeline = "pushDataToTimeline",
  updateTimeline = "updateTimeline",
  updateYearsToLoad = "updateYearsToLoad"
}