import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autocompleteInputButtonGroup: {
      display: 'flex'
    },
    autocompleteInputTextField: {
      flexGrow: 1
    },
    controlBarAutocomplete: {
      width: "300px"
    },
    textArea: {
      width: '100%',
      resize: 'vertical',
      marginTop: theme.spacing(1),
    },
    formControl:{
      width: '100%',
      marginTop: theme.spacing(1),
    },
    buttonControls:{
      marginTop: theme.spacing(1),
    }
  }),
);

export default useStyles;