import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import * as mui from '@material-ui/icons';
import { getIconTypeByKey, IconType, MaterialUiIconSearch } from "../MaterialUiIconSearch";
import { Avatar, FormHelperText } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useStyles from './AvatarMaterialUiIconPicker.styles';
import classNames from "classnames";
import { getIconSpanByIconType } from "../MaterialUiIconSearch/MaterialUiIconSearch";
import i18n from "i18next";

type MuiIndexType = { [k: string]: typeof mui[keyof typeof mui] };

export type AvatarMaterialUiIconPickerProps = {
  selectedIconKey: string | null
  onSelectedIconKeyChange: (iconKey: string) => void
  avatarClassName?: string
}

const AvatarMaterialUiIconPicker: FunctionComponent<AvatarMaterialUiIconPickerProps> = ({ selectedIconKey, onSelectedIconKeyChange, avatarClassName }) => {
  const classes = useStyles();

  const materialUiSerachRef = useRef<HTMLElement>(null);

  const [selectedIcon, setSelectedIcon] = useState<IconType | null>(null);
  const [isIconDialogOpen, setIsIconDialogOpen] = useState<boolean>(false);

  const selectIcon = (icon: IconType): void => {
    onSelectedIconKeyChange(icon.key);
    setSelectedIcon(icon);
  }

  useEffect(() => {
    if (!selectedIconKey) {
      setSelectedIcon(null);
      return;
    }

    const icon = getIconTypeByKey(selectedIconKey);
    setSelectedIcon(icon ?
      icon
      : null
    )

  }, [selectedIconKey]);

  const handleIconDialogEntering = () => {
    if (materialUiSerachRef.current != null) {
      materialUiSerachRef.current.focus();
    }
  };

  return (
    <>
      <Avatar
        className={classNames(classes.avatar, avatarClassName)}
        onClick={() => { setIsIconDialogOpen(true) }}
      >
        {selectedIcon
          ? getIconSpanByIconType(selectedIcon, classes.icon, classes.iconSvg)
          : <span>{i18n.t('Components:avatarIconPicker.chooseIconText')}</span>
        }
      </Avatar>
      {selectedIcon == null && <FormHelperText error>{i18n.t('Components:avatarIconPicker.iconRequired')}</FormHelperText>}
      <Dialog
        onEntering={handleIconDialogEntering}
        aria-labelledby="add-parametrisation-rule-dialog-title"
        open={isIconDialogOpen}
        classes={{
          paper: classes.iconDialog,
        }}
        onClose={() => { setIsIconDialogOpen(false) }}
      >
        <DialogTitle >{i18n.t('Components:avatarIconPicker.chooseIcon')}</DialogTitle>
        <DialogContent dividers
          ref={materialUiSerachRef}
        >
          <MaterialUiIconSearch
            showSelectTag={false}
            onSelectedIconChanged={(icon: IconType) => { selectIcon(icon); setIsIconDialogOpen(false); }}
            selectedIcon={selectedIcon}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AvatarMaterialUiIconPicker;