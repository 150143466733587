import { StoryFeature } from "../../domain/StoryFeature";
import { FeatureType } from "../../enums/Feature";

export const psdStoryFeatures : StoryFeature[] =  [
  {
    createdById: 'jss',
    createdDate: new Date(),
    featureId: 1,
    id: 1,
    lastModifiedById: 'jss',
    lastModifiedDate: new Date(),
    storyId: 1,
    xcoordinate: 1,
    ycoordinate: 1,
    feature: {
      createdById: "jss",
      createdDate: new Date(),
      description: 'Überprüfung der Parametrisierung des Produkts',
      id: 1,
      lastModifiedById: 'jss',
      lastModifiedDate: new Date(),
      name: 'Parametrisierung',
      type: FeatureType.Parametrisation
    }
  },
  {
    createdById: 'jss',
    createdDate: new Date(),
    featureId: 2,
    id: 2,
    lastModifiedById: 'jss',
    lastModifiedDate: new Date(),
    storyId: 1,
    xcoordinate: 1,
    ycoordinate: 1,
    feature: {
      createdById: "jss",
      createdDate: new Date(),
      description: 'Durchführung des KPI Checks',
      id: 2,
      lastModifiedById: 'jss',
      lastModifiedDate: new Date(),
      name: 'KPI Check',
      type: FeatureType.KpiCheck
    }
  },
  {
    createdById: 'jss',
    createdDate: new Date(),
    featureId: 3,
    id: 3,
    lastModifiedById: 'jss',
    lastModifiedDate: new Date(),
    storyId: 1,
    xcoordinate: 1,
    ycoordinate: 1,
    feature: {
      createdById: "jss",
      createdDate: new Date(),
      description: 'Eingabe weiterer Informationen',
      id: 3,
      lastModifiedById: 'jss',
      lastModifiedDate: new Date(),
      name: 'Application',
      type: FeatureType.Application
    }
  },
  {
    createdById: 'jss',
    createdDate: new Date(),
    featureId: 4,
    id: 4,
    lastModifiedById: 'jss',
    lastModifiedDate: new Date(),
    storyId: 1,
    xcoordinate: 1,
    ycoordinate: 1,
    feature: {
      createdById: "jss",
      createdDate: new Date(),
      description: 'Verarbeitung von Verträgen',
      id: 4,
      lastModifiedById: 'jss',
      lastModifiedDate: new Date(),
      name: 'DocuSign',
      type: FeatureType.DocuSign
    }
  }
]