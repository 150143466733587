import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from "i18next-http-backend";
import { getIdToken } from "../auth/authProvider";
import { getServiceBaseUrl } from "../services/getServiceBaseUrl";
import {
  bankParameter_de,
  bankParameter_en,
  banks_de,
  banks_en,
  documents_de,
  documents_en,
  headerInfo_de,
  headerInfo_en,
  imageMapEditor_de,
  imageMapEditor_en,
  layout_de,
  layout_en,
  notFound_de,
  notFound_en,
  parametrisationFeatureConfig_de,
  parametrisationFeatureConfig_en,
  startPage_de,
  startPage_en,
  components_de,
  components_en,
  routesConstants_de,
  routesConstants_en,
  enums_de,
  enums_en,
  creditProcess_en,
  creditProcess_de,
  crefoInformation_de,
  crefoInformation_en,
  crefoInvoice_en,
  crefoInvoice_de,
  imageMediathek_de,
  imageMediathek_en,
  notAllowedRoleErrorPage_de,
  notAllowedRoleErrorPage_en,
  selfServiceRoute_de,
  selfServiceRoute_en,
  callInfo_de,
  callInfo_en,
  dataCenter_de,
  dataCenter_en,
  chonky_de,
  chonky_en,
  timeline_de,
  timeline_en,
  documentParsing_de,
  documentParsing_en
} from "./index";
import { ServiceName } from "../enums/Services";
import { ApiError } from "../domain/Api";
import { isApiCallError } from "../domain/Api/ApiError";

const cookieName = '.AspNetCore.Culture'
const defaultLanguage = 'de-DE'

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length < 2)
    return;

  const pop = parts.pop()
  if (!pop)
    return;

  return pop.split(";").shift();
}

const customDetector = {
  name: 'customDetector',

  lookup() {
    const cookie = getCookie(cookieName);
    if (!cookie)
      return defaultLanguage;

    const result = cookie.match(new RegExp("c=(.*)uic")); // should be |uic, but don't know how to use | inside regex
    if (!result || result.length < 2)
      return defaultLanguage;

    return result[1].substring(0, result[1].length - 1); // | at the end shouldn't be inside the culture code
  }
}

const languageDetector = new LanguageDetector();
languageDetector.addDetector(customDetector);

i18n

  /*using 18next-http-backend to make api call
  learn more: https://github.com/i18next/i18next-http-backend
  */
  .use(HttpBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    load: 'all',
    supportedLngs: ['en-EN', 'de-DE'],
    fallbackLng: defaultLanguage,
    nonExplicitSupportedLngs: false,
    defaultNS: 'defaultTranslation',
    ns: ['Layout', 'HeaderInfo', 'BankParameter', 'Components', 'ImageMapEditor', 'RoutesConstants'],
    detection: {
      order: ['customDetector', 'cookie'],
      lookupCookie: cookieName
    },
    backend: {
      loadPath: `${getServiceBaseUrl(ServiceName.Api)}/Localization?$filter=Locale eq '{{lng}}' and TransNamespace eq '{{ns}}'`,
      request: function (requestOptions: any, url: string, payload: any, callback: any) {
        getIdToken().then((accessToken: string | ApiError) => {
          if(isApiCallError(accessToken)){
            callback(accessToken.getMessage(), { status: 404 });
            return;
          }

          fetch(url, {
            method: 'GET',
            headers: {
              authorization: `Bearer ${accessToken}`
            }
          })
            .then((res) => {
              res.text()
                .then(locale => callback(null, { status: 200, data: locale }))
            })
            .catch(error => callback(error, { status: 404 }));
        })
      },
      parse: function (data: any) {
        const obj = JSON.parse(data);
        if (!obj || !obj.value)
          return {};

        const parsedObject: Record<string, string> = {};
        obj.value.forEach((element: any) => {
          parsedObject[element.transKey] = element.translation;
        });

        return parsedObject;
      }
    },
    interpolation: {
      format: (value, format, lng) => {
        if (format === 'intlDate') {
          return new Intl.DateTimeFormat(lng).format(value);
        }
    
        return value;
      }
    },
    react: {
      wait: true, // set to true if you like to wait for loaded in every translated hoc
      nsMode: 'default', // set it to fallback to let passed namespaces to translated hoc act as fallbacks
    }
  })

i18n.on('languageChanged', function (lng) {
  setLanguageCookie(lng);
})

function setLanguageCookie(languageKey?: string) {
  if (!languageKey)
    return;
  document.cookie = `${cookieName}=c=${languageKey}|uic=${languageKey}; SameSite=None; Secure`;
}

i18n.addResourceBundle('de-DE', 'RoutesConstants', routesConstants_de, true, false);
i18n.addResourceBundle('en-EN', 'RoutesConstants', routesConstants_en, true, false);

i18n.addResourceBundle('de-DE', 'Components', components_de, true, false);
i18n.addResourceBundle('en-EN', 'Components', components_en, true, false);

i18n.addResourceBundle('de-DE', 'BankParameter', bankParameter_de, true, false);
i18n.addResourceBundle('en-EN', 'BankParameter', bankParameter_en, true, false);

i18n.addResourceBundle('de-DE', 'Banks', banks_de, true, false);
i18n.addResourceBundle('en-EN', 'Banks', banks_en, true, false);

i18n.addResourceBundle('de-DE', 'Documents', documents_de, true, false);
i18n.addResourceBundle('en-EN', 'Documents', documents_en, true, false);

i18n.addResourceBundle('de-DE', 'HeaderInfo', headerInfo_de, true, false);
i18n.addResourceBundle('en-EN', 'HeaderInfo', headerInfo_en, true, false);

i18n.addResourceBundle('de-DE', 'ImageMapEditor', imageMapEditor_de, true, false);
i18n.addResourceBundle('en-EN', 'ImageMapEditor', imageMapEditor_en, true, false);

i18n.addResourceBundle('de-DE', 'NotFound', notFound_de, true, false);
i18n.addResourceBundle('en-EN', 'NotFound', notFound_en, true, false);

i18n.addResourceBundle('de-DE', 'ParametrisationFeatureConfig', parametrisationFeatureConfig_de, true, false);
i18n.addResourceBundle('en-EN', 'ParametrisationFeatureConfig', parametrisationFeatureConfig_en, true, false);

i18n.addResourceBundle('de-DE', 'Layout', layout_de, true, false);
i18n.addResourceBundle('en-EN', 'Layout', layout_en, true, false);

i18n.addResourceBundle('de-DE', 'StartPage', startPage_de, true, false);
i18n.addResourceBundle('en-EN', 'StartPage', startPage_en, true, false);

i18n.addResourceBundle('de-DE', 'Enums', enums_de, true, false);
i18n.addResourceBundle('en-EN', 'Enums', enums_en, true, false);

i18n.addResourceBundle('de-DE', 'CreditProcess', creditProcess_de, true, false);
i18n.addResourceBundle('en-EN', 'CreditProcess', creditProcess_en, true, false);

i18n.addResourceBundle('de-DE', 'crefoInvoice', crefoInvoice_de, true, false);
i18n.addResourceBundle('en-EN', 'crefoInvoice', crefoInvoice_en, true, false);

i18n.addResourceBundle('de-DE', 'crefoInformation', crefoInformation_de, true, false);
i18n.addResourceBundle('en-EN', 'crefoInformation', crefoInformation_en, true, false);

i18n.addResourceBundle('de-DE', 'ImageMediathek', imageMediathek_de, true, false);
i18n.addResourceBundle('en-EN', 'ImageMediathek', imageMediathek_en, true, false);

i18n.addResourceBundle('de-DE', 'NotAllowedRoleErrorPage', notAllowedRoleErrorPage_de, true, false);
i18n.addResourceBundle('en-EN', 'NotAllowedRoleErrorPage', notAllowedRoleErrorPage_en, true, false);

i18n.addResourceBundle('de-DE', 'SelfServiceRoute', selfServiceRoute_de, true, false);
i18n.addResourceBundle('en-EN', 'SelfServiceRoute', selfServiceRoute_en, true, false);

i18n.addResourceBundle('de-DE', 'CallInfo', callInfo_de, true, false);
i18n.addResourceBundle('en-EN', 'CallInfo', callInfo_en, true, false);

i18n.addResourceBundle('de-DE', 'DataCenter', dataCenter_de, true, false);
i18n.addResourceBundle('en-EN', 'DataCenter', dataCenter_en, true, false);

i18n.addResourceBundle('de-DE', 'chonky', chonky_de, true, false);
i18n.addResourceBundle('en-EN', 'chonky', chonky_en, true, false);

i18n.addResourceBundle('de-DE', 'Timeline', timeline_de, true, false);
i18n.addResourceBundle('en-EN', 'Timeline', timeline_en, true, false);

i18n.addResourceBundle('de-DE', 'DocumentParsing', documentParsing_de, true, false);
i18n.addResourceBundle('en-EN', 'DocumentParsing', documentParsing_en, true, false);

export default i18n;