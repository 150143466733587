import { DiagramSchema } from "beautiful-react-diagrams/@types/DiagramSchema";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { BankVisibilityParameterSets } from "../../../../domain/Bank";
import { Story } from "../../../../domain/StoryFeature";
import { RootState } from "../../../../store/reduxStore";
import { BankParameterStoryDiagram, NoBankParameterStoryAvailable } from "./components";

const BankParameterStory: FunctionComponent = () => {

  const selectedParameter: BankVisibilityParameterSets | undefined = useSelector((state: RootState) => state.bankParameterPage.selectedParameter);
  const parameterDiagramSchema: DiagramSchema<unknown> | null = useSelector((state: RootState) => state.bankParameterPage.parameterDiagramSchema);
  const story: Story | null = useSelector((state: RootState) => state.bankParameterPage.story);


  if (!selectedParameter || !parameterDiagramSchema || !story)
    return (
      <NoBankParameterStoryAvailable />
    );

  return (
    <>
      <BankParameterStoryDiagram
        schema={parameterDiagramSchema}
        storyId={story.id}
      />
    </>
  );
};

export default BankParameterStory;

