/**
 * DataFormatTypes, useful to indentify how to organize the items in timeline.
 * Depending of the type it would be show as TimelineDataStandard[] or TimelineGroupedDataStandard[]
 */
export enum TimelineDataFormatTypes {
  Minute = 'Minute',
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year'
}

/**
 * Months of the year, useful to indentify translations.
 */
export enum TimelineMonths {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}

/**
 * Days of the Week, useful to indentify translations.
 */
export enum TimelineWeekDays {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday'
}

/**
 * Timeline options. Useful to identify translationa and keep the available options organize. 
 * Options are the items inside the anchor (LeftHeader).
 */
export enum TimelineOption {
  TimelineSettings = "TimelineSettings",
  TimelineHelp = "TimelineHelp"
}

/**
 * Dialogs Actions. Useful to identify what kind of Dialog we will like to open.
 */
export enum ActionDialogsOptions {
  InsertEntry = 'InsertEntry',
  EditEntry = 'EditEntry',
  TimelineDataPreview = 'TimelineDataPreview'
}

/**
 * Values of the form inside InsertEntry.  Useful to identify each input inside InsertEntry.
 */
export enum InsertEntryValues {
  StartDate = 'startDate',
  EndDate = 'endDate',
  Type = 'type',
  Icon = 'icon',
  Reference = 'reference',
  Notes = 'notes',
  Other = 'other'

}

/**
 * Values to identify what type of form is been sent after submit or cancel inside InsertEntry.
 */
export enum FormRequestTypes {
  Insert = 'Insert',
  Edit = 'Edit',
  Cancel = 'Cancel'
}

/**
 * Interpratation of the labelWidth (a number). This way we can standarize all possible numbers outcome to only 4 values.
 */
export enum LabelWidthIs {
  Small= 'Small',
  Medium = 'Medium',
  MediumLong = 'MediumLong',
  Long = 'Long'
}