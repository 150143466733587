import React, { useState, FunctionComponent, ReactNode, MouseEvent } from "react";
import { Inbox as InboxIcon } from "@material-ui/icons";
import { Collapse, Divider, List, ListItem, ListItemIcon, ListItemText, Typography, } from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";
import * as H from 'history';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// styles
import useStyles from "./SidebarLink.styles";
import { useTranslation } from "react-i18next";
import { AppRoleTypes } from "../../enums/Authentication";

// components

export type SidebarLinkLocation = {
  pathname: string
};

export type SidebarLinkProps = {
  id?: number
  link?: string
  linkIsExact?: boolean
  icon?: ReactNode
  label?: string
  children?: SidebarLinkProps[]
  location?: H.Location<H.LocationState>
  isSidebarOpened?: boolean
  nested?: boolean
  type?: string
  allowedAppRoles?: AppRoleTypes[]
}

const SidebarLink: FunctionComponent<SidebarLinkProps> = ({
  link,
  linkIsExact = true,
  icon,
  label,
  children,
  location,
  isSidebarOpened,
  nested,
  type,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('RoutesConstants', { useSuspense: false })

  // local
  const [isOpen, setIsOpen] = useState(false);
  const isLinkActive =
    link && link !== '' && location &&
    (location.pathname === link || (!linkIsExact && location.pathname.indexOf(link) !== -1));

  if (type === "title")
    return (

      <Typography
        className={classnames(classes.selfservice_linkText, classes.selfservice_sectionTitle, {
          [classes.selfservice_linkTextHidden]: !isSidebarOpened,
        })}
      >
        {label ? t(label) : ''}
      </Typography>
    );

  if (type === "divider") return <Divider className={classes.selfservice_divider} />;

  if (!children) {
    return (
      <ListItem
        button
        component={Link}
        to={link || ""}
        className={classes.selfservice_link}
        classes={{
          root: classnames(classes.selfservice_link, {
            [classes.selfservice_linkActive]: isLinkActive,
            [classes.selfservice_linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <ListItemIcon
          className={classnames(classes.selfservice_linkIcon, {
            [classes.selfservice_linkIconActive]: isLinkActive,
          })}
        >
          {icon ? icon : <InboxIcon />}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.selfservice_linkText, {
              [classes.selfservice_linkTextActive]: isLinkActive,
              [classes.selfservice_linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label ? t(label) : ''}
        />
      </ListItem>
    );
  }


  return (
    <>
      <ListItem
        button
        component={Link}
        onClick={toggleCollapse}
        className={classes.selfservice_link}
        to={link || ""}
        disableRipple
      >
        <ListItemIcon
          className={classnames(classes.selfservice_linkIcon, {
            [classes.selfservice_linkIconActive]: isLinkActive,
          })}
        >
          {icon ? icon : <InboxIcon />}
          {
            !isSidebarOpened && (
              isOpen
                ? (
                  <ExpandLessIcon />
                )
                : (
                  <ExpandMoreIcon />
                )
            )
          }
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.selfservice_linkText, {
              [classes.selfservice_linkTextActive]: isLinkActive,
              [classes.selfservice_linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label
            ? <div style={{ display: 'flex', verticalAlign: 'center' }}>
              {t(label)}
              {
                isSidebarOpened && (
                  isOpen
                    ? (
                      <ExpandLessIcon />
                    )
                    : (
                      <ExpandMoreIcon />
                    )
                )
              }
            </div>
            : ''
          }
        />
      </ListItem>
      {
        children && (
          <Collapse
            in={isOpen}
            timeout="auto"
            unmountOnExit
            className={classes.selfservice_nestedList}
          >
            <List component="div" disablePadding>
              {children.map(childrenLink => (
                // <SidebarLink
                //     key={childrenLink && childrenLink.link}
                //     location={location}
                //     isSidebarOpened={isSidebarOpened}
                //     classes={classes}
                //     nested
                //     {...childrenLink}
                // />
                <SidebarLink
                  key={childrenLink && childrenLink.link}
                  isSidebarOpened={isSidebarOpened}
                  location={location}
                  nested
                  {...childrenLink}
                />
              ))}
            </List>
          </Collapse>
        )
      }
    </>
  );

  // ###########################################################

  function toggleCollapse(e: MouseEvent) {
    // if (isSidebarOpened) {
    e.preventDefault();
    setIsOpen(!isOpen);
    // }
  }
}

export default SidebarLink;



// className = {
//   classnames({
//     [classes.selfservice_nestedList]: isSidebarOpened
//   }
//   )
// }