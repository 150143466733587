import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const appStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
    background: theme.content.background,
    backgroundSize: theme.content.backgroundSize,
    height: "100vh"
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    width: `calc(100vw - 240px)`,
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
}));

export default appStyles;
