import React, { FunctionComponent, useEffect, useRef, useState, useCallback } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { ParametrisationRule } from "../../../../domain/Parametrisation";
import useStyles from './ImportParametrisationRulesDialog.styles';
import MaterialTable, { Column, Filter, Query, QueryResult, MaterialTableProps } from 'material-table';
import { materialTableDefaultLocalization, materialTableIcons } from "../../../../components/MaterialTable";
import { useDispatch } from "react-redux";
import { Avatar, IconButton } from "@material-ui/core";
import classNames from "classnames";
import { TrafficLight } from "../../../../enums/Parametrisation";
import { getIconSpanByIconKey } from "../../../../components/MaterialUiIconSearch/MaterialUiIconSearch";
import { currentParametrisationRuleApiService } from "../../../../services/ParametrisationRuleApi";
import { ODataFilterOptions, ODataOptions, ODataWrapper } from "../../../../domain/Api/OData";
import { isApiCallError } from "../../../../domain/Api/ApiError";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/reduxStore";
import { ParametrisationFeatureConfigurationPageActions } from "../../../../store/actions";
import { StoryFeature } from "../../../../domain/StoryFeature";
import { currentParametrisationFeatureApiService } from "../../../../services/ParametrisationFeatureApi";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DoneIcon from '@material-ui/icons/Done';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';

enum headerCheckedParametrisationRulesChecked {
  all = "currentFiltering",
  onlyChecked = "onlyChecked"
}

const parametrisationRulesTableLocalization = {
  ...materialTableDefaultLocalization
};

export type ImportParametrisationRulesDialogProps = {
  dialogClasses: Record<'paper', string>
  keepMounted: boolean
}

const ImportParametrisationRulesDialog: FunctionComponent<ImportParametrisationRulesDialogProps> = ({ dialogClasses, keepMounted }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('ParametrisationFeatureConfig');
  const formGroupRef = useRef<HTMLElement>(null);

  const tableRef = useRef<MaterialTableProps<ParametrisationRule>>();

  const [checkedParametrisationRules, setCheckedParametrisationRules] = useState<ParametrisationRule[]>([]);

  const [parametrisationRulesQuery, setParametrisationRulesQuery] = useState<Query<ParametrisationRule>>({
    filters: new Array<Filter<ParametrisationRule>>(),
    orderBy: {
      field: 'displayName'
    },
    orderDirection: "asc",
    page: 0,
    pageSize: 5,
    search: '',
    totalCount: 10
  });

  const selectedStoryFeature: StoryFeature | undefined = useSelector((state: RootState) => state.parametrisationFeatureConfigurationPage.selectedStoryFeature);

  const showImportParametrisationRulesDialog: boolean = useSelector((state: RootState) => state.parametrisationFeatureConfigurationPage.showImportParametrisationRulesDialog);
  const setShowImportParametrisationRulesDialog = (showDialog: boolean) => {
    dispatch(ParametrisationFeatureConfigurationPageActions.setShowImportParametrisationRulesDialog(showDialog));
  }

  const setStoryFeatureParametrisationRulesQuery = (reloadDate?: Date) => {
    dispatch(ParametrisationFeatureConfigurationPageActions.setParametrisationConnectedParametrisationRulesReload(reloadDate));
  };

  const updatedCheckedParametrisationRulesCallback = useCallback((updatedCheckedParametrisationRules: ParametrisationRule[]) => setCheckedParametrisationRules(updatedCheckedParametrisationRules), []);

  const toggleParametrisationRuleSelection = (parametrisationRuleToToggle: ParametrisationRule, select: boolean) => {
    const updatedCheckedParametrisationRules = new Array<ParametrisationRule>().concat(checkedParametrisationRules);
    const checkedParametrisationRulesIndex = updatedCheckedParametrisationRules.findIndex((checked: ParametrisationRule) => checked.id === parametrisationRuleToToggle.id);

    if (select && checkedParametrisationRulesIndex < 0) {
      updatedCheckedParametrisationRules.push(parametrisationRuleToToggle);
    }
    else if (!select && checkedParametrisationRulesIndex >= 0) {
      updatedCheckedParametrisationRules.splice(checkedParametrisationRulesIndex, 1);
    }

    updatedCheckedParametrisationRulesCallback(updatedCheckedParametrisationRules);
  }

  const [filterCheckedParametrisationRules, setFilterCheckedParametrisationRules] = useState<headerCheckedParametrisationRulesChecked>(headerCheckedParametrisationRulesChecked.all);

  const renderIdParametrisationRulesColumn = (checkedParametrisationRules: ParametrisationRule[]) => (rowData: ParametrisationRule) => {
    const currentSelected = checkedParametrisationRules.find((checked: ParametrisationRule) => checked.id === rowData.id) != null;

    return (
      <IconButton
        onClick={() => { toggleParametrisationRuleSelection(rowData, !currentSelected); }}
        title={currentSelected ? t('ParametrisationFeatureConfig:rulesDialog.isSelected') : t('ParametrisationFeatureConfig:rulesDialog.isNotSelected')}
      >
        {currentSelected ? <DoneIcon /> : <CheckBoxOutlineBlankIcon />}
      </IconButton>
    );
  };

  const [parametrisationRulesColumns, setParametrisationRulesColumns] = useState<Column<ParametrisationRule>[]>([]);

  const setDefaultParametrisationRulesColumns = () => {
    setParametrisationRulesColumns([
      {
        field: 'id',
        title: (
          <FormControl>
            <Select
              labelId="filter-checked-or-not-checked-label"
              id="filter-checked-or-not-checked-select"
              value={filterCheckedParametrisationRules}
              onChange={(event: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
                const newValue = event.target.value as headerCheckedParametrisationRulesChecked;
                setFilterCheckedParametrisationRules(newValue);
              }}
            >
              <MenuItem value={headerCheckedParametrisationRulesChecked.all}>Alle</MenuItem>
              <MenuItem value={headerCheckedParametrisationRulesChecked.onlyChecked}>Nur ausgewählt</MenuItem>
            </Select>
          </FormControl>
        ),
        render: renderIdParametrisationRulesColumn(checkedParametrisationRules),
        filtering: false,
        sorting: false,

      },
      {
        field: 'displayName',
        title: t('ParametrisationFeatureConfig:rulesDialog.displayName'),
        defaultFilter: parametrisationRulesQuery.filters?.find((filter: Filter<ParametrisationRule>) => filter.column.field === "displayName")?.value,
        defaultSort: parametrisationRulesQuery.orderBy?.field === 'displayName' ? parametrisationRulesQuery.orderDirection : undefined
      },
      {
        field: 'hint',
        title: t('ParametrisationFeatureConfig:rulesDialog.hint'),
        defaultFilter: parametrisationRulesQuery.filters?.find((filter: Filter<ParametrisationRule>) => filter.column.field === "hint")?.value,
        defaultSort: parametrisationRulesQuery.orderBy?.field === 'hint' ? parametrisationRulesQuery.orderDirection : undefined
      },
      {
        field: 'readableCondition',
        title: t('ParametrisationFeatureConfig:rulesDialog.condition'),
        defaultFilter: parametrisationRulesQuery.filters?.find((filter: Filter<ParametrisationRule>) => filter.column.field === "readableCondition")?.value,
        defaultSort: parametrisationRulesQuery.orderBy?.field === 'readableCondition' ? parametrisationRulesQuery.orderDirection : undefined
      },
      {
        field: 'trafficLight',
        title: t('ParametrisationFeatureConfig:rulesDialog.trafficLight'),
        defaultFilter: parametrisationRulesQuery.filters?.find((filter: Filter<ParametrisationRule>) => filter.column.field === "trafficLight")?.value,
        defaultSort: parametrisationRulesQuery.orderBy?.field === 'trafficLight' ? parametrisationRulesQuery.orderDirection : undefined,
        render: (rule: ParametrisationRule) => (
          <Avatar
            className={classNames(
              {
                [classes.greenAvatar]: rule.trafficLight === TrafficLight.green,
                [classes.yellowAvatar]: rule.trafficLight === TrafficLight.yellow,
                [classes.redAvatar]: rule.trafficLight === TrafficLight.red,
              }
            )
            }
          >
            {
              rule.iconKey != null &&
              getIconSpanByIconKey(rule.iconKey, classes.icon, classes.iconSvg)
            }
          </Avatar >
        )
      }
    ]);
  }

  useEffect(() => {
    setDefaultParametrisationRulesColumns();

    if (tableRef.current && tableRef.current.onQueryChange)
      tableRef.current.onQueryChange(parametrisationRulesQuery)
  }, [filterCheckedParametrisationRules])


  useEffect(() => {
    setDefaultParametrisationRulesColumns();

    if (filterCheckedParametrisationRules === headerCheckedParametrisationRulesChecked.onlyChecked && tableRef.current && tableRef.current.onQueryChange) {
      let queryToUse = parametrisationRulesQuery;

      const checkedParametrisationRulesLength = checkedParametrisationRules.length;

      // if the last element of a page is unchecked, the page is not available, so use last existing page
      // ele MaterialTable ends into endless reloading loop :D 
      if (checkedParametrisationRulesLength <= queryToUse.page * queryToUse.pageSize) {
        queryToUse = Object.assign({}, queryToUse);

        while (queryToUse.page > 0 && checkedParametrisationRulesLength <= queryToUse.page * queryToUse.pageSize) {
          queryToUse.page -= 1;
        }
        setParametrisationRulesQuery(queryToUse);
      }
      tableRef.current.onQueryChange(queryToUse)
    }
  }, [checkedParametrisationRules])

  const handleEntering = () => {
    if (formGroupRef.current != null) {
      formGroupRef.current.focus();
    }
  };

  const resetSelection = () => {
    setCheckedParametrisationRules([]);
  }

  const handleCancel = () => {
    resetSelection();
    setStoryFeatureParametrisationRulesQuery(new Date());
    setShowImportParametrisationRulesDialog(false);
  };

  const handleOk = async () => {
    if (!selectedStoryFeature || !checkedParametrisationRules.length)
      return;

    await currentParametrisationFeatureApiService.importParametrisationRulesForParametrisationFeature({
      storyFeatureId: selectedStoryFeature.id,
      parametrisationRules: checkedParametrisationRules
    });

    resetSelection();
    setStoryFeatureParametrisationRulesQuery(new Date());
    setShowImportParametrisationRulesDialog(false);
  };

  const getCheckedParametrisationRulesForQuery = (query: Query<ParametrisationRule>): QueryResult<ParametrisationRule> => {
    let queryCheckedParametrisationRules = new Array<ParametrisationRule>().concat(checkedParametrisationRules);

    query.filters.forEach((ctFilter: Filter<ParametrisationRule>) => {
      if (!ctFilter.column.field || ctFilter.value == null)
        return;

      const filterValue = ctFilter.value.toLowerCase();
      queryCheckedParametrisationRules = queryCheckedParametrisationRules.filter((rule: ParametrisationRule) =>
        rule && ctFilter.column.field && ('' + rule[ctFilter.column.field]) != null && ('' + rule[ctFilter.column.field]).toLowerCase().includes(filterValue)
      );

    });

    if (query.search && query.search !== '') {
      const querySearch = query.search.toLowerCase();
      queryCheckedParametrisationRules = queryCheckedParametrisationRules.filter((rule: ParametrisationRule) =>
        rule && ["displayName", "hint", "trafficLight", "readableCondition", "iconKey"].find((property: string) => ('' + rule[property]) != null && ('' + rule[property]).toLowerCase().includes(querySearch))
      );
    }

    if (query.orderBy && query.orderBy.field && query.orderBy.field !== '') {
      queryCheckedParametrisationRules = queryCheckedParametrisationRules.sort((a: ParametrisationRule, b: ParametrisationRule) => {
        if (!a && !b)
          return 0;

        if (a && !b) {
          return query.orderDirection == "asc" ? 1 : -1;
        }

        if (!a && b) {
          return query.orderDirection == "asc" ? -1 : 1;
        }

        return (query.orderDirection == "asc" ? 1 : -1) * (query.orderBy.field
          ? a[query.orderBy.field] < b[query.orderBy.field]
            ? -1
            : a[query.orderBy.field] == b[query.orderBy.field]
              ? 0
              : 1
          : 0);
      })
    }

    const currentPageIndexStart = query.page * query.pageSize;
    const currentPageIndexEnd = currentPageIndexStart + query.pageSize;
    const queryCheckedParametrisationRulesLength = queryCheckedParametrisationRules.length;
    const dataOnlyForCurrentPage = queryCheckedParametrisationRules.slice(currentPageIndexStart, currentPageIndexEnd);

    return {
      data: dataOnlyForCurrentPage,
      // sometimes problem if one element was unchecked and so there amount of checked changes and the page is not existing anymore, so set to 0
      page: query.page * query.pageSize >= queryCheckedParametrisationRulesLength ? 0 : query.page,
      totalCount: queryCheckedParametrisationRulesLength//query.totalCount//
    };
  }

  const loadParametrisationRulesForTable = async (query: Query<ParametrisationRule>): Promise<QueryResult<ParametrisationRule>> => {
    if (filterCheckedParametrisationRules === headerCheckedParametrisationRulesChecked.onlyChecked) {
      return getCheckedParametrisationRulesForQuery(query);
    }

    const filter = new Array<ODataFilterOptions>();

    if (selectedStoryFeature)
      filter.push({
        expression: `not StoryFeatureParametrisationRule/any(s: s/StoryFeatureId eq ${selectedStoryFeature.id})`,
        operation: 'and'
      });

    query.filters.forEach((ctFilter: Filter<ParametrisationRule>) => {
      if (!ctFilter.column.field)
        return;

      filter.push({
        expression: `contains(${ctFilter.column.field},'${ctFilter.value}')`,
        operation: 'and'
      });
    }
    );

    if (query.search && query.search !== '') {
      const searchQueryLower = query.search.toLowerCase();
      const searchQueryExpression =
        `(${["DisplayName", "Hint", "TrafficLight", "ReadableCondition", "IconKey"].map((columnName: string) => `contains(${columnName},'${searchQueryLower}')`)
          .join(' or ')})`;

      filter.push({
        expression: searchQueryExpression,
        operation: 'and'
      });
    }

    let orderby: string[] | undefined;
    if (query.orderBy && query.orderBy.field && query.orderBy.field !== '') {
      orderby = [`${query.orderBy.field} ${query.orderDirection}`];
    }

    const oDataOptions: ODataOptions = {
      filter,
      orderby,
      count: true,
      skip: query.pageSize * query.page,
      top: query.pageSize
    };
    const response = await currentParametrisationRuleApiService.getSpecificParametrisationRules(oDataOptions);
    const responseOdata = response as ODataWrapper<ParametrisationRule>;
    if (isApiCallError(response) || !responseOdata) {
      return {
        data: new Array<ParametrisationRule>(),
        page: 0,
        totalCount: 0
      };
    }

    return {
      data: responseOdata.value ?? new Array<ParametrisationRule>(),
      page: query.page,
      totalCount: responseOdata["@odata.count"]
    }
  }

  if (!showImportParametrisationRulesDialog)
    return null;

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      onEntering={handleEntering}
      aria-labelledby="add-parametrisation-rules-dialog-title"
      open={showImportParametrisationRulesDialog}
      classes={dialogClasses}
      keepMounted={keepMounted}
    >
      <MaterialTable
        tableRef={tableRef}
        title={t('ParametrisationFeatureConfig:rulesDialog.addRule')}
        columns={parametrisationRulesColumns}
        data={loadParametrisationRulesForTable}
        icons={materialTableIcons}
        options={{
          filtering: true,
          tableLayout: 'fixed'
        }}
        localization={parametrisationRulesTableLocalization}
        onFilterChange={(filters: Filter<ParametrisationRule>[]) => {
          const updatedQuery = Object.assign({}, parametrisationRulesQuery);
          updatedQuery.filters = filters;
          updatedQuery.page = 0; // reset page so it's reloading all
          setParametrisationRulesQuery(updatedQuery);
        }}
        onSearchChange={(searchText: string) => {
          const updatedQuery = Object.assign({}, parametrisationRulesQuery);
          updatedQuery.search = searchText;
          updatedQuery.page = 0; // reset page so it's reloading all
          setParametrisationRulesQuery(updatedQuery);
        }}
        onChangeRowsPerPage={(pageSize: number) => {
          const updatedQuery = Object.assign({}, parametrisationRulesQuery);
          updatedQuery.pageSize = pageSize;
          updatedQuery.page = 0; // reset page so it's reloading all
          setParametrisationRulesQuery(updatedQuery);
        }}
        onChangePage={(page: number, pageSize: number) => {
          const updatedQuery = Object.assign({}, parametrisationRulesQuery);
          updatedQuery.page = page;
          updatedQuery.pageSize = pageSize;
          setParametrisationRulesQuery(updatedQuery);
        }}
        onOrderChange={(orderBy: number, orderDirection: "asc" | "desc") => {
          if (parametrisationRulesColumns.length <= orderBy)
            return;

          const updatedQuery = Object.assign({}, parametrisationRulesQuery);
          updatedQuery.orderBy = parametrisationRulesColumns[orderBy];
          updatedQuery.orderDirection = orderDirection;
          updatedQuery.page = 0; // reset page so it's reloading all
          setParametrisationRulesQuery(updatedQuery);
        }}
      />
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="secondary">
          {t('ParametrisationFeatureConfig:rulesDialog.cancel')}
        </Button>
        <Button
          onClick={handleOk}
          color="primary"
          disabled={!checkedParametrisationRules.length}
        >
          {t('ParametrisationFeatureConfig:rulesDialog.import')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImportParametrisationRulesDialog;
