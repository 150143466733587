import React, { FunctionComponent, } from "react";
import { Bank, BankVisibilityParameterSets } from "../../../domain/Bank";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import useStyles from './BankHeaderInformation.styles';
import { useHistory } from 'react-router-dom';
import * as H from 'history';
import { RoutesConstants } from "../../../constants";
import { StoryFeature } from "../../../domain/StoryFeature";
import i18n from "i18next";

export type BankHeaderInformationProps = {
  selectedBank?: Bank
  onSelectedBankClick?: (bank: Bank) => void
  selectedParameter?: BankVisibilityParameterSets
  onSelectedParameterClick?: (parameter: BankVisibilityParameterSets) => void
  selectedStoryFeature?: StoryFeature
  onSelectedStoryFeatureClick?: (storyFeature: StoryFeature) => void
};

const BankHeaderInformation: FunctionComponent<BankHeaderInformationProps> = ({ selectedBank, onSelectedBankClick: propOnSelectedBankClick, selectedParameter,
  onSelectedParameterClick: propOnSelectedParameterClick, selectedStoryFeature, onSelectedStoryFeatureClick: propOnSelectedStoryFeatureClick }) => {
  const classes = useStyles();
  const history: H.History<H.LocationState> = useHistory();

  if (!selectedBank)
    return (
      <Typography
      >
        {i18n.t('HeaderInfo:bankHeader')}
      </Typography>
    );

  const onSelectedBankClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (!selectedBank)
      return;

    if (propOnSelectedBankClick)
      propOnSelectedBankClick(selectedBank);

    if (selectedParameter)
      history.push(`${RoutesConstants.routePath.banksPage}/${selectedBank.id}`);
  }

  const onSelectedParameterClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (!selectedParameter)
      return;

    if (propOnSelectedParameterClick)
      propOnSelectedParameterClick(selectedParameter);


    if (selectedStoryFeature)
      history.push(`${RoutesConstants.routePath.banksPage}/${selectedBank.id}/${selectedParameter.id}`);
  }

  const onSelectedStoryFeatureClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (!propOnSelectedStoryFeatureClick || !selectedStoryFeature)
      return;

    propOnSelectedStoryFeatureClick(selectedStoryFeature);
  }

  return (
    <div className={classes.root}>
      {
        selectedBank.attributes["logo-url"] != null &&
        <img
          src={selectedBank.attributes["logo-url"]}
          className={classes.bankLogo}
        />
      }
      <Breadcrumbs aria-label="breadcrumb" className={classes.toolbarTitle} >
        <Link color={selectedParameter ? "inherit" : "textPrimary"} href="/" onClick={onSelectedBankClick}>
          {selectedBank.attributes["display-name"].trim()}
        </Link>
        {selectedParameter &&
          <Link color={selectedStoryFeature ? "inherit" : "textPrimary"} href="/" onClick={onSelectedParameterClick}>
            {selectedParameter.attributes.title}
          </Link>
        }
        {
          selectedStoryFeature &&
          <Typography color="textPrimary" onClick={onSelectedStoryFeatureClick}>{selectedStoryFeature?.feature?.name ?? "Unbenanntes Feature"}</Typography>
        }
      </Breadcrumbs >
    </div >
  );
}

export default BankHeaderInformation;
