import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import useStyles from "./ParametrisationRulesViewTable.styles";
import MaterialTable, { Column, Filter, MaterialTableProps, Query, QueryResult } from 'material-table';
import { ParametrisationRule } from "../../../../domain/Parametrisation";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import classNames from "classnames";
import { TrafficLight } from "../../../../enums/Parametrisation";
import { getIconSpanByIconKey } from "../../../../components/MaterialUiIconSearch/MaterialUiIconSearch";
import { ODataFilterOptions, ODataOptions, ODataWrapper } from "../../../../domain/Api/OData";
import { currentParametrisationRuleApiService } from "../../../../services/ParametrisationRuleApi";
import { isApiCallError } from "../../../../domain/Api/ApiError";
import { materialTableDefaultLocalization, materialTableIcons } from "../../../../components/MaterialTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/reduxStore";
import { StoryFeature } from "../../../../domain/StoryFeature";
import { ParametrisationFeatureConfigurationPageActions } from "../../../../store/actions";
import DeleteIcon from '@material-ui/icons/Delete';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { currentParametrisationFeatureApiService } from "../../../../services/ParametrisationFeatureApi";
import { useTranslation } from 'react-i18next';

const parametrisationRulesTableLocalization = {
  ...materialTableDefaultLocalization
};

const ParametrisationRulesViewTable: FunctionComponent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('ParametrisationFeatureConfig');

  const parametrisationRuleTableRef = useRef<MaterialTableProps<ParametrisationRule>>();

  const selectedStoryFeature: StoryFeature | undefined = useSelector((state: RootState) => state.parametrisationFeatureConfigurationPage.selectedStoryFeature);

  const [storyFeatureParametrisationRulesQuery, setStoryFeatureParametrisationRulesQuery] = useState<Query<ParametrisationRule>>(
    {
      filters: new Array<Filter<ParametrisationRule>>(),
      orderBy: { field: 'displayName' },
      orderDirection: 'asc',
      page: 0,
      pageSize: 5,
      search: '',
      totalCount: 10
    });


  const parametrisationConnectedParametrisationRulesReload: Date | undefined = useSelector((state: RootState) => state.parametrisationFeatureConfigurationPage.parametrisationConnectedParametrisationRulesReload);
  useEffect(() => {
    if (!parametrisationConnectedParametrisationRulesReload)
      return;

    if (parametrisationRuleTableRef.current?.onQueryChange) {
      parametrisationRuleTableRef.current.onQueryChange(storyFeatureParametrisationRulesQuery);
    }
  }, [parametrisationConnectedParametrisationRulesReload])

  const setShowUpsertParametrisationRuleDialog = (showCreateNewParametrisationRuleDialog: boolean) => {
    dispatch(ParametrisationFeatureConfigurationPageActions.setShowUpsertParametrisationRuleDialog(showCreateNewParametrisationRuleDialog));
  }

  const setParametrisationRuleToEdit = (parametrisationRuleToEdit?: ParametrisationRule) => {
    dispatch(ParametrisationFeatureConfigurationPageActions.setParametrisationRuleToEdit(parametrisationRuleToEdit));
  }

  const setShowImportParametrisationRulesDialog = (showImportParametrisationRulesDialog: boolean) => {
    dispatch(ParametrisationFeatureConfigurationPageActions.setShowImportParametrisationRulesDialog(showImportParametrisationRulesDialog));
  }

  const [tableDataIsLoading, setTableDataIsLoading] = useState<boolean>(false);

  const [parametrisationRulesToDelete, setParametrisationRulesToDelete] = useState<ParametrisationRule[] | undefined>(undefined);
  const [deleteParametrisationRulesDialogOpen, setDeleteParametrisationRulesDialogOpen] = useState<boolean>(false);
  const onDeleteSelectedParametrisationRulesClick = (evt: any, data: ParametrisationRule | ParametrisationRule[]) => {
    if (Array.isArray(data))
      setParametrisationRulesToDelete(data);
    else
      setParametrisationRulesToDelete([data]);

    setDeleteParametrisationRulesDialogOpen(true);
  }

  const onAddNewParametrisationRulesClick = () => {
    setShowUpsertParametrisationRuleDialog(true);
  }

  const onImportParametrisationRulesClick = () => {
    setShowImportParametrisationRulesDialog(true);
  }

  const onDeleteParametrisationRulesDialogClose = () => {
    setParametrisationRulesToDelete(undefined);
    setDeleteParametrisationRulesDialogOpen(false);
  }
  const onCancelParametrisationRuleDeletion = () => {
    onDeleteParametrisationRulesDialogClose();
  }

  const executeTableQueryToReloadData = () => {
    if (parametrisationRuleTableRef.current?.onQueryChange) {
      const queryToLoad: Query<ParametrisationRule> = storyFeatureParametrisationRulesQuery;
      parametrisationRuleTableRef.current.onQueryChange(queryToLoad);
    }
  }

  const onConfirmParametrisationRuleDeletion = async () => {
    onDeleteParametrisationRulesDialogClose();

    if (parametrisationRulesToDelete && selectedStoryFeature) {
      setTableDataIsLoading(true);
      await currentParametrisationFeatureApiService.deleteParametrisationRulesOfParametrisationFeature({
        storyFeatureId: selectedStoryFeature.id,
        parametrisationRuleIdsToDelete: parametrisationRulesToDelete.map((p: ParametrisationRule) => p.id)
      });

      checkIfDefaultRulesHaveToBeLoaded(true);
    }
  }

  // therefore at least one Parametrisation should have specific rules, this methods calls api
  // to create default rules if there are no rules. If default rules were created, the data method to load is called
  const checkIfDefaultRulesHaveToBeLoaded = async (forceReload = false) => {
    if (!selectedStoryFeature)
      return;

    setTableDataIsLoading(true);

    const dataNeedsToBeReloaded = await currentParametrisationFeatureApiService.ensureStoryFeatureHasParametrisationRules(selectedStoryFeature.id);

    setTableDataIsLoading(false);

    if ((isApiCallError(dataNeedsToBeReloaded) || !dataNeedsToBeReloaded) && !forceReload)
      return;

    // set reload date, so data is reloaded
    executeTableQueryToReloadData();
  }
  useEffect(() => {
    checkIfDefaultRulesHaveToBeLoaded();
  }, [selectedStoryFeature])

  const [parametrisationRulesColumns, setParametrisationRulesColumns] = useState<Column<ParametrisationRule>[]>([]);
  useEffect(() => {
    setParametrisationRulesColumns(
      [
        {
          field: 'id',
          title: 'Edit',
          filtering: false,
          sorting: false,
          width: '50px',
          render: (rule: ParametrisationRule) => <Tooltip title={`${t('ParametrisationFeatureConfig:rulesViewTable.editRule')}`}>
            <IconButton
              onClick={() => {
                setParametrisationRuleToEdit(rule);
                setShowUpsertParametrisationRuleDialog(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        },
        {
          field: 'displayName',
          title: t('ParametrisationFeatureConfig:rulesViewTable.name'),
          defaultFilter: storyFeatureParametrisationRulesQuery.filters?.find((filter: Filter<ParametrisationRule>) => filter.column.field === "displayName")?.value,
          defaultSort: storyFeatureParametrisationRulesQuery.orderBy?.field === 'displayName' ? storyFeatureParametrisationRulesQuery.orderDirection : undefined
        },
        {
          field: 'hint',
          title: t('ParametrisationFeatureConfig:rulesViewTable.hint'),
          defaultFilter: storyFeatureParametrisationRulesQuery.filters?.find((filter: Filter<ParametrisationRule>) => filter.column.field === "hint")?.value,
          defaultSort: storyFeatureParametrisationRulesQuery.orderBy?.field === 'hint' ? storyFeatureParametrisationRulesQuery.orderDirection : undefined
        },
        {
          field: 'readableCondition',
          title: t('ParametrisationFeatureConfig:rulesViewTable.condition'),
          defaultFilter: storyFeatureParametrisationRulesQuery.filters?.find((filter: Filter<ParametrisationRule>) => filter.column.field === "readableCondition")?.value,
          defaultSort: storyFeatureParametrisationRulesQuery.orderBy?.field === 'readableCondition' ? storyFeatureParametrisationRulesQuery.orderDirection : undefined
        },
        {
          field: 'trafficLight',
          title: t('ParametrisationFeatureConfig:rulesViewTable.trafficLight'),
          defaultFilter: storyFeatureParametrisationRulesQuery.filters?.find((filter: Filter<ParametrisationRule>) => filter.column.field === "trafficLight")?.value,
          defaultSort: storyFeatureParametrisationRulesQuery.orderBy?.field === 'trafficLight' ? storyFeatureParametrisationRulesQuery.orderDirection : undefined,
          render: (rule: ParametrisationRule) => (
            <Avatar
              className={classNames(
                {
                  [classes.greenAvatar]: rule.trafficLight === TrafficLight.green,
                  [classes.yellowAvatar]: rule.trafficLight === TrafficLight.yellow,
                  [classes.redAvatar]: rule.trafficLight === TrafficLight.red,
                }
              )
              }
            >
              {
                rule.iconKey != null &&
                getIconSpanByIconKey(rule.iconKey, classes.icon, classes.iconSvg)
              }
            </Avatar >
          )
        },
      ]
    );
  }, [])

  const loadParametrisationRulesForTable = async (query: Query<ParametrisationRule>): Promise<QueryResult<ParametrisationRule>> => {
    const filter = new Array<ODataFilterOptions>();
    if (selectedStoryFeature)
      filter.push({
        expression: `StoryFeatureParametrisationRule/any(s: s/StoryFeatureId eq ${selectedStoryFeature.id})`,
        operation: 'and'
      });

    query.filters.forEach((ctFilter: Filter<ParametrisationRule>) => {
      if (!ctFilter.column.field)
        return;

      filter.push({
        expression: `contains(${ctFilter.column.field},'${ctFilter.value}')`,
        operation: 'and'
      });
    }
    );

    if (query.search && query.search !== '') {
      const searchQueryLower = query.search.toLowerCase();
      const searchQueryExpression =
        `(${["DisplayName", "Hint", "TrafficLight", "ReadableCondition", "IconKey"].map((columnName: string) => `contains(${columnName},'${searchQueryLower}')`)
          .join(' or ')})`;

      filter.push({
        expression: searchQueryExpression,
        operation: 'and'
      });
    }

    let orderby: string[] | undefined;
    if (query.orderBy && query.orderBy.field && query.orderBy.field !== '') {
      orderby = [`${query.orderBy.field} ${query.orderDirection}`];
    }

    const oDataOptions: ODataOptions = {
      filter,
      orderby,
      count: true,
      skip: query.pageSize * query.page,
      top: query.pageSize
    };
    const response = await currentParametrisationRuleApiService.getSpecificParametrisationRules(oDataOptions);

    const responseOdata = response as ODataWrapper<ParametrisationRule>;
    if (isApiCallError(response) || !responseOdata)
      return {
        data: new Array<ParametrisationRule>(),
        totalCount: 0,
        page: 0
      };

    setStoryFeatureParametrisationRulesQuery(Object.assign({}, query));

    return {
      data: !responseOdata?.value ? [] : responseOdata.value.map((p: ParametrisationRule) => ({
        ...p
      })),
      totalCount: responseOdata["@odata.count"],
      page: query.page
    };
  }

  if (!selectedStoryFeature)
    return null;

  return (
    <>
      <MaterialTable
        tableRef={parametrisationRuleTableRef}
        title={
          <>
            <Typography component="h3" variant="h3" align="center" color="textPrimary" gutterBottom>
              {t('ParametrisationFeatureConfig:rulesViewTable.rules')}
            </Typography>
            <Typography component="span" variant="body2" align="center" color="textPrimary" gutterBottom>
              {t('ParametrisationFeatureConfig:rulesViewTable.minRule')}
            </Typography>
          </>
        }
        columns={parametrisationRulesColumns}
        data={loadParametrisationRulesForTable}
        icons={materialTableIcons}
        options={{
          filtering: true,
          tableLayout: 'fixed',
          selection: true,
        }}
        actions={
          [
            {
              tooltip: t('ParametrisationFeatureConfig:rulesViewTable.createRule'),
              icon: () => <AddIcon />,
              onClick: onAddNewParametrisationRulesClick,
              isFreeAction: true
            },
            {
              tooltip: t('ParametrisationFeatureConfig:rulesViewTable.importRule'),
              icon: () => <ImportExportIcon />,
              onClick: onImportParametrisationRulesClick,
              isFreeAction: true
            },
            {
              tooltip: t('ParametrisationFeatureConfig:rulesViewTable.deleteRule'),
              icon: () => <DeleteIcon />,
              onClick: onDeleteSelectedParametrisationRulesClick
            }
          ]
        }
        localization={parametrisationRulesTableLocalization}
        isLoading={tableDataIsLoading}
      />
      <Dialog
        fullScreen={fullScreen}
        open={deleteParametrisationRulesDialogOpen}
        onClose={onDeleteParametrisationRulesDialogClose}
        aria-labelledby="parametrisation-rule-delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">{t('ParametrisationFeatureConfig:rulesViewTable.deleteRuleTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('ParametrisationFeatureConfig:rulesViewTable.confirmDelete', { count: parametrisationRulesToDelete?.length ?? 0})}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onCancelParametrisationRuleDeletion} color="secondary">{t('ParametrisationFeatureConfig:rulesViewTable.cancel')}</Button>
          <Button
            onClick={onConfirmParametrisationRuleDeletion}
            color="primary"
            autoFocus
            disabled={(parametrisationRulesToDelete?.length ?? 0) === 0}
          >{t('ParametrisationFeatureConfig:rulesViewTable.confirm')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ParametrisationRulesViewTable;