// followed by https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/migration-guide.md
import { Configuration, RedirectRequest, PublicClientApplication, SilentRequest, AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { ApiError } from "../domain/Api";
import { Providers, ProviderState, SimpleProvider } from '@microsoft/mgt-element';
import { isApiCallError } from "../domain/Api/ApiError";
import { instanceOfIdTokenClaims } from "../domain/Auth";
import { AppRoleTypes, getUserRoleEnumCase } from "../enums/Authentication";

// Authentication Parameters
export const scopes: string[] = ["openid", "profile", "User.Read"];
export const redirectRequest: RedirectRequest = {
  scopes
};

// export const tokenRequestParameters = {
//   scopes: [`api://${window.REACT_APP_MS_CLIENTID}/access_as_user`]
// }

const pcaConfiguration: Configuration = {
  auth: {
    authority: "https://login.microsoftonline.com/compeon.de",
    clientId: window.REACT_APP_MS_CLIENTID,
    redirectUri: window.location.protocol + "//" + window.location.host,

  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
  }
}

export const publicClientApplication = new PublicClientApplication(pcaConfiguration);

/**
 * This refreshes tokens without the user noticing anything in case a user loses/gets a role
 * @param account Contains AccountInfo
 * @returns AuthenticationResult with id- and accessToken
 */
const getTokenSilent = async(account: AccountInfo) : Promise<AuthenticationResult> => {
  const request: SilentRequest = {
    scopes,
    account,
    forceRefresh: true
  }

  return publicClientApplication.acquireTokenSilent(request);
}

export async function getIdToken(): Promise<string | ApiError> {
  const account = getCurrentAccount();
  
  if (!account) {
    return new ApiError("No Account available");
  }

  const idToken = await getTokenSilent(account).then((response) => {
    return response.idToken;
  }).catch(error => {
    // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
    console.log(error);
    return new ApiError(error?.message);
  });

  return idToken;
}

export async function getAccessToken(): Promise<string | ApiError> {
  const account = getCurrentAccount();

  if (!account) {
    return new ApiError("No Account available");
  }

  const accessToken = await getTokenSilent(account).then((response) => {
    return response.accessToken;
  }).catch(error => {
    // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
    console.log(error);
    return new ApiError(error?.message);
  });

  return accessToken;
}

/**
 * Requests the current account. 
 * @returns Current account or undefined.
 */
export function getCurrentAccount(): AccountInfo | undefined {
  const accounts = publicClientApplication.getAllAccounts();
  if (!accounts || !accounts.length) {
    return ;
  }

  return accounts[0];
}

/**
 * Requests the UserRoles for the current Account using the idTokenClaim
 * @returns UserRoles for Current Account or APIError
 */
export function getUserRoles(): AppRoleTypes[] {
  const account = getCurrentAccount();

  if (!account) {
    return [];
  }
  
  if(!instanceOfIdTokenClaims(account.idTokenClaims)){
    return [];
  }
  const userRoles = account.idTokenClaims.roles;
  const userRolesEnumArray: AppRoleTypes[] = [];
  userRoles.forEach((entry:string) => {
    const userRolesEnumCase = getUserRoleEnumCase(entry);
    if(userRolesEnumCase === undefined) {
      console.log(`Couldn't find user role ${entry}`);
      return;
    }
    userRolesEnumArray.push(userRolesEnumCase);
  });

  return userRolesEnumArray;

}

Providers.globalProvider = new SimpleProvider(
  async (): Promise<string> => {
    const idToken = await getAccessToken();

    return new Promise(
      (
        resolve: (response: string) => void,
        reject: (response: ApiError) => void
      ) => {
        if (isApiCallError(idToken)) {
          reject(idToken);
        }
        else {
          resolve(idToken);
        }
      })
  },
  async (): Promise<void> => {
    Providers.globalProvider.setState(ProviderState.SignedIn)
  },
  async (): Promise<void> => {
    Providers.globalProvider.setState(ProviderState.SignedOut)
  },
);

// We're using MsalProvider of @azure/msal-react, which automatically logs in, so we have to inform 
// Provider of mgt-element, that it is logged in
// set state to signal to all components to start calling graph
Providers.globalProvider.setState(ProviderState.SignedIn)
