export enum StoryResultState {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Completed = "Completed",
  Waiting = "Waiting",
  ErrorWhileExecution = "ErrorWhileExecution"
}

export function getStoryResultStateLabel(enumValue: StoryResultState): string {
  switch (enumValue) {
    case StoryResultState.InProgress:
      return "In Bearbeitung";
    case StoryResultState.NotStarted:
      return "Nicht gestartet";
    case StoryResultState.Completed:
      return "Ausgeführt";
    case StoryResultState.ErrorWhileExecution:
      return "Fehlgeschlagen";
    default:
      return "Unbekannt";
  }
}

export enum StoryFeatureResultState {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Completed = "Completed",
  Waiting = "Waiting",
  ErrorWhileExecution = "ErrorWhileExecution"
}

export function getStoryFeatureResultStateLabel(enumValue: StoryFeatureResultState): string {
  switch (enumValue) {
    case StoryFeatureResultState.InProgress:
      return "In Bearbeitung";
    case StoryFeatureResultState.NotStarted:
      return "Nicht gestartet";
    case StoryFeatureResultState.Completed:
      return "Ausgeführt";
    case StoryFeatureResultState.ErrorWhileExecution:
      return "Fehlgeschlagen";
    default:
      return "Unbekannt";
  }
}

export enum DocuSignContractStatusType {
  Sent = "Sent",
  DeliveryFailed = "DeliveryFailed",
  Delivered = "Delivered",
  Completed = "Completed",
  Declined = "Declined",
  AuthenticationFailure = "AuthenticationFailure",
  Created = "Created"
}

export function getDocuSignContractStatusTypeLabel(enumValue: DocuSignContractStatusType): string {
  switch (enumValue) {
    case DocuSignContractStatusType.Sent:
      return "Sent";
    case DocuSignContractStatusType.DeliveryFailed:
      return "DeliveryFailed";
    case DocuSignContractStatusType.Delivered:
      return "Delivered";
    case DocuSignContractStatusType.Completed:
      return "Completed";
    case DocuSignContractStatusType.Declined:
      return "Declined";
    case DocuSignContractStatusType.AuthenticationFailure:
      return "AuthenticationFailure";
    case DocuSignContractStatusType.Created:
      return "Created";
    default:
      return "Unbekannt";
  }
}

export enum OutputType {
  Green = "Green",
  Yellow = "Yellow",
  Red = "Red",
  ZinsFound = "ZinsFound",
  ZinsNotFound = "ZinsNotFound",
  True = "True",
  KpisValid = "KpisValid",
  KpisInvalid = "KpisInvalid",
  ApplicationValid = "ApplicationValid",
  ApplicationInvalid = "ApplicationInvalid",
  DocuSignCompleted = "DocuSignCompleted",
  DocuSignFailed = "DocuSignFailed"
}