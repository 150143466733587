import _ from "lodash";
import { TimelineDataStandard, TimelineGroupedDataStandard, TimelineTrack } from './domain';
import { TimelineDataFormatTypes, TimelineMonths } from './TimelineEnums';
export class TimelineHelpers {


  durationIsMoreEqualThan = (duration : number, timeInMinutes : number) : boolean => {
    return duration >= timeInMinutes * 60000;
  }

  translateMonth = (month: string) => {
    let translatedMonth: string;
    switch (month) {
      case TimelineMonths.January:
        translatedMonth = 'Timeline:topHeader.months.january'
        break;
      case TimelineMonths.February:
        translatedMonth = 'Timeline:topHeader.months.february'
        break;
      case TimelineMonths.March:
        translatedMonth = 'Timeline:topHeader.months.march'
        break;
      case TimelineMonths.April:
        translatedMonth = 'Timeline:topHeader.months.april'
        break;
      case TimelineMonths.May:
        translatedMonth = 'Timeline:topHeader.months.may'
        break;
      case TimelineMonths.June:
        translatedMonth = 'Timeline:topHeader.months.june'
        break;
      case TimelineMonths.July:
        translatedMonth = 'Timeline:topHeader.months.july'
        break;
      case TimelineMonths.August:
        translatedMonth = 'Timeline:topHeader.months.august'
        break;
      case TimelineMonths.September:
        translatedMonth = 'Timeline:topHeader.months.september'
        break;
      case TimelineMonths.October:
        translatedMonth = 'Timeline:topHeader.months.october'
        break;
      case TimelineMonths.November:
        translatedMonth = 'Timeline:topHeader.months.november'
        break;
      case TimelineMonths.December:
        translatedMonth = 'Timeline:topHeader.months.december'
        break;
      default:
        translatedMonth = ''
        break;
    }

    return translatedMonth
  }

  msToDate = (ms: number): Date => {
    const date = new Date(ms)
    return date
  }

  hourMinuteString = ( date : Date, locales?: string) : string => {
    const result = date.toLocaleTimeString(locales, { hour: '2-digit', minute: '2-digit' } )
    return result
  }

  muiTimeFormat = (date: Date): string => {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const day = date.getDate().toString().padStart(2, "0")
    const time = this.hourMinuteString(date)
    return `${year}-${month}-${day}T${time}`
  }


  uniqueData = (data: TimelineDataStandard[]): TimelineDataStandard[] => {

    const unique = _.uniqBy(data, "id")

    return unique
  }

  uniqueTracks = (data: TimelineTrack[]): TimelineTrack[] => {

    const unique = _.uniqBy(data, "id")

    const uniqueMapped = unique.map((item): TimelineTrack => {
      return { ...(item) }
    }) as TimelineTrack[]

    return uniqueMapped
  }

  combineGroupedDataArrays = (data: TimelineGroupedDataStandard[][]): TimelineGroupedDataStandard[] => {
    let result: TimelineGroupedDataStandard[] = []

    data.forEach(arr => {
      result = result.concat(arr)
    })

    return result
  }

  dataFormatToShow = (
    data: TimelineDataStandard[],
    tracks: TimelineTrack[],
    format: TimelineDataFormatTypes
  ): TimelineDataStandard[] | TimelineGroupedDataStandard[] => {

    let dataFormatToShow: TimelineDataStandard[]| TimelineGroupedDataStandard[];
   
    switch (format) {
      case TimelineDataFormatTypes.Hour:
      case TimelineDataFormatTypes.Week:
      case TimelineDataFormatTypes.Minute:
        dataFormatToShow = data
        break
      case TimelineDataFormatTypes.Day:
        //Months you have days.
        dataFormatToShow = this.groupByDay(data, tracks)
        break  
      case TimelineDataFormatTypes.Month:
        //Months you have days.
        dataFormatToShow = this.groupByMonth(data, tracks)
        break
      case TimelineDataFormatTypes.Year:
        //Months you have Year.
        dataFormatToShow = this.groupByYear(data, tracks)
        break
      default:
        //Safety net.
        dataFormatToShow = data
    }
    
    return dataFormatToShow
  }
  
  groupByDay = (data: TimelineDataStandard[], tracks: TimelineTrack[]): TimelineGroupedDataStandard[] => {
    const occurrences = data.reduce((acc: Record<string, number>, curr: TimelineDataStandard) => {
      acc[`${curr.group}-${curr.year}-${curr.month}-${curr.day}`] = (acc[`${curr.group}-${curr.year}-${curr.month}-${curr.day}`] || 0) + 1;
      return acc;
    }, {})

    const result = Object.entries(occurrences)

    const resultByDay: TimelineGroupedDataStandard[][] = Array.from({ length: tracks.length }, () => [])

    result.forEach(item => {
      //Since we have dashes we can identify what kind of data is this
      const [group, year, month, day] = item[0].split("-")
      const total = item[1]

      //We check for the index of the current ID inside the available tracks. Ex: track ID can be 878 but is index 2. 
      const currentArrayIndex: number = tracks.findIndex(track => track.id === Number(group))

      if (currentArrayIndex <= -1) {
        //If there is an item who doesnt belong to any track, we dont group it.
        return
      }
      
      //First we create Date values to transform it to moment.
      const dateStart = new Date(Number(year), Number(month) - 1, Number(day))
      const dateEnd = new Date(Number(year), Number(month) - 1, Number(day) + 1)

      //We need the Miliseconds for our timeline
      const start_time = dateStart.getTime()
      const end_time = dateEnd.getTime()
      //Group is just for the readibility. Space is to later divide the string and after get each value with split(-) for our Table Preview.
      const obj: TimelineGroupedDataStandard = { id: `Group:${group} ${year}-${month}-${day}`, group: group, title: String(total), start_time, end_time }
      //Send to their respective array in result BY DAY.
      resultByDay[currentArrayIndex].push(obj)

      return
    })

    const grandResultDay = this.combineGroupedDataArrays(resultByDay)

    return grandResultDay
  }

  groupByMonth = (data: TimelineDataStandard[], tracks: TimelineTrack[]): TimelineGroupedDataStandard[] => {
    const occurrences = data.reduce((acc: Record<string, number>, curr: TimelineDataStandard) => {
      acc[`${curr.group}-${curr.year}-${curr.month}`] = (acc[`${curr.group}-${curr.year}-${curr.month}`] || 0) + 1;
      return acc;
    }, {})

    const result = Object.entries(occurrences)

    const resultByMonth: TimelineGroupedDataStandard[][] = Array.from({ length: tracks.length }, () => [])

    result.forEach(item => {
      //Since we have dashes we can identify what kind of data is this
      const [group, year, month] = item[0].split("-")
      const total = item[1]

      //We check for the index of the current ID inside the available tracks. Ex: track ID can be 878 but is index 2. 
      const currentArrayIndex: number = tracks.findIndex(track => track.id === Number(group))

      if (currentArrayIndex <= -1) {
        //If there is an item who doesnt belong to any track, we dont group it.
        return
      }

      //First we create Date values to transform it to moment.
      //-1 One because Date starts from 0 to 11, but this month values starts from 1 - 12
      const dateStart = new Date(Number(year), Number(month) - 1, 1)
      const dateEnd = new Date(Number(year), Number(month), 0)

      //We need the Miliseconds for our timeline
      const start_time = dateStart.getTime()
      const end_time = dateEnd.getTime()
      //Group is just for the readibility. Space is to later divide the string and after get each value with split(-) for our Table Preview.
      const obj: TimelineGroupedDataStandard = { id: `Group:${group} ${year}-${month}`, group: group, title: String(total), start_time, end_time }

      //Send to their respective array in result BY MONTH.
      resultByMonth[currentArrayIndex].push(obj)

      return
    })

    const grandResultMonth = this.combineGroupedDataArrays(resultByMonth)

    return grandResultMonth
  }

  groupByYear = (data: TimelineDataStandard[], tracks: TimelineTrack[]): TimelineGroupedDataStandard[] => {
    const occurrences = data.reduce((acc: Record<string, number>, curr: TimelineDataStandard) => {
      acc[`${curr.group}-${curr.year}`] = (acc[`${curr.group}-${curr.year}`] || 0) + 1;
      return acc;
    }, {})

    const result = Object.entries(occurrences)

    const resultByYear: TimelineGroupedDataStandard[][] = Array.from({ length: tracks.length }, () => [])

    result.forEach(item => {
      //Since we have dashes we can identify what kind of data is this
      const [group, year] = item[0].split("-")
      const total = item[1]

      //We check for the index of the current ID inside the available tracks. Ex: track ID can be 878 but is index 2. 
      const currentArrayIndex: number = tracks.findIndex(track => track.id === Number(group))

      if (currentArrayIndex <= -1) {
        //If there is an item who doesnt belong to any track, we dont group it.
        return
      }

      //First we create Date values to transform it to moment.
      const dateStart = new Date(Number(year), 0, 1)
      const dateEnd = new Date(Number(year + 1), 11, 31)

      //We need the Miliseconds for our timeline
      const start_time = dateStart.getTime()
      const end_time = dateEnd.getTime()
      //Group is just for the readibility. Space is to later divide the string and after get each value with split(-) for our Table Preview.
      const obj: TimelineGroupedDataStandard = { id: `Group:${group} ${year}`, group: group, title: String(total), start_time, end_time }

      //Send to their respective array in result BY YEAR.
      resultByYear[currentArrayIndex].push(obj)

      return
    })

    const grandResultYear = this.combineGroupedDataArrays(resultByYear)

    return grandResultYear
  }
}

export const currentTimelineHelpers = new TimelineHelpers();