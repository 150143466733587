import { Button, ButtonGroup, FormControl, FormHelperText, FormLabel, TextareaAutosize, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import useStyles from "./TextParameterCreator.styles";
import AddIcon from '@material-ui/icons/Add';
import { SelectOption } from "../../domain/UI";
import i18n from "i18next";

const sortParameterEntries = (a: SelectOption<string>, b: SelectOption<string>): number => {
  const nameA = a?.label?.toUpperCase(); // Groß-/Kleinschreibung ignorieren
  const nameB = b?.label?.toUpperCase(); // Groß-/Kleinschreibung ignorieren
  if (nameA < nameB)
    return -1;
  if (nameA > nameB)
    return 1;
  return 0; // Namen müssen gleich sein
}

export type TextParameterCreatorProps = {
  label?: string
  parameters: SelectOption<string>[]
  textValue?: string
  onTextValueChange: (textValue: string) => void
  emptyValueAllowed?: boolean
  emptyValueText?: string
}

const TextParameterCreator: FunctionComponent<TextParameterCreatorProps> = ({ parameters: propParameters, textValue = '', onTextValueChange, label, emptyValueAllowed = false, emptyValueText = `${i18n.t('Components:textParameter.valueRequired')}` }) => {
  const classes = useStyles();
  const showEmptyValueText: boolean = !emptyValueAllowed && (textValue == null || textValue === '');

  const [parameters, setParameters] = useState<SelectOption<string>[]>([]);
  const [selectedParameter, setSelectedParameter] = useState<SelectOption<string> | null>(null);

  useEffect(() => {
    const newParameter: SelectOption<string>[] =
      propParameters
        ? propParameters.sort(sortParameterEntries)
        : [];

    setParameters(newParameter);
    if (newParameter?.length)
      setSelectedParameter(newParameter[0]);

  }, [propParameters])

  return (
    <>
      <FormControl component="fieldset" className={classes.formControl}>
        {label != null && <FormLabel component="legend">{label}</FormLabel>}
        <div className={classes.buttonControls}>
          <Autocomplete
            size="small"
            autoComplete
            options={parameters}
            getOptionLabel={option => option.label}
            value={selectedParameter}
            renderInput={params =>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
                className={classes.autocompleteInputButtonGroup}
              >
                <TextField
                  {...params}
                  variant="outlined"
                  label={i18n.t('Components:textParameter.addParam')}
                  className={classes.autocompleteInputTextField}
                />
                <Button
                  id="addNumber"
                  title={i18n.t('Components:textParameter.addParam')}
                  variant="outlined"
                  disabled={!selectedParameter}
                  onClick={() => {
                    if (!selectedParameter)
                      return;

                    onTextValueChange(`${textValue}${(!textValue || textValue === '' ? '' : ' ')}${selectedParameter.value}`)
                  }}
                >
                  <AddIcon />
                </Button>
              </ButtonGroup>

            }
            onChange={(_event: ChangeEvent<unknown>, newVariable: SelectOption<string> | null) => {
              setSelectedParameter(newVariable)
            }}
            className={classes.controlBarAutocomplete}
          />
        </div>
        <TextareaAutosize
          aria-label="textarea"
          value={textValue}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onTextValueChange(event.target.value)}
          className={classes.textArea}
          rowsMin={3}
        />
        {showEmptyValueText && <FormHelperText error>{emptyValueText}</FormHelperText>}
      </FormControl>
    </>
  );
}

export default TextParameterCreator;