import { ApiError } from "../domain/Api";
import { isApiCallError } from "../domain/Api/ApiError";
import { BankParameterStory } from "../domain/StoryFeature";
import { ServiceName } from "../enums/Services";
import { ApiService } from "./ApiService";

export class BankParameterStoryService extends ApiService {

  async crateBankParameterStory(bankVisibilityParameterSetsId: string): Promise<ApiError | null> {
    try {
      const response = await this.Get<string>(`StoryManager/GetStoryForBankVisibilityParameterSet?bankVisibilityParameterSetsId=${bankVisibilityParameterSetsId}`);
      return response && isApiCallError(response) ? response : null;
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async getBankVisibilityParameterSetStory(bankVisibilityParameterSetsId: string): Promise<BankParameterStory | null | ApiError> {
    try {
      const getBankVisibilityParameterSet = await this.GetOData<BankParameterStory>('BankParameterStory', { filter: [{ expression: `BankVisibilityParameterSetsId eq '${bankVisibilityParameterSetsId}'` }], top: 1, count: true }, []);
      if (isApiCallError(getBankVisibilityParameterSet))
        return getBankVisibilityParameterSet;
      return getBankVisibilityParameterSet.length ? getBankVisibilityParameterSet[0] : null;
    }
    catch (error) {
      return error as ApiError;
    }
  }


}

export const currentBankParameterStoryService = new BankParameterStoryService(ServiceName.Api);