import React, { lazy } from 'react';
import * as I from "../domain/App";
import { RoutesConstants } from "../constants";
import { SidebarLinkProps } from '../app/components/SidebarLink';
import { QuestionAnswer as QuestionAnswerIcon } from '@material-ui/icons';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import EuroIcon from '@material-ui/icons/Euro';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CallIcon from '@material-ui/icons/Call';
// import CakeIcon from '@material-ui/icons/Cake';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { AppRoleTypes } from '../enums/Authentication';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import FindInPageIcon from '@material-ui/icons/FindInPage';

const NotFoundPage = lazy(() => import('./NotFound/NotFoundPage'));
const StartPage = lazy(() => import('./StartPage/StartPage'));
const BanksPage = lazy(() => import('./Banks/BanksPage'));
const BankParameterPage = lazy(() => import('./BankParameter/BankParameterPage'));
const DocumentsPage = lazy(() => import('./Documents/DocumentsPage'));
const DocumentEditPage = lazy(() => import('./Documents/DocumentEditPage'));
const ParametrisationFeatureConfigurationPage = lazy(() => import('./ParametrisationFeatureConfiguration/ParametrisationFeatureConfigurationPage'));
const CreditProcessPage = lazy(() => import('./CreditProcess/CreditProcessPage'));
const CreditProcessDemoPage = lazy(() => import('./CreditProcessDemo/CreditProcessDemoPage'));
const KpiCheckPage = lazy(() => import('./KpiCheck/KpiCheckPage'));
const CallInfoPage = lazy(() => import('./CallInfo/CallInfoPage'));
const OfferCalculatorPage = lazy(() => import('./OfferCalculator/OfferCalculatorPage'));
const CrefoPage = lazy(() => import('./Crefo/CrefoInformation/CrefoInformationPage'));
const CrefoInvoicePage = lazy(() => import('./Crefo/CrefoInvoice/CrefoInvoicePage'));
const ApplicationPage = lazy(() => import('./Application/ApplicationPage'));
const NotAllowedRoleErrorPage = lazy(() => import('./NotAllowedRoleError/NotAllowedRoleErrorPage'));
const DocumentParsingPage = lazy(() => import('./DocumentParsing/DocumentParsingPage'));

//const TestPage = lazy(() => import('./Test/TestPage'));
//const DataCenterPage = lazy(() => import('./DataCenter/DataCenterPage'));
const DataCenterPage = lazy(() => import('./Test/TestPage'));

export function getRouterRoutes(): I.ExtendedRouteProps[] {
  const routes = [
    {
      pageTitle: RoutesConstants.pageTitle.startPage,
      path: RoutesConstants.routePath.startPage,
      component: StartPage,
      exact: true,
      usesBasePage: false,
    },
    {
      pageTitle: RoutesConstants.pageTitle.banksPage,
      path: `${RoutesConstants.routePath.banksPage}/:bankId?`,
      component: BanksPage,
      exact: true,
      usesBasePage: false,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect],
    },
    {
      pageTitle: RoutesConstants.pageTitle.bankParameterPage,
      path: RoutesConstants.routePath.bankParameterPage,
      component: BankParameterPage,
      exact: true,
      usesBasePage: false,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect],
    },
    {
      pageTitle: RoutesConstants.pageTitle.documentsPage,
      path: RoutesConstants.routePath.documentsPage,
      component: DocumentsPage,
      exact: true,
      usesBasePage: true,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect, AppRoleTypes.fulfillment],
    },
    {
      pageTitle: RoutesConstants.pageTitle.documentEditPage,
      path: `${RoutesConstants.routePath.documentEditPageWithDocumentID}`,
      component: DocumentEditPage,
      exact: true,
      usesBasePage: false,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect, AppRoleTypes.fulfillment],
    },
    {
      pageTitle: RoutesConstants.pageTitle.parametrisationFeatureConfigurationPage,
      path: RoutesConstants.routePath.parametrisationFeatureConfigurationPage,
      component: ParametrisationFeatureConfigurationPage,
      exact: true,
      usesBasePage: false,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect],
    },
    {
      pageTitle: RoutesConstants.pageTitle.creditProcessPage,
      path: RoutesConstants.routePath.creditProcessPage,
      component: CreditProcessPage,
      exact: true,
      usesBasePage: false,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect],
    },
    {
      pageTitle: RoutesConstants.pageTitle.creditProcessDemoPage,
      path: RoutesConstants.routePath.creditProcessDemoPage,
      component: CreditProcessDemoPage,
      exact: true,
      usesBasePage: false,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect],
    },
    {
      pageTitle: RoutesConstants.pageTitle.kpiCheckPage,
      path: `${RoutesConstants.routePath.kpiCheckPage}/:kpiCheckId?`,
      component: KpiCheckPage,
      exact: true,
      usesBasePage: false,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect],
    },
    {
      pageTitle: RoutesConstants.pageTitle.crefoInformationPage,
      path: RoutesConstants.routePath.crefoInformationPage,
      component: CrefoPage,
      exact: true,
      usesBasePage: true,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect, AppRoleTypes.sales, AppRoleTypes.salesAdmin],
    },
    {
      pageTitle: RoutesConstants.pageTitle.crefoInvoicePage,
      path: RoutesConstants.routePath.crefoInvoicePage,
      component: CrefoInvoicePage,
      exact: true,
      usesBasePage: false,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect, AppRoleTypes.finance],
    },
    {
      pageTitle: RoutesConstants.pageTitle.applicationPage,
      path: `${RoutesConstants.routePath.applicationPageWithInquiryID}`,
      component: ApplicationPage,
      exact: true,
      usesBasePage: false,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect],
    },
    {
      pageTitle: RoutesConstants.pageTitle.callInfoPage,
      path: `${RoutesConstants.routePath.callInfoPage}/:callId?`,
      component: CallInfoPage,
      exact: true,
      usesBasePage: false,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect, AppRoleTypes.customerSuccess],
    },
    {
      pageTitle: RoutesConstants.pageTitle.offerCalculatorPage,
      path: `${RoutesConstants.routePath.offerCalculatorPage}`,
      component: OfferCalculatorPage,
      exact: true,
      usesBasePage: false,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect, AppRoleTypes.customerSuccess],
    },
    /*
    {
      pageTitle: RoutesConstants.pageTitle.testPage,
      path: RoutesConstants.routePath.testPage,
      component: TestPage,
      exact: true,
      usesBasePage: true,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect, AppRoleTypes.sales, AppRoleTypes.productUnit],
    },
    */
    {
      pageTitle: RoutesConstants.pageTitle.dataCenterPage,
      path: RoutesConstants.routePath.dataCenterPage,
      component: DataCenterPage,
      exact: true,
      usesBasePage: false,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect, AppRoleTypes.sales, AppRoleTypes.salesAdmin],
    },
    {
      pageTitle: RoutesConstants.pageTitle.notAllowedRoleErrorPage,
      path: RoutesConstants.routePath.notAllowedRoleErrorPage,
      component: NotAllowedRoleErrorPage,
      exact: true,
      usesBasePage: true,
    },
    {
      pageTitle: RoutesConstants.pageTitle.documentParsingPage,
      component: DocumentParsingPage,
      path: RoutesConstants.routePath.documentParsingInquiryPage,
      usesBasePage: true,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect, AppRoleTypes.fulfillment, AppRoleTypes.salesAdmin, AppRoleTypes.sales],
    },
    {
      pageTitle: RoutesConstants.pageTitle.documentParsingPage,
      component: DocumentParsingPage,
      path: RoutesConstants.routePath.documentParsingPage,
      usesBasePage: true,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect, AppRoleTypes.fulfillment, AppRoleTypes.salesAdmin, AppRoleTypes.sales],
    },
    {
      pageTitle: RoutesConstants.pageTitle.documentParsingPage,
      component: DocumentParsingPage,
      path: RoutesConstants.routePath.documentParsingStartPage,
      usesBasePage: true,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect, AppRoleTypes.fulfillment, AppRoleTypes.salesAdmin, AppRoleTypes.sales],
    },

    // make sure that not found page is the last one, else it will be shown
    {
      pageTitle: RoutesConstants.pageTitle.notFoundPage,
      component: NotFoundPage,
      path: RoutesConstants.routePath.notFoundPage,
      usesBasePage: true,
    },
  ];
  return routes;
}

export function getSidebarStructure(): SidebarLinkProps[] {
  const s: SidebarLinkProps[] = [
    {
      id: 0,
      label: RoutesConstants.pageTitle.startPage,
      icon: <QuestionAnswerIcon />,
      linkIsExact: true,
      link: RoutesConstants.routePath.startPage,
    },
    {
      id: 1,
      label: RoutesConstants.pageTitle.banksPage,
      icon: <AccountBalanceIcon />,
      linkIsExact: false,
      link: RoutesConstants.routePath.banksPage,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect],
    },
    {
      id: 2,
      label: RoutesConstants.pageTitle.documentsPage,
      icon: <DescriptionIcon />,
      linkIsExact: true,
      link: RoutesConstants.routePath.documentsPage,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect, AppRoleTypes.fulfillment],
    },
    {
      id: 3,
      label: RoutesConstants.pageTitle.creditProcessPage,
      icon: <DirectionsRunIcon />,
      linkIsExact: true,
      link: `${RoutesConstants.routePath.creditProcessPage}?inquiryId=35b5a42a-a8aa-4073-b1d4-54fcd7ad8f3d&bankVisibilityParameterSetsId=350`,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect],
    },
    {
      id: 4,
      label: RoutesConstants.pageTitle.crefoPage,
      icon: <EuroIcon />,
      allowedAppRoles: [AppRoleTypes.sales, AppRoleTypes.salesAdmin, AppRoleTypes.admin, AppRoleTypes.finance],
      children: [
        {
          id: 5,
          label: RoutesConstants.pageTitle.crefoInformationPage,
          icon: <InfoOutlinedIcon />,
          linkIsExact: true,
          link: RoutesConstants.routePath.crefoInformationPage,
          allowedAppRoles: [AppRoleTypes.sales, AppRoleTypes.salesAdmin, AppRoleTypes.admin],
        },
        {
          id: 6,
          label: RoutesConstants.pageTitle.crefoInvoicePage,
          icon: <CreditCardIcon />,
          linkIsExact: true,
          link: RoutesConstants.routePath.crefoInvoicePage,
          allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.finance],
        }
      ],
    },
    {
      id: 7,
      label: RoutesConstants.pageTitle.offerCalculatorPage,
      icon: <AssignmentIcon />,
      linkIsExact: true,
      link: RoutesConstants.routePath.offerCalculatorPage,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect, AppRoleTypes.customerSuccess],
    },
    {
      id: 8,
      label: RoutesConstants.pageTitle.callInfoPage,
      icon: <CallIcon />,
      linkIsExact: true,
      link: RoutesConstants.routePath.callInfoPage,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.customerSuccess]
    },
    {
      id: 9,
      label: RoutesConstants.pageTitle.dataCenterPage,
      icon: <InsertDriveFileIcon />,
      linkIsExact: true,
      link: RoutesConstants.routePath.dataCenterPage,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect, AppRoleTypes.salesAdmin, AppRoleTypes.sales],
    },
    {
      id: 10,
      label: RoutesConstants.pageTitle.documentParsingPage,
      icon: <FindInPageIcon />,
      linkIsExact: true,
      link: RoutesConstants.routePath.documentParsingStartPage,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect, AppRoleTypes.fulfillment, AppRoleTypes.salesAdmin, AppRoleTypes.sales],
    }
    /*
    {
      id: 999,
      label: RoutesConstants.pageTitle.testPage,
      icon: <CakeIcon />,
      linkIsExact: true,
      link: RoutesConstants.routePath.testPage,
      allowedAppRoles: [AppRoleTypes.admin, AppRoleTypes.connect, AppRoleTypes.sales, AppRoleTypes.productUnit]
    }
    */
  ];
  return s;
}
