import { Bank, BankVisibilityParameterSets } from "../../domain/Bank";
import { ReduxEnums } from "../../enums";
import { ActionTypes } from "../actions";

interface InitialStateType {
  selectedBank?: Bank
  selectedParameter?: BankVisibilityParameterSets
}

const initialState: InitialStateType = {
  selectedBank: undefined,
  selectedParameter: undefined
};

export default function banksPage(
  state = initialState,
  action: ActionTypes
): InitialStateType {
  switch (action.type) {
    case ReduxEnums.BanksPageActionsEnum.setSelectedBank:
      return {
        ...state,
        selectedBank: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    case ReduxEnums.BanksPageActionsEnum.setSelectedParameter:
      return {
        ...state,
        selectedParameter: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    default:
      return state;
  }
}
