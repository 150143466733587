import { AccountInfo, AuthenticationResult, EventType } from "@azure/msal-browser";
import { ActionTypes } from "../actions";
import { AppRoleTypes } from "../../enums/Authentication";
// import { Account } from "msal";
// import { Providers, ProviderState } from "@microsoft/mgt-element";

interface InitialStateType {
  // initializing: boolean
  // initialized: boolean
  idToken: string | null
  accessToken: string | null
  // state: AuthenticationState
  account: AccountInfo | null
  userAppRoleTypes: AppRoleTypes[] | null
}

const initialState: InitialStateType = {
  // initializing: false,
  // initialized: false,
  idToken: null,
  accessToken: null,
  // state: AuthenticationState.Unauthenticated,
  account: null,
  userAppRoleTypes: null
};

// events explained on https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md
export default function authentication(
  state = initialState,
  action: ActionTypes
): InitialStateType {
  switch (action.type) {
    case EventType.LOGIN_SUCCESS: {
      if (!action.payload)
        return state;

      const payload = <AuthenticationResult>action.payload;
      return {
        ...state,
        idToken: payload.idToken,
        accessToken: payload.accessToken,
        account: payload.account
      }
    }
    case EventType.LOGOUT_END: {
      return {
        ...state,
        idToken: null,
        accessToken: null,
        account: null
      }
    }
    case EventType.ACQUIRE_TOKEN_FAILURE: {
      return {
        ...state,
        idToken: null,
        accessToken: null,
      }
    }
    case EventType.ACQUIRE_TOKEN_SUCCESS: {
      if (!action.payload)
        return state;

      const payload = <AuthenticationResult>action.payload;

      return {
        ...state,
        idToken: payload.idToken,
        accessToken: payload.accessToken,
        account: payload.account
      }
    }
    default:
      return state;
  }
}

// export default function authentication(
//   state = initialState,
//   action: ActionTypes
// ): InitialStateType {
//   switch (action.type) {
//     case AuthenticationActions.Initializing:
//       return {
//         ...state,
//         initializing: true,
//         initialized: false,
//       };
//     case AuthenticationActions.Initialized:
//       return {
//         ...state,
//         initializing: false,
//         initialized: true,
//       };
//     case AuthenticationActions.AcquiredIdTokenSuccess:
//       return {
//         ...state,
//         idToken: action.payload,
//       };
//     case AuthenticationActions.AcquiredAccessTokenSuccess:
//       return {
//         ...state,
//         accessToken: action.payload,
//       };
//     case AuthenticationActions.AcquiredAccessTokenError:
//       return {
//         ...state,
//         accessToken: null,
//       };
//     case AuthenticationActions.LoginSuccess:
//       const callLoginPrivder = async () => {
//         if (Providers && Providers.globalProvider && Providers.globalProvider.login)
//           await Providers.globalProvider.login();
//       }
//       // callLoginPrivder();
//       // Providers.globalProvider.setState(ProviderState.SignedIn)
//       return {
//         ...state,
//         account: action.payload.account,
//       };
//     case AuthenticationActions.LoginError:
//     case AuthenticationActions.AcquiredIdTokenError:
//     case AuthenticationActions.LogoutSuccess:
//       return {
//         ...state,
//         idToken: null,
//         accessToken: null,
//         account: null,
//       };
//     case AuthenticationActions.AuthenticatedStateChanged:
//       return {
//         ...state,
//         state: action.payload,
//       };
//     default:
//       return state;
//   }
// }
