import { ApiError } from "../domain/Api";
import { isApiCallError } from "../domain/Api/ApiError";
import { Feature, Output } from "../domain/StoryFeature";
import { ServiceName } from "../enums/Services";
import { ApiService } from "./ApiService";

export class FeatureApiService extends ApiService {

  async getFeatures(): Promise<Feature[] | ApiError> {
    try {
      return await this.GetOData<Feature>('Feature', {}, []);
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async getFeature(featureId: number): Promise<Feature | null | ApiError> {
    try {
      const getFatureResponse = await this.GetOData<Feature>('Feature', { filter: [{ expression: `Id eq ${featureId}` }], top: 1 }, []);
      if (isApiCallError(getFatureResponse))
        return getFatureResponse;

      return getFatureResponse.length ? getFatureResponse[0] : null;
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async getOutputsOfFeature(featureId: number): Promise<Output[] | ApiError> {
    try {
      return await this.GetOData<Output>('Output', { filter: [{ expression: `FeatureOutput/any(output: output/FeatureId eq ${featureId})` }] }, []);
    }
    catch (error) {
      return error as ApiError;
    }
  }
}

export const currentFeatureApiService = new FeatureApiService(ServiceName.Api);