import { ApiError } from "../../domain/Api";
import { StoryFeatureConnection } from "../../domain/StoryFeature";
import { ServiceName } from "../../enums/Services";
import { ApiService } from "../ApiService";

export class StoryFeatureConnectionApiService extends ApiService {

  async getFeatures(): Promise<StoryFeatureConnection[] | ApiError> {
    try {
      return await this.GetOData<StoryFeatureConnection>('StoryFeatureConnection', {}, []);
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async getStoryFeatureConnectionsByTriggerStoryFeatureId(storyFeatureId: number): Promise<StoryFeatureConnection[] | ApiError> {
    try {
      return await this.GetOData<StoryFeatureConnection>('StoryFeatureConnection', { filter: [{ expression: `TriggerStoryFeatureId eq ${storyFeatureId}` }] }, []);
    }
    catch (error) {
      return error as ApiError;
    }
  }
}

export const currentStoryFeatureConnectionApiService = new StoryFeatureConnectionApiService(ServiceName.Api);