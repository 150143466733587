import { DiagramSchema } from 'beautiful-react-diagrams/@types/DiagramSchema'
import { Bank, BankVisibilityParameterSets } from '../../../domain/Bank'
import { FeatureNodeMetadata } from '../../../domain/BankParameterPage'
import { ParametrisationRule } from '../../../domain/Parametrisation'
import { Feature, Story, StoryFeature } from '../../../domain/StoryFeature'
import StoryFeatureMetaData from '../../../domain/StoryFeature/StoryFeatureMetaData'
import { ReduxEnums } from '../../../enums'
import { BankParameterPageActionTypes } from './types'

export const setSelectedBank = (bank: Bank | undefined): BankParameterPageActionTypes => ({
  type: ReduxEnums.BankParameterPageActionsEnum.setSelectedBank,
  payload: bank
})

export const setSelectedParameter = (parameter: BankVisibilityParameterSets | undefined): BankParameterPageActionTypes => ({
  type: ReduxEnums.BankParameterPageActionsEnum.setSelectedParameter,
  payload: parameter
})

export const setParameterDiagramSchema = (diagramSchema: DiagramSchema<unknown> | null): BankParameterPageActionTypes => ({
  type: ReduxEnums.BankParameterPageActionsEnum.setParameterDiagramSchema,
  payload: diagramSchema
})

export const setParameterStoryFeatures = (storyFeatures: StoryFeature[] | null): BankParameterPageActionTypes => ({
  type: ReduxEnums.BankParameterPageActionsEnum.setParameterStoryFeatures,
  payload: storyFeatures
})

export const setSelectStoryFeaturesFilter = (selectStoryFeaturesFilter: string | null): BankParameterPageActionTypes => ({
  type: ReduxEnums.BankParameterPageActionsEnum.setSelectStoryFeaturesFilter,
  payload: selectStoryFeaturesFilter
})

export const storeSelectFeatures = (selectFeatures: Feature[]): BankParameterPageActionTypes => ({
  type: ReduxEnums.BankParameterPageActionsEnum.storeSelectFeatures,
  payload: selectFeatures
})

export const setSelectedAddFeature = (selectedAddFeature: Feature | null): BankParameterPageActionTypes => ({
  type: ReduxEnums.BankParameterPageActionsEnum.setSelectedAddFeature,
  payload: selectedAddFeature
})

export const setStory = (story: Story | null): BankParameterPageActionTypes => ({
  type: ReduxEnums.BankParameterPageActionsEnum.setStory,
  payload: story
})

export const setFeatureNodeToEdit = (featureNodeMetadata: FeatureNodeMetadata | null): BankParameterPageActionTypes => ({
  type: ReduxEnums.BankParameterPageActionsEnum.setFeatureNodeToEdit,
  payload: featureNodeMetadata
})

export const setStoryFeatureMetaDatas = (storyFeatureMetaDatas: StoryFeatureMetaData[] | null): BankParameterPageActionTypes => ({
  type: ReduxEnums.BankParameterPageActionsEnum.setStoryFeatureMetaDatas,
  payload: storyFeatureMetaDatas
})

export const setParametrisationRules = (parametrisationRules: ParametrisationRule[] | null): BankParameterPageActionTypes => ({
  type: ReduxEnums.BankParameterPageActionsEnum.setParametrisationRules,
  payload: parametrisationRules
})

