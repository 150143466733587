import { DiagramSchema } from "beautiful-react-diagrams/@types/DiagramSchema";
import { Bank, BankVisibilityParameterSets } from "../../domain/Bank";
import { FeatureNodeMetadata } from "../../domain/BankParameterPage";
import { ParametrisationRule } from "../../domain/Parametrisation";
import { Feature, Story, StoryFeature } from "../../domain/StoryFeature";
import StoryFeatureMetaData from "../../domain/StoryFeature/StoryFeatureMetaData";
import { ReduxEnums } from "../../enums";
import { ActionTypes } from "../actions";

interface InitialStateType {
  selectedBank?: Bank
  selectedParameter?: BankVisibilityParameterSets
  parameterDiagramSchema: DiagramSchema<unknown> | null
  parameterStoryFeatures: StoryFeature[] | null
  selectStoryFeaturesFilter: string | null
  selectFeatures: Feature[]
  selectedAddFeature: Feature | null
  story: Story | null
  featureNodeToEdit: FeatureNodeMetadata | null
  storyFeatureMetaDatas: StoryFeatureMetaData[] | null
  parametrisationRules: ParametrisationRule[] | null
}

const initialState: InitialStateType = {
  selectedBank: undefined,
  selectedParameter: undefined,
  parameterDiagramSchema: null,
  parameterStoryFeatures: null,
  selectStoryFeaturesFilter: null,
  selectFeatures: [],
  selectedAddFeature: null,
  story: null,
  featureNodeToEdit: null,
  storyFeatureMetaDatas: null,
  parametrisationRules: null
};

export default function bankParameterPage(
  state = initialState,
  action: ActionTypes
): InitialStateType {
  switch (action.type) {
    case ReduxEnums.BankParameterPageActionsEnum.setSelectedBank:
      return {
        ...state,
        selectedBank: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    case ReduxEnums.BankParameterPageActionsEnum.setSelectedParameter:
      return {
        ...state,
        selectedParameter: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    case ReduxEnums.BankParameterPageActionsEnum.setParameterDiagramSchema:
      return {
        ...state,
        parameterDiagramSchema: action.payload ? Object.assign({}, action.payload) : null,
      };
    case ReduxEnums.BankParameterPageActionsEnum.setParameterStoryFeatures:
      return {
        ...state,
        parameterStoryFeatures: action.payload ? new Array<StoryFeature>().concat(action.payload) : null,
      };
    case ReduxEnums.BankParameterPageActionsEnum.setSelectStoryFeaturesFilter:
      return {
        ...state,
        selectStoryFeaturesFilter: action.payload
      };
    case ReduxEnums.BankParameterPageActionsEnum.storeSelectFeatures:
      return {
        ...state,
        selectFeatures: new Array<Feature>().concat(action.payload)
      };
    case ReduxEnums.BankParameterPageActionsEnum.setSelectedAddFeature:
      return {
        ...state,
        selectedAddFeature: action.payload ? Object.assign({}, action.payload) : null
      };
    case ReduxEnums.BankParameterPageActionsEnum.setStory:
      return {
        ...state,
        story: action.payload ? Object.assign({}, action.payload) : null
      };
    case ReduxEnums.BankParameterPageActionsEnum.setFeatureNodeToEdit:
      return {
        ...state,
        featureNodeToEdit: action.payload ? Object.assign({}, action.payload) : null
      };
    case ReduxEnums.BankParameterPageActionsEnum.setStoryFeatureMetaDatas:
      return {
        ...state,
        storyFeatureMetaDatas: action.payload ? new Array<StoryFeatureMetaData>().concat(action.payload) : null
      };
    case ReduxEnums.BankParameterPageActionsEnum.setParametrisationRules:
      return {
        ...state,
        parametrisationRules: action.payload ? new Array<ParametrisationRule>().concat(action.payload) : null
      };
    default:
      return state;
  }
}
