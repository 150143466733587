export enum ComparementStatus {
  pending,
  success,
  failed,
  apiCallError,
  fileNotOpened,
  emptyFile,
  emptySheet,
  apiValueError,
  missingAssignmentNumber,
  missingCrefoNumber,
  missingInformationAbout,
  missingProduct,
  missingProvidedDate,
  invalidProvidedDate,
  dateBelowMinimumValue
}

export enum CrefoInvoiceStatus {
  success = "success",
  invalidDate = "invalidDate",
  crefoRechnungItemsEmpty = "crefoRechnungItemsEmpty",
  dateBelowMinimumValue = "dateBelowMinimumValue"
}

export function convertComparementStatusEnumToTranslationKey(status: ComparementStatus): string {
  switch (status) {
    case ComparementStatus.pending:
      return 'crefoInvoice:resultTable.pending';
    case ComparementStatus.success:
      return 'crefoInvoice:resultTable.success';
    case ComparementStatus.failed:
      return 'crefoInvoice:resultTable.failed';
    case ComparementStatus.apiCallError:
      return 'crefoInvoice:errorList.apiCallError';
    case ComparementStatus.emptyFile:
      return 'crefoInvoice:errorList.emptyFile';
    case ComparementStatus.emptySheet:
      return 'crefoInvoice:errorList.emptySheet';
    case ComparementStatus.fileNotOpened:
      return 'crefoInvoice:errorList.fileNotOpened';
    case ComparementStatus.missingAssignmentNumber:
      return 'crefoInvoice:errorList.missingAssignmentNumber';
    case ComparementStatus.missingCrefoNumber:
      return 'crefoInvoice:errorList.missingCrefoNumber';
    case ComparementStatus.missingInformationAbout:
      return 'crefoInvoice:errorList.missingInformationAbout';
    case ComparementStatus.missingProduct:
      return 'crefoInvoice:errorList.missingProduct';
    case ComparementStatus.missingProvidedDate:
      return 'crefoInvoice:erromissingInformationAboutrList.missingProvidedDate';
    case ComparementStatus.invalidProvidedDate:
      return 'crefoInvoice:errorList.invalidProvidedDate';
    case ComparementStatus.dateBelowMinimumValue:
      return 'crefoInvoice:errorList.dateBelowMinimumValue';
    default:
      return 'crefoInvoice:errorList.unhandledError';
  }
}

export function convertCrefoInvoiceStatusEnumToTranslationKey(status: CrefoInvoiceStatus): string {
  switch (status) {
    case CrefoInvoiceStatus.success:
      return 'crefoInvoice:resultTable.success';
    case CrefoInvoiceStatus.invalidDate:
      return 'crefoInvoice:errorList.invalidProvidedDate';
    case CrefoInvoiceStatus.crefoRechnungItemsEmpty:
      return 'crefoInvoice:errorList.crefoRechnungItemsEmpty';
    case CrefoInvoiceStatus.dateBelowMinimumValue:
      return 'crefoInvoice:errorList.dateBelowMinimumValue';
    default:
      return 'crefoInvoice:errorList.unhandledError';
  }
}

export enum CrefoTrafficLight {
  green = "green",
  yellow = "yellow",
  red = "red",
  unknown = "unknown"
}

export enum CrefoInformationError {
  noCrefoInformationFound,
  searchCrefoInformationApiCallError,
  noCrefoInformationSearched,
  noDataToDownload,
  noIdAvailable,
  noEntryFound,
  getFileDataApiCallError
}

export function convertCrefoInformationErrorsEnumToTranslationKey(errorKey: CrefoInformationError): string {
  switch (errorKey) {
    case CrefoInformationError.noCrefoInformationFound:
      return 'crefoInformation:errorKey.noCrefoInformationFound';
    case CrefoInformationError.searchCrefoInformationApiCallError:
      return 'crefoInformation:errorKey.searchCrefoInformationApiCallError';
    case CrefoInformationError.noCrefoInformationSearched:
      return 'crefoInformation:errorKey.noCrefoInformationSearched';
    case CrefoInformationError.noDataToDownload:
      return 'crefoInformation:errorKey.noDataToDownload';
    case CrefoInformationError.noIdAvailable:
      return 'crefoInformation:errorKey.noIdAvailable';
    case CrefoInformationError.noEntryFound:
      return 'crefoInformation:errorKey.noEntryFound';
    case CrefoInformationError.getFileDataApiCallError:
      return 'crefoInformation:errorKey.getFileDataApiCallError';
  }
}