import { CallInfoPageActionTypes } from "./types";
import { ReduxEnums } from "../../../enums";
import { CallKeyFacts } from "../../../domain/CallInfo/CallKeyFacts";
import { Contact } from "../../../domain/CallInfo/Contact";
import { Lead } from "../../../domain/CallInfo/Lead";
import { CallProtocol } from "../../../domain/CallInfo/CallProtocol";

export const setCallKeyFacts = (
  callKeyFacts?: CallKeyFacts
): CallInfoPageActionTypes => ({
  type: ReduxEnums.CallInfoPageActionsEnum.setCallKeyFacts,
  payload: callKeyFacts,
});

export const setContacts = (
  contacts?: Contact[]
): CallInfoPageActionTypes => ({
  type: ReduxEnums.CallInfoPageActionsEnum.setContacts,
  payload: contacts,
});

export const setLeads = (leads?: Lead[]): CallInfoPageActionTypes => ({
  type: ReduxEnums.CallInfoPageActionsEnum.setLeads,
  payload: leads,
});

export const setCallProtocols = (
  callProtocols?: CallProtocol[]
): CallInfoPageActionTypes => ({
  type: ReduxEnums.CallInfoPageActionsEnum.setCallProtocols,
  payload: callProtocols,
});
