import { ServiceName } from "../enums/Services";

export function getServiceBaseUrl(service: ServiceName): string {
  switch (service) {
    case ServiceName.Api:
      return window.REACT_APP_SERVICE_API;
    case ServiceName.DocumentCatalogueApi:
      return window.REACT_APP_SERVICE_DOCUMENTCATALOGUE_API;
    case ServiceName.DocumentCatalogueOData:
      return window.REACT_APP_SERVICE_DOCUMENTCATALOGUE_ODATA;
    case ServiceName.GraphApi:
      return window.REACT_APP_SERVICE_GRAPH_API;
    case ServiceName.TimelineApi:
      return window.REACT_APP_SERVICE_TIMELINE_API;
    default:
      return "";
  }
}