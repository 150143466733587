import { Bank, BankVisibilityParameterSets } from "../../domain/Bank";
import { StoryFeatureResult, StoryResult } from "../../domain/CreditProcess";
import { Inquiry } from "../../domain/Marketplace";
import { StoryFeature } from "../../domain/StoryFeature";
import { ReduxEnums } from "../../enums";
import { ActionTypes } from "../actions";

interface InitialStateType {
  selectedBank?: Bank
  selectedParameter?: BankVisibilityParameterSets
  latestStoryResult?: StoryResult
  latestStoryFeatureResults: StoryFeatureResult[]
  storyFeatures?: StoryFeature[]
  selectedInquiry?: Inquiry
}

const initialState: InitialStateType = {
  selectedBank: undefined,
  selectedParameter: undefined,
  selectedInquiry: undefined,
  latestStoryResult: undefined,
  latestStoryFeatureResults: [],
  storyFeatures: undefined
};

export default function creditProcessPage(
  state = initialState,
  action: ActionTypes
): InitialStateType {
  switch (action.type) {
    case ReduxEnums.CreditProcessPageActionsEnum.setSelectedBank:
      return {
        ...state,
        selectedBank: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    case ReduxEnums.CreditProcessPageActionsEnum.setSelectedParameter:
      return {
        ...state,
        selectedParameter: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    case ReduxEnums.CreditProcessPageActionsEnum.setLatestStoryResult:
      return {
        ...state,
        latestStoryResult: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    case ReduxEnums.CreditProcessPageActionsEnum.setLatestStoryFeatureResults:
      return {
        ...state,
        latestStoryFeatureResults: new Array<StoryFeatureResult>().concat(action.payload),
      };
    case ReduxEnums.CreditProcessPageActionsEnum.updateLatestStoryFeatureResult:
    {
      const updatedLatestStoryFeatureResults = new Array<StoryFeatureResult>().concat(state.latestStoryFeatureResults);
      const storyFeatureIndex = updatedLatestStoryFeatureResults.findIndex(storyFeatureResult => storyFeatureResult.storyFeatureId == action.payload.storyFeatureId);
      if (storyFeatureIndex < 0)
        updatedLatestStoryFeatureResults.push(Object.assign({}, action.payload));
      else
        updatedLatestStoryFeatureResults[storyFeatureIndex] = Object.assign({}, action.payload);

      return {
        ...state,
        latestStoryFeatureResults: updatedLatestStoryFeatureResults,
      };
    }
    case ReduxEnums.CreditProcessPageActionsEnum.setStoryFeatures:
      return {
        ...state,
        storyFeatures: action.payload ? new Array<StoryFeature>().concat(action.payload) : undefined,
      };
    case ReduxEnums.CreditProcessPageActionsEnum.setSelectedInquiry:
      return {
        ...state,
        selectedInquiry: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    default:
      return state;
  }
}
