import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { Provider } from "react-redux";
import { store } from "./store/reduxStore";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import '../src/i18n/i18n';
import { ConfigurationAppRoles } from './domain/Service';

declare global {
  interface Window {
    REACT_APP_SERVICE_API: string
    REACT_APP_SERVICE_DOCUMENTCATALOGUE_API: string
    REACT_APP_SERVICE_DOCUMENTCATALOGUE_ODATA: string
    REACT_APP_SERVICE_GRAPH_API: string
    REACT_APP_SERVICE_TIMELINE_API: string
    REACT_APP_MS_CLIENTID: string
    REACT_APP_MS_TENANTID: string
    REACT_APP_ENVIRONMENT: string
    REACT_APP_LINK_SALES: string
    REACT_APP_LINK_PARTNERSITE: string
    REACT_APP_CONFIGURATION_AZURE_APPROLES_RESOURCE_STRING: string
    REACT_APP_CONFIGURATION_AZURE_APPROLES_STRING: string
    REACT_APP_CONFIGURATION_AZURE_APPROLES: ConfigurationAppRoles
  }
}

window.REACT_APP_SERVICE_API = window.REACT_APP_SERVICE_API || "";
window.REACT_APP_SERVICE_DOCUMENTCATALOGUE_API = window.REACT_APP_SERVICE_DOCUMENTCATALOGUE_API || "";
window.REACT_APP_SERVICE_DOCUMENTCATALOGUE_ODATA = window.REACT_APP_SERVICE_DOCUMENTCATALOGUE_ODATA || "";
window.REACT_APP_SERVICE_GRAPH_API = window.REACT_APP_SERVICE_GRAPH_API || "";
window.REACT_APP_SERVICE_TIMELINE_API = window.REACT_APP_SERVICE_TIMELINE_API || "";
window.REACT_APP_MS_CLIENTID = window.REACT_APP_MS_CLIENTID || "";
window.REACT_APP_MS_TENANTID = window.REACT_APP_MS_TENANTID || "";
window.REACT_APP_LINK_SALES = window.REACT_APP_LINK_SALES || "";
window.REACT_APP_LINK_PARTNERSITE = window.REACT_APP_LINK_PARTNERSITE || "";
window.REACT_APP_ENVIRONMENT = window.REACT_APP_ENVIRONMENT || "";
window.REACT_APP_CONFIGURATION_AZURE_APPROLES_RESOURCE_STRING = window.REACT_APP_CONFIGURATION_AZURE_APPROLES_RESOURCE_STRING || "";

if (window.REACT_APP_CONFIGURATION_AZURE_APPROLES_STRING) {
  window.REACT_APP_CONFIGURATION_AZURE_APPROLES = JSON.parse(window.REACT_APP_CONFIGURATION_AZURE_APPROLES_STRING);
}
else
  console.error("Configuration AppRoles are not set.");

Sentry.init({
  dsn: "https://382ea08483f44c508e6771680548383f@o1047154.ingest.sentry.io/4504888115265536",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: window.REACT_APP_ENVIRONMENT,
});

ReactDOM.render(
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <Router>
        <CssBaseline />
        <App />
      </Router>
    </DndProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
