import React, { useState, FunctionComponent, useEffect } from 'react';
import { useTheme } from "@material-ui/styles";
import { Drawer, IconButton, Theme } from '@material-ui/core';
import classNames from "classnames";
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import * as H from 'history';
import useStyles from './Sidebar.styles';
import { SidebarInfos } from './SidebarInfosForCurrentPage';

export type SidebarProps = {
  open: boolean
  handleDrawerClose: () => void
  location?: H.Location<H.LocationState>
};

const Sidebar: FunctionComponent<SidebarProps> = ({ open, handleDrawerClose, location }) => {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const hookLocation = useLocation();
  if (!location)
    location = hookLocation;

  const [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.selfservice_drawer, {
        [classes.selfservice_drawerOpen]: open,
        [classes.selfservice_drawerClose]: !open,
      })}
      classes={{
        paper: classNames({
          [classes.selfservice_drawerOpen]: open,
          [classes.selfservice_drawerClose]: !open,
        }),
      }}
      open={open}
    >
      <div className={classes.selfservice_toolbar} />
      <div className={classes.selfservice_mobileBackButton}>
        <IconButton onClick={handleDrawerClose}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.selfservice_headerIcon, classes.selfservice_headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <SidebarInfos
        location={location}
      />
      <div className={classes.selfservice_footerIcon}>
      </div>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange(): void {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}


export default Sidebar;