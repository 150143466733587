import { ApiError } from "../../domain/Api";
import { isApiCallError } from "../../domain/Api/ApiError";
import { StoryFeature } from "../../domain/StoryFeature";
import { ApiService } from "../ApiService";

export class StoryFeatureApi extends ApiService {

  async getStoryFeatures(): Promise<StoryFeature[] | ApiError> {
    return await this.GetOData<StoryFeature>('StoryFeature', {}, []);
  }

  async getStoryFeatureWithFeature(storyFeatureId: number): Promise<StoryFeature | undefined | ApiError> {
    try {
      const storyFeatureResponse: StoryFeature[] | ApiError = await this.GetOData<StoryFeature>('StoryFeature',
        {
          filter: [{ expression: `Id eq ${storyFeatureId}` }],
          top: 1,
          expand: ["Feature"]
        }, []);

      if (isApiCallError(storyFeatureResponse))
        return storyFeatureResponse as ApiError;

      if (!storyFeatureResponse || !storyFeatureResponse.length)
        return;

      return storyFeatureResponse[0];
    }
    catch (error) {
      return error as ApiError;
    }
  }
}

export const currentStoryFeatureApiService = new StoryFeatureApi();