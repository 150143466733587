import React, { FunctionComponent, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import { isApiCallError } from "../../domain/Api/ApiError";
import { Bank, BankVisibilityParameterSets } from "../../domain/Bank";
import { currentMarketplaceApiService } from "../../services/MarketplaceApi";
import { setSelectedBank, setSelectedParameter } from "../../store/actions/bankParameterPage/actions";
import { toggleIsPageLoading } from "../../store/actions/uiActions/actions";
import { RootState } from "../../store/reduxStore";
import useStyles from './BankParameterPage.styles'
import { loadStoryForBankParameter, resetStoryForBankParameter } from "./BankParameterPageHelperFunctions";
import { BankParameterStory } from "./components";

type BankParameterPageUrlParams = {
  bankId: string | undefined
  productId: string | undefined
}

const BankParameterPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation('BankParameter');

  const isPageLoading = useSelector((state: RootState) => state.ui.isPageLoading);
  const setIsPageLoading = (isLoading: boolean) => {
    dispatch(toggleIsPageLoading(isLoading));
  }

  const { bankId: urlBankId, productId: urlProductId } = useParams<BankParameterPageUrlParams>();
  const selectedBank: Bank | undefined = useSelector((state: RootState) => state.bankParameterPage.selectedBank);
  const selectBank = (bank: Bank | undefined) => {
    dispatch(setSelectedBank(bank));
  }

  const selectedParameter: BankVisibilityParameterSets | undefined = useSelector((state: RootState) => state.bankParameterPage.selectedParameter);
  const selectBankParameter = (parameter: BankVisibilityParameterSets | undefined) => {
    dispatch(setSelectedParameter(parameter));
  }


  useEffect(
    () => {
      const loadData = async () => {
        setIsPageLoading(true);
        let searchBank: Bank | undefined;
        let searchParameter: BankVisibilityParameterSets | undefined;
        if (urlProductId) {
          const loadResponse = await currentMarketplaceApiService.getBankAndBankVisibilityParameter(Number(urlProductId));
          if (loadResponse && !isApiCallError(loadResponse)) {
            searchBank = loadResponse.bank;
            searchParameter = loadResponse.bankVisibilityParameter
          }
        }
        selectBank(searchBank);
        selectBankParameter(searchParameter);

        if (searchParameter) {
          loadStoryForBankParameter(searchParameter, dispatch);
        }
        else
          setIsPageLoading(false);
      }

      loadData();

      return () => {
        resetStoryForBankParameter(dispatch);
      }
    },
    [urlBankId, urlProductId]
  )

  return (
    <div className={classes.root}>
      {selectedParameter
        ? <>
          <Helmet>
            <title>{
              `
                            ${selectedBank ? `${selectedBank.attributes["display-name"].trim()} ` : ''}
                            ${selectedParameter.attributes.title}
                            `
            }</title>
          </Helmet>
          <div className={classes.titleContainer}>
            <PageTitle title={selectedParameter.attributes.title} />
          </div>
          {!isPageLoading && <BankParameterStory />}
        </>
        : <p>{t('BankParameter:loading')}</p>
      }
    </div>

  );
};

export default BankParameterPage;

