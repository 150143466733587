import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    textAlignRight: {
      "& .MuiInputBase-root": {
        "& input": {
          textAlign: "right"
        }
      }
    },
    textAlignLeft: {
      "& .MuiInputBase-root": {
        "& input": {
          textAlign: "left"
        }
      }
    },
    upDownButtonsRoot: {
      display: "flex",
      flexDirection: "row",
    },
    upDownButtons: {
      marginLeft: "1px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "space-around",
    },
    upDownButton: {
      maxHeight: "20px",
      maxWidth: "20px",
      minWidth: "20px",
    },
  }));

export default useStyles;