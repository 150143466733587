export enum DatacenterError {
  OneDatacenterDocumentsReadError,
  DatacentersReadError,
  DownloadDocumentDocumentsReadError,
  DownloadDocumentNoDocumentsFound,
  PreviewDocumentReadError,
  PreviewDocumentNoContent,
  DocumentDeleteError,
  DocumentUploadError,
  DocumentSelectError
}

export enum DatacenterPageModes {
  /**
   * Mode for showing all existing datacenter
   */
  DatacentersDashboard = "DatacentersDashboard",
  /**
   * View for only one datacenter
   */
  DatacenterView = "DatacenterView"
}

export enum DatacenterActiveFilter {
  all = "all",
  active = "active",
  inactive = "inactive"
}