import { psdStoryFeatures } from "../../demoData/creditProcessPage/storyFeatures";
import { Bank, BankVisibilityParameterSets } from "../../domain/Bank";
import { StoryFeatureResult, StoryResult } from "../../domain/CreditProcess";
import { KpiCheck, KpiSection } from "../../domain/Kpi";
import { Tender } from "../../domain/Marketplace";
import { StoryFeature } from "../../domain/StoryFeature";
import { ReduxEnums } from "../../enums";
import { ActionTypes } from "../actions";

interface InitialStateType {
  selectedBank?: Bank
  selectedParameter?: BankVisibilityParameterSets
  latestStoryResult?: StoryResult
  latestStoryFeatureResults: StoryFeatureResult[]
  storyFeatures: StoryFeature[]
  selectedTender?: Tender
  kpiCheck?: KpiCheck
  kpiSections: KpiSection[]
  kpiValueCalculationCounter: number
  completeKpisValidation: string
}

const initialState: InitialStateType = {
  selectedBank: undefined,
  selectedParameter: undefined,
  selectedTender: undefined,
  latestStoryResult: undefined,
  latestStoryFeatureResults: [],
  storyFeatures: psdStoryFeatures,
  kpiCheck: undefined,
  kpiSections: [],
  kpiValueCalculationCounter: 0,
  completeKpisValidation: ''
};

export default function kpiCheckPage(
  state = initialState,
  action: ActionTypes
): InitialStateType {
  switch (action.type) {
    case ReduxEnums.KpiCheckPageActionsEnum.setSelectedBank:
      return {
        ...state,
        selectedBank: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    case ReduxEnums.KpiCheckPageActionsEnum.setSelectedParameter:
      return {
        ...state,
        selectedParameter: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    case ReduxEnums.KpiCheckPageActionsEnum.setSelectedTender:
      return {
        ...state,
        selectedTender: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    case ReduxEnums.KpiCheckPageActionsEnum.setKpiCheck:
      return {
        ...state,
        kpiCheck: action.payload ? Object.assign({}, action.payload) : undefined,
      };
    case ReduxEnums.KpiCheckPageActionsEnum.setKpiSections:
      return {
        ...state,
        kpiSections: new Array<KpiSection>().concat(action.payload),
      };
    case ReduxEnums.KpiCheckPageActionsEnum.changeKpiValueCalculationCounter:
      return {
        ...state,
        kpiValueCalculationCounter: state.kpiValueCalculationCounter + action.payload,
      };
    case ReduxEnums.KpiCheckPageActionsEnum.setCompleteKpisValidation:
      return {
        ...state,
        completeKpisValidation: action.payload,
      };
    default:
      return state;
  }
}
