import React, { FunctionComponent, } from "react";
import { useSelector } from "react-redux";
import { Bank, BankVisibilityParameterSets } from "../../domain/Bank";
import { RootState } from "../../store/reduxStore";
import { BankHeaderInformation } from "../../components/Banks";
import { StoryFeature } from "../../domain/StoryFeature";

const ParametrisationFeatureConfigurationPageHeaderInfo: FunctionComponent = () => {

  const selectedBank: Bank | undefined = useSelector((state: RootState) => state.parametrisationFeatureConfigurationPage.selectedBank);
  const selectedParameter: BankVisibilityParameterSets | undefined = useSelector((state: RootState) => state.parametrisationFeatureConfigurationPage.selectedParameter);
  const selectedStoryFeature: StoryFeature | undefined = useSelector((state: RootState) => state.parametrisationFeatureConfigurationPage.selectedStoryFeature);

  return (
    <BankHeaderInformation
      selectedBank={selectedBank}
      selectedParameter={selectedParameter}
      selectedStoryFeature={selectedStoryFeature}
    />
  );
}

export default ParametrisationFeatureConfigurationPageHeaderInfo;
