import axios, { AxiosResponse, CancelToken, Method } from "axios";
import { Api } from "../domain";
import { ServiceName } from "../enums/Services";
import { getServiceBaseUrl } from "./getServiceBaseUrl";
import { getIdToken } from "../auth/authProvider";
import { HttpStatusCodeEventType, ApiError } from "../domain/Api";
import { isApiCallError } from "../domain/Api/ApiError";

export function apiCall<Input, Output>(
  endpointUrl: string,
  method: Method,
  callback: (response: Api.ApiResponse<Output>) => void,
  serviceName: ServiceName,
  cancelToken?: CancelToken,
  apiVersion = "1.0"
): void {
  const baseUrl: string = getServiceBaseUrl(serviceName);
  if (!baseUrl) {
    callback(
      new Api.ApiResponse<Output>(false, null, [
        new Api.ApiError(
          serviceName.toString() + " is an unknown service name!"
        ),
      ])
    );
    return;
  }
  const url: string =
    baseUrl + (endpointUrl.startsWith("/") ? "" : "/") + endpointUrl;

  getIdToken()
    .then((token: string | ApiError) => {
      if (isApiCallError(token)) {
        callback(
          new Api.ApiResponse<Output>(false, null, [
            token,
          ]));
        return;
      }

      axios
        .request<Input, AxiosResponse<Output>>({
        url: url,
        headers: getHeaders(token, apiVersion),
        method: method,
        cancelToken: cancelToken
      })
        .then((fulfilledResponse: AxiosResponse<Output>) => {
          if (!callback) return;

          fulfilledResponse = parseAxiosResponse<Output>(fulfilledResponse);
          callback(new Api.ApiResponse<Output>(true, fulfilledResponse.data));
        })
        .catch((rejectedResponse: any) => {
          if (!callback) return;

          callback(
            new Api.ApiResponse<Output>(false, null, [
              new Api.ApiError(rejectedResponse.toString()),
            ])
          );
        });
    });
}

export function apiGet<Output>(
  endpointUrl: string,
  callback: (response: Api.ApiResponse<Output>) => void,
  serviceName: ServiceName,
  cancelToken?: CancelToken,
  apiVersion = "1.0"
): void {
  apiCall<unknown, Output>(
    endpointUrl,
    "GET",
    callback,
    serviceName,
    cancelToken,
    apiVersion
  );
}

export function apiPost<Input, Output>(
  endpointUrl: string,
  callback: (response: Api.ApiResponse<Output>) => void,
  serviceName: ServiceName,
  cancelToken?: CancelToken,
  apiVersion = "1.0"
): void {
  apiCall<Input, Output>(
    endpointUrl,
    "POST",
    callback,
    serviceName,
    cancelToken,
    apiVersion
  );
}

export async function apiCallAsync<Input, Output>(
  endpointUrl: string,
  method: Method,
  serviceName: ServiceName,
  apiVersion = "1.0"
): Promise<Api.ApiResponse<Output>> {
  const baseUrl: string = getServiceBaseUrl(serviceName);
  if (!baseUrl) {
    return new Api.ApiResponse<Output>(false, null, [
      new Api.ApiError(serviceName.toString() + " is an unknown service name!"),
    ]);
  }

  const token = await getIdToken();
  if (isApiCallError(token))
    return new Api.ApiResponse<Output>(false, null, [
      token,
    ]);

  const url: string =
    baseUrl + (endpointUrl.startsWith("/") ? "" : "/") + endpointUrl;
  const response = await axios
    .request<Input, AxiosResponse<Output>>({
    url: url,
    headers: getHeaders(token, apiVersion),
    method: method,
  })
    .then((fulfilledResponse: AxiosResponse<Output>) => {
      fulfilledResponse = parseAxiosResponse<Output>(fulfilledResponse);
      return new Api.ApiResponse<Output>(true, fulfilledResponse.data);
    })
    .catch((rejectedResponse: any) => {
      return new Api.ApiResponse<Output>(false, null, [
        new Api.ApiError(rejectedResponse.toString()),
      ]);
    });

  return response;
}

export async function apiGetAsync<Output>(
  endpointUrl: string,
  serviceName: ServiceName,
  apiVersion = "1.0"
): Promise<Api.ApiResponse<Output>> {
  return await apiCallAsync<unknown, Output>(
    endpointUrl,
    "GET",
    serviceName,
    apiVersion
  );
}

export async function apiPostAsync<Output>(
  endpointUrl: string,
  serviceName: ServiceName,
  apiVersion = "1.0"
): Promise<Api.ApiResponse<Output>> {
  return await apiCallAsync<unknown, Output>(
    endpointUrl,
    "POST",
    serviceName,
    apiVersion
  );
}

function callEventMethodByAxiosResponse<Output>(
  response: AxiosResponse<Output>,
  eventElement: HttpStatusCodeEventType<Output>
) {
  console.log(response.status);
  const eventMethod = eventElement.getEventFunction(response.status);
  if (eventMethod) eventMethod(response);
}

/**
 * Regex for identify datestring
 */
const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d*)?Z?$/;

/**
 * Currently deserialisation not working automatically for datestring to date type.
 * So use this method to loop through response and check if datestring and conform it to date.
 * TODO check if it would e better to use axios.interceptors.response.use(originalResponse => {}) and perform there the conversion
 * @param response 
 * @returns 
 */
function parseAxiosResponse<Output>(response: AxiosResponse<Output>): AxiosResponse<Output> {
  if (!response?.data)
    return response;

  response.data = handleDates(response.data);

  return response;
}

function handleDates(body: any): any {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];

    if (typeof value === "string" && dateFormat.test(value)) {
      
      body[key] = new Date(value);

    }
    else if (typeof value === "object")
      handleDates(value);
  }
  return body;
}

export type AxiosQueryParameter = {
  [key: string]: any
};

function getGraphApiAccessToken(): Promise<string | ApiError> {
  return new Promise(
    (
      resolve: (response: string) => void,
      reject: (response: ApiError) => void
    ) => {
      const eventElement: HttpStatusCodeEventType<string> = new HttpStatusCodeEventType<string>(
        (response: AxiosResponse<string>) => {
          reject(
            new ApiError(
              `Http Status Code ${response.status}: ${response.data}`
            )
          );
        },
        {
          404: (response: AxiosResponse<string>) => {
            reject(new ApiError(`Not found - ${response.statusText}`));
          },
        },
        [
          {
            start: 200,
            end: 300,
            eventFunction: (response: AxiosResponse<string>) => {
              resolve(response.data);
            },
          },
        ]
      );

      const requestFailed = (error: ApiError) => {
        reject(error);
      };

      apiGetEvent<string>('Azure/GetSystemToken', ServiceName.Api, eventElement, requestFailed);
    }
  )
}

async function getAccessTokenForServiceName(serviceName: ServiceName): Promise<string | ApiError> {
  switch (serviceName) {
    case ServiceName.GraphApi:
    {
      return await getGraphApiAccessToken();
    }

    default: {
      return await getIdToken();
    }
  }
}

export function apiCallEvent<Input, Output>(
  endpointUrl: string,
  method: Method,
  serviceName: ServiceName,
  eventElement: HttpStatusCodeEventType<Output>,
  requestFailed: (error: ApiError) => void,
  params: any = undefined,
  bodyData: Input | undefined = undefined,
  cancelToken?: CancelToken,
  apiVersion = "1.0",
  additionalHeaders?: { [key: string]: string }
): void {
  const baseUrl: string = getServiceBaseUrl(serviceName);
  if (!baseUrl) {
    return;
  }
  const url: string = endpointUrl.startsWith("http")
    ? endpointUrl
    : baseUrl + (endpointUrl.startsWith("/") ? "" : "/") + endpointUrl;

  getAccessTokenForServiceName(serviceName)
    .then((token: string | ApiError) => {
      if (isApiCallError(token)) {
        requestFailed(token);
        return;
      }

      const headers = {
        ...getHeaders(token, apiVersion),
        ...additionalHeaders
      };

      axios.request<Input, AxiosResponse<Output>>({
        url: url,
        headers: headers,
        method: method,
        validateStatus: (): boolean => {
          // validateStatus: (status: number): boolean => {
          // return status >= 200 && status < 300; // default
          return true;
        },
        params: params,
        data: bodyData,
        cancelToken: cancelToken
      })
        .then((fulfilledResponse: AxiosResponse<Output>) => {
          fulfilledResponse = parseAxiosResponse<Output>(fulfilledResponse);
          callEventMethodByAxiosResponse(fulfilledResponse, eventElement);
        })
        .catch((error: any) => {
          // all http error codes are allowed, so error won't be AxiosResult
          requestFailed(new ApiError(undefined, error));
        });
    })
    .catch((error: ApiError) => {
      requestFailed(error);
    })
}

export function apiGetEvent<Output>(
  endpointUrl: string,
  serviceName: ServiceName,
  eventElement: HttpStatusCodeEventType<Output>,
  requestFailed: (error: ApiError) => void,
  params: any = undefined,
  cancelToken?: CancelToken,
  apiVersion = "1.0"
): void {
  apiCallEvent<unknown, Output>(
    endpointUrl,
    "GET",
    serviceName,
    eventElement,
    requestFailed,
    params,
    undefined,
    cancelToken,
    apiVersion
  );
}

export function apiPostEvent<Input, Output>(
  endpointUrl: string,
  serviceName: ServiceName,
  bodyData: Input,
  eventElement: HttpStatusCodeEventType<Output>,
  requestFailed: (error: ApiError) => void,
  params: any = undefined,
  cancelToken?: CancelToken,
  apiVersion = "1.0"
): void {
  apiCallEvent<Input, Output>(
    endpointUrl,
    "POST",
    serviceName,
    eventElement,
    requestFailed,
    params,
    bodyData,
    cancelToken,
    apiVersion
  );
}

export function apiPatchEvent<Input, Output>(
  endpointUrl: string,
  serviceName: ServiceName,
  bodyData: Input,
  eventElement: HttpStatusCodeEventType<Output>,
  requestFailed: (error: ApiError) => void,
  params: any = undefined,
  cancelToken?: CancelToken,
  apiVersion = "1.0",
  additionalHeaders?: { [key: string]: string }
): void {
  apiCallEvent<unknown, Output>(
    endpointUrl,
    "PATCH",
    serviceName,
    eventElement,
    requestFailed,
    params,
    bodyData,
    cancelToken,
    apiVersion,
    additionalHeaders
  );
}

export function apiDeleteEvent<Input, Output>(
  endpointUrl: string,
  serviceName: ServiceName,
  eventElement: HttpStatusCodeEventType<Output>,
  requestFailed: (error: ApiError) => void,
  params: any = undefined,
  cancelToken?: CancelToken,
  apiVersion = "1.0"
): void {
  apiCallEvent<unknown, Output>(
    endpointUrl,
    "DELETE",
    serviceName,
    eventElement,
    requestFailed,
    params,
    {},
    cancelToken,
    apiVersion
  );
}

export function getHeaders(accessToken: string, apiVersion = "1.0"): { [key: string]: string } {
  return {
    "Content-Type": `application/json; v=${apiVersion}`,
    Authorization: "Bearer " + accessToken,
    Accept: `application/json; v=${apiVersion}`,
  };
}

export type HttpStatusCodeRangeAcceptance = {
  start: number
  end?: number
};
