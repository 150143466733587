import React, { forwardRef, ForwardRefRenderFunction, ForwardRefExoticComponent } from "react";
import { Icons, Localization } from 'material-table';
import { AddBox, Check, Clear, DeleteOutline, ChevronRight, Edit, SaveAlt, FilterList, FirstPage, LastPage, ChevronLeft, Search, ArrowDownward, Remove, ViewColumn } from "@material-ui/icons";

export const forwardRefWithDisplayName = (displayName: string, render: ForwardRefRenderFunction<SVGSVGElement, React.RefAttributes<SVGSVGElement>>): ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>> => {
  const component: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>> = forwardRef<SVGSVGElement, React.RefAttributes<SVGSVGElement>>(render);
  component.displayName = displayName;
  return component;
}

export const materialTableIcons: Icons = {
  Add: forwardRefWithDisplayName("Add", (props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRefWithDisplayName("Check", (props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRefWithDisplayName("Clear", (props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRefWithDisplayName("Delete", (props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRefWithDisplayName("DetailPanel", (props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRefWithDisplayName("Edit", (props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRefWithDisplayName("Export", (props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRefWithDisplayName("Filter", (props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRefWithDisplayName("FirstPage", (props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRefWithDisplayName("LastPage", (props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRefWithDisplayName("NextPage", (props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRefWithDisplayName("PreviousPage", (props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRefWithDisplayName("ResetSearch", (props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRefWithDisplayName("Search", (props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRefWithDisplayName("SortArrow", (props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRefWithDisplayName("ThirdStateCheck", (props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRefWithDisplayName("ViewColumn", (props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const materialTableDefaultLocalization: Localization = {
  pagination: {
    labelDisplayedRows: '{from}-{to} von {count}'
  },
  toolbar: {
    nRowsSelected: '{0} Zeile(n) selektiert'
  },
  body: {
    emptyDataSourceMessage: 'Keine Einträge vorhanden',
    filterRow: {
      filterTooltip: 'Filter'
    }
  }
}