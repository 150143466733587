import React, { Attributes, ReactElement } from "react";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import InputIcon from '@material-ui/icons/Input';
import { OutputType } from "../../../enums/CreditProcess";
import { getEnumKeyByEnumValue } from "../../../enums/helperFunctions";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export type FeaturePortProps = {
  portElement: ReactElement
  cloneElementProps?: Partial<any> & Attributes
}

const trafficLightsColors = [OutputType.Red, OutputType.Yellow, OutputType.Green];
const outputsWithCheckIcon = [OutputType.ZinsFound, OutputType.True, OutputType.KpisValid, OutputType.ApplicationValid, OutputType.DocuSignCompleted];
const outputsWithCloseIcon = [OutputType.ZinsNotFound, OutputType.ApplicationInvalid, OutputType.DocuSignFailed, OutputType.KpisInvalid];

const getPortOutput = (portElement: ReactElement, tCreditProcess: TFunction, tNotKnownNamespace: TFunction): ReactElement | null => {
  if (!portElement)
    return null;

  const { props: { name, outputType, isInput = false } } = portElement || { props: {} };

  const portName = name ? tNotKnownNamespace(name) : '';
  if (isInput)
    return (
      <Tooltip title={portName} aria-label={portName}>
        <InputIcon />
      </Tooltip>
    )

  if (!portName || portName === '')
    return null;

  const outputTypeEnumKey = getEnumKeyByEnumValue(OutputType, outputType);
  if (outputTypeEnumKey == null)
    return <span>{portName}</span>;

  const outputTypeEnumValue: OutputType = OutputType[outputTypeEnumKey]
  if (trafficLightsColors.includes(outputTypeEnumValue)) {
    let htmlColor;
    switch (outputTypeEnumValue) {
      case OutputType.Green:
        htmlColor = "green";
        break;
      case OutputType.Yellow:
        htmlColor = "yellow";
        break;
      case OutputType.Red:
        htmlColor = "red";
        break;
      default:
        htmlColor = "grey";
        break;
    }

    return <Tooltip title={portName} aria-label={portName}><FiberManualRecordIcon htmlColor={htmlColor} /></Tooltip>
  }

  if (outputsWithCheckIcon.includes(outputTypeEnumValue))
    return <Tooltip title={portName} aria-label={portName}><CheckIcon /></Tooltip>

  if (outputsWithCloseIcon.includes(outputTypeEnumValue))
    return <Tooltip title={portName} aria-label={portName}><CloseIcon /></Tooltip>

  return <span>{portName}</span>;
}

const FeaturePort = ({ portElement, cloneElementProps }: FeaturePortProps): JSX.Element | null => {
  const { t: tCreditProcess } = useTranslation('CreditProcess');
  const { t: tNotKnownNamespace } = useTranslation('OutputDB');
  if (!portElement)
    return null;

  if (!portElement)
    return null;

  const currentElementProps = cloneElementProps ?? {
    style: {}
  };

  const portNameOutput: ReactElement | null = getPortOutput(portElement, tCreditProcess, tNotKnownNamespace);

  return (
    <div>
      {portNameOutput}
      {React.cloneElement(portElement, currentElementProps)}
    </div>
  );

}


export default FeaturePort;