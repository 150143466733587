import { createFormularElement, FormularElement, Variable } from ".";
import { FormularElementType } from "../../enums/FormularEditor";

type ConstantFormularElement = FormularElement & {
  constant: Variable
};

export default ConstantFormularElement;

export function createConstantFormularElement(variable: Variable, id: number | FormularElement[]): ConstantFormularElement {
  return {
    ...createFormularElement(variable.label, id),
    type: FormularElementType.constant,
    constant: variable
  }
}