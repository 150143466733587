import { ReduxEnums } from "../../enums";
import { ActionTypes } from "../actions";

interface InitialStateType {
  isPageLoading: boolean
  isDrawerOpen: boolean
}

const initialState: InitialStateType = {
  isPageLoading: false,
  isDrawerOpen: false,
};

export default function ui(
  state = initialState,
  action: ActionTypes
): InitialStateType {
  switch (action.type) {
    case ReduxEnums.UiActionsEnum.togglePageIsLoading:
      return {
        ...state,
        isPageLoading: action.payload,
      };
    case ReduxEnums.UiActionsEnum.toggleDrawerIsOpen:
      return {
        ...state,
        isDrawerOpen: action.payload,
      };
    default:
      return state;
  }
}
