export type ErrorCodeConstantsType = {
  success: number
  defaultError: number
};

const ErrorCodeConstants: ErrorCodeConstantsType = {
  success: 0,
  defaultError: -1
};

export default ErrorCodeConstants;
