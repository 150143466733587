import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => createStyles({
  noSelectedBankDiv: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  createConfigurationButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(),
    width: '248px'
  }
}));
