import { Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react"
import { useDrag } from "react-dnd"
import { FeatureDragDrop, ItemTypes } from "../.."
import { Feature } from "../../../../../../../../../../domain/StoryFeature"
import useStyles from './FeatureControlBox.styles';

export type FeatureControlBoxProps = {
  feature: Feature
}

type CollectedDragProps = {
  isDragging: boolean
}

const FeatureControlBox: React.FC<FeatureControlBoxProps> = ({ feature }) => {
  const classes = useStyles();
  const [{ isDragging }, drag] = useDrag<FeatureDragDrop, void, CollectedDragProps>({
    item: { type: ItemTypes.FEATURE, feature: feature },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  })

  return (
    <div ref={drag}
      className={
        classNames(classes.root,
          {
            [classes.dragging]: isDragging
          })
      }
    >
      <Typography>
        {feature.name}
      </Typography>
    </div>
  )
}

export default FeatureControlBox;