export enum SettingsQuickDates {
  Today = 'Today',
  ThisWeek = 'This Week',
  LastWeek = 'Last Week',
  ThisMonth = 'This Month',
  NextMonth = 'Next Month',
  LastMonth = 'Last Month',
  Custom = 'Custom'
}

export enum TimelineTypes {
  Call = 'Calls',
  Email = 'Email',
  Opportunity = 'Opportunity',
}

export enum CallInsertEntryNames {
  RemoteName = 'Remote Name'
}