import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2)
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  navigationBar: {
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: 0,
  },
  button: {
    margin: theme.spacing(1),
  },
  importParametrisationRulesDialog: {
    maxWidth: 'none'
  },
  upsertParametrisationRuleDialog: {
    maxWidth: 'none'
  },
}));
