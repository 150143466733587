import { InOperatorFormularElementMetaData, NotInOperatorFormularElementMetaData, Operator, OperatorMetaData, Variable } from "../domain/FormularEditor";
import { EnumerationType } from "../domain/UI/SelectOption";
import { OperatorGroupType, OperatorType } from "../enums/FormularEditor";
import i18n from "i18next";

const simpleMxParserOperatorTranslationMap: Map<OperatorType, string> = new Map<OperatorType, string>([
  [OperatorType.and, "&&"],
  [OperatorType.or, "||"],
  [OperatorType.plus, "+"],
  [OperatorType.minus, "-"],
  [OperatorType.mulitply, "*"],
  [OperatorType.divide, "/"],
  [OperatorType.bracketClose, ")"],
  [OperatorType.bracketOpen, "("],
  [OperatorType.equal, "=="],
  [OperatorType.notEqual, "!="],
  [OperatorType.greater, ">"],
  [OperatorType.greaterEqual, ">="],
  [OperatorType.lower, "<"],
  [OperatorType.lowerEqual, "<="],
]);

const operatorLabelMap: Map<OperatorType, (metaData?: OperatorMetaData) => string> = new Map<OperatorType, (metaData?: OperatorMetaData) => string>([
  [OperatorType.and, (): string => { return "und" }],
  [OperatorType.or, (): string => { return "oder" }],
  [OperatorType.plus, (): string => { return "+" }],
  [OperatorType.minus, (): string => { return "-" }],
  [OperatorType.mulitply, (): string => { return "*" }],
  [OperatorType.divide, (): string => { return "/" }],
  [OperatorType.bracketClose, (): string => { return ")" }],
  [OperatorType.bracketOpen, (): string => { return "(" }],
  [OperatorType.equal, (): string => { return "=" }],
  [OperatorType.notEqual, (): string => { return "!=" }],
  [OperatorType.greater, (): string => { return ">" }],
  [OperatorType.greaterEqual, (): string => { return ">=" }],
  [OperatorType.lower, (): string => { return "<" }],
  [OperatorType.lowerEqual, (): string => { return "<=" }],
  [OperatorType.in, (metaData?: OperatorMetaData): string => {
    const inMetaData: InOperatorFormularElementMetaData = metaData as InOperatorFormularElementMetaData;
    if (!inMetaData || !inMetaData.elementToCheck || !inMetaData.elements || !inMetaData.elements.length)
      return "In";
    const elementToCheckAsVariable = inMetaData.elementToCheck as Variable;

    return `${elementToCheckAsVariable?.label ?? inMetaData.elementToCheck as string} in 
        [${inMetaData.elements.map((element: string | Variable) => (element as Variable)?.label ?? element as string).join("; ")}]
        `;
  }],
  [OperatorType.notIn, (metaData?: OperatorMetaData): string => {
    const notInMetaData: NotInOperatorFormularElementMetaData = metaData as NotInOperatorFormularElementMetaData;
    if (!notInMetaData || !notInMetaData.elementToCheck || !notInMetaData.elements || !notInMetaData.elements.length)
      return "Nicht in";
    const elementToCheckAsVariable = notInMetaData.elementToCheck as Variable;

    return `${elementToCheckAsVariable?.label ?? notInMetaData.elementToCheck as string} ${i18n.t('Components:constants.formularEditor.notIn')}
        [${notInMetaData.elements.map((element: string | Variable) => (element as Variable)?.label ?? element as string).join("; ")}]
        `;
  }],
]);


const operatorTooltipMap: Map<OperatorType, string> = new Map<OperatorType, string>([
  [OperatorType.and, `${i18n.t('Components:constants.formularEditor.leftAndRight')}`],
  [OperatorType.or, `${i18n.t('Components:constants.formularEditor.leftAndRight')}`],
  [OperatorType.plus, `${i18n.t('Components:constants.formularEditor.addition')}`],
  [OperatorType.minus, `${i18n.t('Components:constants.formularEditor.subtraction')}`],
  [OperatorType.mulitply, `${i18n.t('Components:constants.formularEditor.mulitplication')}`],
  [OperatorType.divide, `${i18n.t('Components:constants.formularEditor.division')}`],
  [OperatorType.bracketClose, `${i18n.t('Components:constants.formularEditor.closeBracket')}`],
  [OperatorType.bracketOpen, `${i18n.t('Components:constants.formularEditor.openBracket')}`],
  [OperatorType.equal, `${i18n.t('Components:constants.formularEditor.equal')}`],
  [OperatorType.notEqual, `${i18n.t('Components:constants.formularEditor.notEqual')}`],
  [OperatorType.greater, `${i18n.t('Components:constants.formularEditor.greater')}`],
  [OperatorType.greaterEqual, `${i18n.t('Components:constants.formularEditor.greaterEqual')}`],
  [OperatorType.lower, `${i18n.t('Components:constants.formularEditor.lower')}`],
  [OperatorType.lowerEqual, `${i18n.t('Components:constants.formularEditor.lower')}`],
  [OperatorType.in, `${i18n.t('Components:constants.formularEditor.inQuantity')}`],
  [OperatorType.notIn, `${i18n.t('Components:constants.formularEditor.notInQuantity')}`],
]);

const operators: Operator[] = Object.keys(OperatorType).map((key: string) => {
  const operatorType: OperatorType = (OperatorType as EnumerationType<OperatorType>)[key];
  const labelFunction = operatorLabelMap.get(operatorType);
  return {
    label: labelFunction ? labelFunction() : operatorType.toString(),
    operatorType,
    tooltip: operatorTooltipMap.get(operatorType) ?? ''
  }
});

export type ComplexMxParserPhrasesType = {
  inOperatorStartsWith: string
  inOperatorEndsWith: string
  notInOperatorEndsWith: string
  notInOperatorStartsWith: string
}

export type FormularEditorConstantsType = {
  simpleMxParserOperatorTranslationMap: Map<OperatorType, string>
  complexMxParserPhrases: ComplexMxParserPhrasesType
  operatorLabelMap: Map<OperatorType, (metaData?: OperatorMetaData) => string>
  operatorTooltipMap: Map<OperatorType, string>
  operators: Operator[]
  getOperatorsForOpertorGroupType(operatorGroupType: OperatorGroupType): Operator[]
  getOperators(operatorsToUse: "all" | "none" | OperatorType[]): Operator[]
  parseMxStringToOperator(possibleMxString: string, variables: Variable[], constants: Variable[]): Operator | undefined
  getOperatorForOperatorType(operatorType: OperatorType): Operator | undefined
  getMxStringForOperator(operator: Operator): string | undefined
  complexMxParserOperatorsFromString: ((valueToParse: string, variables: Variable[], constants: Variable[]) => Operator | undefined)[]
  complexMxParserOperatorsToString: ((operator: Operator) => string | undefined)[]
}

const FormularEditorConstants: FormularEditorConstantsType = {
  simpleMxParserOperatorTranslationMap: simpleMxParserOperatorTranslationMap,
  operatorLabelMap,
  operatorTooltipMap,
  operators,
  getOperatorsForOpertorGroupType(operatorGroupType: OperatorGroupType): Operator[] {
    let acceptedOperatorTypes: OperatorType[] = new Array<OperatorType>();
    switch (operatorGroupType) {
      case OperatorGroupType.boolean:
        acceptedOperatorTypes = [
          OperatorType.and, OperatorType.or, OperatorType.bracketOpen, OperatorType.bracketClose
        ];
        break;
      case OperatorGroupType.comparsion:
        acceptedOperatorTypes = [
          OperatorType.equal, OperatorType.notEqual, OperatorType.greater, OperatorType.greaterEqual, OperatorType.lowerEqual,
          OperatorType.lower
        ];
        break;
    }

    return FormularEditorConstants.operators.filter((operator: Operator) => acceptedOperatorTypes.includes(operator.operatorType));
  },
  getOperators(operatorsToUse: "all" | "none" | OperatorType[]): Operator[] {
    if (operatorsToUse === "all")
      return FormularEditorConstants.operators;

    if (operatorsToUse === "none")
      return new Array<Operator>();

    return FormularEditorConstants.operators.filter((operator: Operator) => operatorsToUse.includes(operator.operatorType));
  },
  parseMxStringToOperator(stringToParse: string, variables: Variable[], constants: Variable[]): Operator | undefined {
    if (!stringToParse)
      return;

    const simpleMxParserOperator = [...FormularEditorConstants.simpleMxParserOperatorTranslationMap.entries()].find((entry: [OperatorType, string]) => entry[1] === stringToParse)
    if (simpleMxParserOperator)
      return FormularEditorConstants.getOperatorForOperatorType(simpleMxParserOperator[0]);

    const complexMxParserOperatorsLength = FormularEditorConstants.complexMxParserOperatorsFromString.length;
    for (let i = 0; i < complexMxParserOperatorsLength; i++) {
      const fnc = FormularEditorConstants.complexMxParserOperatorsFromString[i];
      if (!fnc)
        continue;
      const fncResult = fnc(stringToParse, variables, constants);
      if (fncResult)
        return fncResult;
    }
  },
  getOperatorForOperatorType(operatorType: OperatorType): Operator | undefined {
    return FormularEditorConstants.operators.find((operator: Operator) => operator.operatorType === operatorType);
  },
  getMxStringForOperator(operator: Operator): string | undefined {
    const simpleMxParserString = FormularEditorConstants.simpleMxParserOperatorTranslationMap.get(operator.operatorType);
    if (simpleMxParserString)
      return simpleMxParserString;

    const complexMxParserOperatorsToStringLength = FormularEditorConstants.complexMxParserOperatorsToString.length;
    for (let i = 0; i < complexMxParserOperatorsToStringLength; i++) {
      const fnc = FormularEditorConstants.complexMxParserOperatorsToString[i];
      if (!fnc)
        continue;
      const fncResult = fnc(operator);
      if (fncResult)
        return fncResult;
    }
  },
  complexMxParserPhrases: {
    inOperatorEndsWith: ')',
    inOperatorStartsWith: 'containsElement(',
    notInOperatorEndsWith: ')',
    notInOperatorStartsWith: 'containsNotElement(',
  },
  complexMxParserOperatorsFromString: [
    (stringToParse: string, variables: Variable[], constants: Variable[]): Operator | undefined => {
      // for operator in. Syntax should containsElement(elementToCheck;inElements1;inElements2;inElements3...)
      if (!stringToParse || stringToParse === '' || !stringToParse.startsWith(FormularEditorConstants.complexMxParserPhrases.inOperatorStartsWith) || !stringToParse.endsWith(FormularEditorConstants.complexMxParserPhrases.inOperatorEndsWith))
        return;


      const parametersString: string = stringToParse.substring(FormularEditorConstants.complexMxParserPhrases.inOperatorStartsWith.length, stringToParse.length - 1);
      if (!parametersString || parametersString === '')
        return;

      const parameters: string[] = parametersString.split(";");
      // at least two parameters: first parameter is elementToCheck and second one are the elements
      if (!parameters || parameters.length < 2)
        return;

      const metaData: Partial<OperatorMetaData> = {};
      const addToMetaData = (valueToAdd: string | Variable, index: number) => {
        if (index === 0) {
          metaData.elementToCheck = valueToAdd;
          return;
        }
        if (!metaData.elements)
          metaData.elements = new Array<string | Variable>();
        metaData.elements.push(valueToAdd);
      }
      parameters.forEach((parameter: string, index: number) => {
        const possibleVariable = variables.find((v: Variable) => v.id === parameter);
        if (possibleVariable) {
          addToMetaData(possibleVariable, index);
          return;
        }

        const possibleConstant = constants.find((v: Variable) => v.id === parameter);
        if (possibleConstant) {
          addToMetaData(possibleConstant, index);
          return;
        }

        addToMetaData(parameter, index);
      })

      if (!metaData || !metaData.elementToCheck || !metaData.elements)
        return;

      const labelFunction = FormularEditorConstants.operatorLabelMap.get(OperatorType.in);
      if (!labelFunction)
        return;

      return {
        label: labelFunction(metaData as OperatorMetaData),
        operatorType: OperatorType.in,
        tooltip: FormularEditorConstants.operatorTooltipMap.get(OperatorType.in) ?? '',
        metaData: metaData as OperatorMetaData
      };
    },
    (stringToParse: string, variables: Variable[], constants: Variable[]): Operator | undefined => {
      // for operator noIn. Syntax should containsNotElement(elementToCheck;notInElements1;notInElements2;notInElements3...)
      if (!stringToParse || stringToParse === '' || !stringToParse.startsWith(FormularEditorConstants.complexMxParserPhrases.notInOperatorStartsWith) || !stringToParse.endsWith(FormularEditorConstants.complexMxParserPhrases.notInOperatorEndsWith))
        return;


      const parametersString: string = stringToParse.substring(FormularEditorConstants.complexMxParserPhrases.notInOperatorStartsWith.length, stringToParse.length - 1);
      if (!parametersString || parametersString === '')
        return;

      const parameters: string[] = parametersString.split(";");
      // at least two parameters: first parameter is elementToCheck and second one are the elements
      if (!parameters || parameters.length < 2)
        return;

      const metaData: Partial<OperatorMetaData> = {};
      const addToMetaData = (valueToAdd: string | Variable, index: number) => {
        if (index === 0) {
          metaData.elementToCheck = valueToAdd;
          return;
        }
        if (!metaData.elements)
          metaData.elements = new Array<string | Variable>();
        metaData.elements.push(valueToAdd);
      }
      parameters.forEach((parameter: string, index: number) => {
        const possibleVariable = variables.find((v: Variable) => v.id === parameter);
        if (possibleVariable) {
          addToMetaData(possibleVariable, index);
          return;
        }

        const possibleConstant = constants.find((v: Variable) => v.id === parameter);
        if (possibleConstant) {
          addToMetaData(possibleConstant, index);
          return;
        }

        addToMetaData(parameter, index);
      })

      if (!metaData || !metaData.elementToCheck || !metaData.elements)
        return;

      const labelFunction = FormularEditorConstants.operatorLabelMap.get(OperatorType.notIn);
      if (!labelFunction)
        return;

      return {
        label: labelFunction(metaData as OperatorMetaData),
        operatorType: OperatorType.notIn,
        tooltip: FormularEditorConstants.operatorTooltipMap.get(OperatorType.notIn) ?? '',
        metaData: metaData as OperatorMetaData
      };
    }
  ],
  complexMxParserOperatorsToString: [
    (operator: Operator): string | undefined => {
      if (operator.operatorType !== OperatorType.in || !operator.metaData)
        return;

      const inOperatorFormularElementMetaData = operator.metaData as InOperatorFormularElementMetaData;
      if (!inOperatorFormularElementMetaData || !inOperatorFormularElementMetaData.elementToCheck || !inOperatorFormularElementMetaData.elements || !inOperatorFormularElementMetaData.elements.length)
        return;

      return `${FormularEditorConstants.complexMxParserPhrases.inOperatorStartsWith}${(inOperatorFormularElementMetaData.elementToCheck as Variable)?.id ?? inOperatorFormularElementMetaData.elementToCheck};` +
                `${inOperatorFormularElementMetaData.elements.map((value: string | Variable) => (value as Variable)?.id ?? value as string).join(";")}${FormularEditorConstants.complexMxParserPhrases.inOperatorEndsWith}`
    },
    (operator: Operator): string | undefined => {
      if (operator.operatorType !== OperatorType.notIn || !operator.metaData)
        return;

      const notInOperatorFormularElementMetaData = operator.metaData as NotInOperatorFormularElementMetaData;
      if (!notInOperatorFormularElementMetaData || !notInOperatorFormularElementMetaData.elementToCheck || !notInOperatorFormularElementMetaData.elements || !notInOperatorFormularElementMetaData.elements.length)
        return;

      return `${FormularEditorConstants.complexMxParserPhrases.notInOperatorStartsWith}${(notInOperatorFormularElementMetaData.elementToCheck as Variable)?.id ?? notInOperatorFormularElementMetaData.elementToCheck};` +
                `${notInOperatorFormularElementMetaData.elements.map((value: string | Variable) => (value as Variable)?.id ?? value as string).join(";")}${FormularEditorConstants.complexMxParserPhrases.notInOperatorEndsWith}`
    }
  ]
};

export default FormularEditorConstants;