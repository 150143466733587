import { ApiError } from "../domain/Api";
import { Bank, BankVisibilityParameterSets } from "../domain/Bank";
import { GetBankAndBankVisibilityParameterDto, GetBanksDto, GetBankVisibilityParametersForBankDto, Tender } from "../domain/Marketplace";
import { Inquiry } from "../domain/Marketplace/Inquiry";
import { ApiService } from "./ApiService";

export class MarketplaceApi extends ApiService {

  async getBank(bankId: string): Promise<Bank | ApiError> {
    try {
      return await this.Get<Bank>(`/Marketplace/GetBank?bankId=${bankId}`);
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async getBanks(pageNumber: number, pageSize: number, bankName?: string): Promise<GetBanksDto | ApiError> {
    try {
      let url = `/Marketplace/GetBanks?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      if (bankName)
        url += `&bankName=${bankName}`;

      return await this.Get<GetBanksDto>(url);
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async getBankVisibilityParametersForBank(bankId: string, pageNumber: number, pageSize: number): Promise<GetBankVisibilityParametersForBankDto | ApiError> {
    try {
      return await this.Get<GetBankVisibilityParametersForBankDto>(`/Marketplace/GetBankVisibilityParametersForBank?bankId=${bankId}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async getBankVisibilityParameter(bankId: string, bankVisibilityParameterId: string): Promise<BankVisibilityParameterSets | ApiError> {
    try {
      return await this.Get<BankVisibilityParameterSets>(`/Marketplace/GetBankVisibilityParameter?bankId=${bankId}&bankVisibilityParameterId=${bankVisibilityParameterId}`);
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async getBankAndBankVisibilityParameter(bankVisibilityParameterId: number): Promise<GetBankAndBankVisibilityParameterDto | ApiError> {
    try {
      return await this.Get<GetBankAndBankVisibilityParameterDto>(`/Marketplace/GetBankAndBankVisibilityParameter?bankVisibilityParameterId=${bankVisibilityParameterId}`);
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async getTender(tenderId: string): Promise<Tender | ApiError> {
    try {
      return await this.Get<Tender>(`/Marketplace/GetTender?tenderId=${tenderId}`);
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async getInquiry(inquiryId: string): Promise<Inquiry | ApiError> {
    try {
      return await this.Get<Inquiry>(`/Marketplace/GetInquiry?inquiryId=${inquiryId}`);
    }
    catch (error) {
      return error as ApiError;
    }
  }
}

export const currentMarketplaceApiService = new MarketplaceApi();