import React, { FunctionComponent, Suspense } from 'react';
import { Switch as RoutesSwitch } from 'react-router-dom';
import { getRouterRoutes } from '../../pages/routes';
import Header from './Header';
import Sidebar from "./Sidebar";
import classnames from "classnames";
import { ExtendedRouteProps } from '../../domain/App';
import SelfServiceRoute from '../../domain/App/SelfServiceRoute';
import { useDispatch, useSelector } from 'react-redux';

import appStyles from './Layout.styles';
import { RootState } from '../../store/reduxStore';
import { toggleIsDrawerOpen } from '../../store/actions/uiActions/actions';
import i18n from "i18next";
import classNames from 'classnames';

export type LayoutProps = {
  darkState: boolean
  setDarkState: (setDarkState: boolean) => void
}

const Layout: FunctionComponent<LayoutProps> = ({ darkState, setDarkState }) => {
  const classes = appStyles();
  const dispatch = useDispatch();  

  const open: boolean = useSelector((state: RootState) => state.ui.isDrawerOpen);
  const setOpen = (isDrawerOpen: boolean) => {
    dispatch(toggleIsDrawerOpen(isDrawerOpen))
  }

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  }

  const routes = getRouterRoutes().map((route: ExtendedRouteProps, index: number) => <SelfServiceRoute key={index} exact={route.exact} path={route.path} component={route.component} route={route} />);

  return (
    <div className={classNames(classes.root, darkState ? "mgt-dark" : "mgt-light")}>
      <>
        <Header
          handleDrawerToggle={handleDrawerToggle}
          darkState={darkState}
          setDarkState={setDarkState}
          open={open}
        />
        <Sidebar
          open={open}
          handleDrawerClose={handleDrawerClose}
        />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: open,
          })}
        >

          <div className={classes.fakeToolbar} />
          <Suspense fallback={<div>{i18n.t("Layout:loading")}</div>}>
            <RoutesSwitch>
              {
                routes
              }
            </RoutesSwitch>
          </Suspense>
        </div>
      </>
    </div>
  );
}

export default Layout;