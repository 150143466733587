import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2)
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  breadcrumbContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(5),
  },
  breadcrumbTypography: {
    color: theme.palette.text.secondary
  },
  breadcrumbLastTypography: {
    color: theme.palette.text.primary
  },
  breadcrumbSeparator: {
    // adjust fontSize of separator to fontsize of Typograph from Wrappers.js of material ui admin
    //multiplier 1.5 is for size="md" of Typograph
    fontSize: `calc(${theme.typography.fontSize}px *1.5)`
  }
}));
