import { Button, ButtonGroup, FormLabel, IconButton, TextField } from "@material-ui/core";
import { Autocomplete, AutocompleteChangeReason } from "@material-ui/lab";
import React, { ChangeEvent, FunctionComponent, ReactElement, useEffect, useState } from "react";
import { CheckMathExpressionSyntaxOutput, ConstantFormularElement, createConstantFormularElement, createFormularElement, createNumberFormularElement, createOperatorFormularElement, createVariableFormularElement, FormularElement, NumberFormularElement, Operator, OperatorFormularElement, parseStringToNumberFormularElement, parseStringToOperatorFormularElement, SelectedVariable, sortVariablesAlphabetical, Variable, VariableFormularElement } from "../../domain/FormularEditor";
import { FormularElementType, OperatorType } from "../../enums/FormularEditor";
import AddIcon from '@material-ui/icons/Add';
// import { DraggableGrid } from "../DraggableGrid";
import NumberFormatField from "../NumberFormatField/NumberFormatField";
import DeleteIcon from '@material-ui/icons/Delete';
import useStyles from './FormularEditor.styles';
import RLDD, { RLDDItem } from "react-list-drag-and-drop/lib/RLDD";
import classNames from "classnames";
import { FormularEditorConstants } from "../../constants";
import { OperatorCreatorDialog } from "./components";
import { currentFormularEditorApiService } from "../../services/FormularEditorApi";
import { isApiCallError } from "../../domain/Api/ApiError";
import { MathParserArgument } from "../../domain/FormularEditor/CheckMathExpressionSyntaxInput";
import i18n from "i18next";

export type FormularEditorProps = {
  calculationFormular: string
  logicalTerm: boolean
  operatorType?: "all" | "none" | OperatorType[]
  allowEmptyFormular?: boolean
  variables?: Variable[]
  constantVariables?: Variable[]
  showAddNewVariableButton?: boolean
  showVariablesInput?: boolean
  showConstantVariablesInput?: boolean
  showNumberInput?: boolean
  showRemoveButton?: boolean
  numberValue?: number
  customComponents?: ReactElement[]
  onCalculationFormularChanged: (calculationFormular: string, readableFormular?: string, valueArray?: string[]) => void
  openNewVariablePopup?: (addVariableFunction: (newVariable: Variable) => void) => () => void
  selectedVariable?: SelectedVariable
  label?: string
}

const FormularEditor: FunctionComponent<FormularEditorProps> = ({
  calculationFormular: propCalculationFormular = '', logicalTerm = false, operatorType = "all",
  allowEmptyFormular = false, variables = [], constantVariables = [],
  showAddNewVariableButton = false, showVariablesInput = false,
  showConstantVariablesInput = false,
  showNumberInput = false, showRemoveButton = false, numberValue: propNumberValue,
  customComponents, onCalculationFormularChanged, openNewVariablePopup,
  selectedVariable: propSelectedVariable,
  label
}) => {
  const classes = useStyles();
  const [formularElements, setFormularElements] = useState<FormularElement[]>([]);
  const [selectedFormularElement, setSelectedFormularElement] = useState<FormularElement | null>(null);
  const [calculationFormular, setCalculationFormular] = useState<string>('');
  const [selectedVariable, setSelectedVariable] = useState<Variable | null>(null);
  const [selectedConstant, setSelectedConstant] = useState<Variable | null>(null);
  const [operators, setOperators] = useState<Operator[]>([]);
  const [selectedOperator, setSelectedOperator] = useState<Operator | null>(null);
  const [numberValue, setNumberValue] = useState<number | undefined>(propNumberValue);
  const [calculationFormularValidation, setCalculationFormularValidation] = useState<string>('');
  const [operatorToCreate, setOperatorToCreate] = useState<Operator | undefined>(undefined);

  const getFormularElementForString = (formularElement: string, variables: Variable[], constantVariables: Variable[], formularElements: FormularElement[]): FormularElement | null => {
    if (!formularElement || formularElement === '')
      return null;

    // check if string is known operator
    const operatorFormularElement: OperatorFormularElement | undefined = parseStringToOperatorFormularElement(formularElement, formularElements, variables, constantVariables);
    if (operatorFormularElement) {
      return operatorFormularElement;
    }

    const numberFormularElement = parseStringToNumberFormularElement(formularElement, formularElements);
    if (numberFormularElement) {
      return numberFormularElement;
    }

    const filteredVariable: Variable | undefined = variables.find(v => v.id === formularElement);
    if (filteredVariable)
      return createVariableFormularElement(filteredVariable, formularElements);

    const filteredConstantVariable: Variable | undefined = constantVariables.find(v => v.id === formularElement);
    if (filteredConstantVariable)
      return createConstantFormularElement(filteredConstantVariable, formularElements);

    return createFormularElement(formularElement, formularElements);
  }

  const getFormularElementsByCalculationFormular = (calculationFormular: string, variables: Variable[], constantVariables: Variable[]): FormularElement[] => {
    const newFormularElements: FormularElement[] = new Array<FormularElement>();
    if (calculationFormular == '')
      return newFormularElements;


    const formularSplitter = calculationFormular.split(" "); //if formular == "" then result is => Array with [""]

    for (let i = 0; i < formularSplitter.length; i++) {
      const formularElement = formularSplitter[i];
      const formularElementLength = formularElement.length;
      const addAtEndClosingBracket = false;

      if (formularElementLength > 0) {
        //if formularElement begins with a bracket, add bracket and cut it of from formularElement
        // needed?
        // if (formularElement[0] === '(') {
        //     const openBracketOperator = FormularEditorConstants.getOperatorForOperatorType(OperatorType.bracketOpen);
        //     if (openBracketOperator)
        //         newFormularElements.push(createOperatorFormularElement(openBracketOperator, newFormularElements));
        //     formularElement = formularElementLength > 1 ? formularElement.substring(1) : '';
        //     formularElementLength = formularElement.length;

        //     //if formularElement ends with a bracket, add bracket and cut it of from formularElement
        //     if (formularElement[formularElementLength - 1] === ')') {
        //         addAtEndClosingBracket = true;
        //         formularElement = formularElement.substring(0, formularElementLength - 1);
        //         formularElementLength = formularElement.length;
        //     }
        // }
      }

      const nextFormularElement = getFormularElementForString(formularElement, variables, constantVariables, newFormularElements);
      if (nextFormularElement)
        newFormularElements.push(nextFormularElement);

      if (addAtEndClosingBracket) {
        const closeBracketOperator = FormularEditorConstants.getOperatorForOperatorType(OperatorType.bracketClose);
        if (closeBracketOperator)
          newFormularElements.push(createOperatorFormularElement(closeBracketOperator, newFormularElements));
      }
    }

    return newFormularElements;
  }

  const setOperatorsByOperatorType = (operatorType: "all" | "none" | OperatorType[]) => {
    const operators = FormularEditorConstants.getOperators(operatorType);
    setOperators(operators);
  }

  useEffect(() => {
    setOperatorsByOperatorType(operatorType);
  }, [operatorType])

  useEffect(() => {
    if (calculationFormular !== '' && calculationFormular === propCalculationFormular)
      return;

    setCalculationFormular(propCalculationFormular);
    setFormularElements(getFormularElementsByCalculationFormular(propCalculationFormular, variables, constantVariables));
    updateCalculationFormularValidation(propCalculationFormular);
  }, [propCalculationFormular])


  useEffect(() => {
    if (calculationFormular === '')
      return;

    setCalculationFormular(calculationFormular);
    setFormularElements(getFormularElementsByCalculationFormular(calculationFormular, variables, constantVariables));
    updateCalculationFormularValidation(calculationFormular);
  }, [variables, constantVariables])

  useEffect(() => {
    if (propSelectedVariable) {
      if (propSelectedVariable.variable) {
        setSelectedVariable(propSelectedVariable.variable);
        if (propSelectedVariable.add) {
          addVariable(propSelectedVariable.variable);
        }
      }
    }
  }, [propSelectedVariable])

  const getCalculationFormularByFormularElements = (formularElements: FormularElement[]) => {
    let calculationFormular = "";

    const formularElementsLength = formularElements.length;
    for (let i = 0; i < formularElementsLength; i++) {
      const formularElement: FormularElement = formularElements[i];
      if (!formularElement)
        continue;

      let newFormularPart: string = formularElement.label;
      switch (formularElement.type) {
        case FormularElementType.constant: {
          const constant: Variable | null = (formularElement as ConstantFormularElement)?.constant;
          if (constant)
            newFormularPart = constant.id;
          break;
        }
        case FormularElementType.number: {
          const numberValue: number | null = (formularElement as NumberFormularElement)?.value;
          if (numberValue != null)
            newFormularPart = numberValue.toString();
          break;
        }
        case FormularElementType.operator: {
          const operator: Operator | null = (formularElement as OperatorFormularElement)?.operator;
          const mxString: string | undefined = operator ? FormularEditorConstants.getMxStringForOperator(operator) : undefined;
          if (mxString)
            newFormularPart = mxString;
          break;
        }
        case FormularElementType.variable: {
          const variable: Variable | null = (formularElement as VariableFormularElement)?.variable;
          if (variable)
            newFormularPart = variable.id;
          break;
        }
      }
      calculationFormular += newFormularPart;

      if (i + 1 < formularElementsLength)
        calculationFormular += " ";
    }

    return calculationFormular;
  }

  const getCalculationFormularValidation = async (calculationFormular: string): Promise<string> => {
    if ((!calculationFormular || calculationFormular === "") && !allowEmptyFormular) {
      return `${i18n.t('Components:formulaEditor.noFormula')}`;
    }

    const expresionArguments: MathParserArgument = {};

    variables.forEach(v => expresionArguments[v.id] = 1);
    constantVariables.forEach(v => expresionArguments[v.id] = 1);
    try {
      const checkResult = await currentFormularEditorApiService.checkSyntax({
        Arguments: expresionArguments,
        expression: calculationFormular,
        logicalTerm
      });

      if (isApiCallError<CheckMathExpressionSyntaxOutput>(checkResult)) {
        return  `${i18n.t('Components:formulaEditor.validateFail')}`;
      }
      const syntaxOutput = checkResult as CheckMathExpressionSyntaxOutput;
      if (syntaxOutput.errorMessage && syntaxOutput.errorMessage !== '')
        return `${i18n.t('Components:formulaEditor.validateError', { error: syntaxOutput.errorMessage })}`;

      if (!syntaxOutput.checkSyntaxResult)
        return `${i18n.t('Components:formulaEditor.validateErrorTwo')}`;

      if (syntaxOutput.checkSyntaxResult.errorMessage && syntaxOutput.checkSyntaxResult.errorMessage !== '') {
        console.log(`Formular is not valid: ${syntaxOutput.checkSyntaxResult.errorMessage}`);
        return `${i18n.t('Components:formulaEditor.notValid')}`;
      }

      return "";
    }
    catch (e) {
      console.log(`Error while calling formel validation ${e}`);
      return `${i18n.t('Components:formulaEditor.errorCall')}`;
    }
  }

  const updateCalculationFormularValidation = (calculationFormular: string): void => {
    setCalculationFormularValidation(`${i18n.t('Components:formulaEditor.executing')}`);
    getCalculationFormularValidation(calculationFormular)
      .then((validation: string) => setCalculationFormularValidation(validation));
  }

  const formularElementsToReadableString = (formulatElementsToString: FormularElement[]): string => {
    if (!formulatElementsToString?.length)
      return '';

    let returnValue = '';
    formulatElementsToString.forEach((formularElement: FormularElement, index: number) => {
      returnValue += `${index > 0 ? ' ' : ''}${formularElement.label}`;
    })

    return returnValue;
  }

  const updateFormularElements = (newFormularElements: FormularElement[]): void => {
    const newCalculationFormular: string = getCalculationFormularByFormularElements(newFormularElements);
    setCalculationFormular(newCalculationFormular);
    setFormularElements(newFormularElements);
    onCalculationFormularChanged(newCalculationFormular, formularElementsToReadableString(newFormularElements));
    updateCalculationFormularValidation(newCalculationFormular);
  }

  const addVariables = (newVariables: Variable[]): void => {
    if (!newVariables)
      return;

    const validVariables: Variable[] = newVariables.filter((v: Variable) => v.label != null && v.id != null);
    if (!validVariables?.length)
      return;

    const newFormularElements: FormularElement[] = new Array<FormularElement>().concat(formularElements);
    validVariables.forEach((v: Variable) => {
      newFormularElements.push(createVariableFormularElement(v, newFormularElements));
    })

    updateFormularElements(newFormularElements);
  }

  const addVariable = (newVariable: Variable): void => {
    if (!newVariable || !newVariable.label || newVariable.id == null)
      return;

    addVariables([newVariable]);
  }

  // method is used to add an already definied Operator
  const addOperator = (operator: Operator): void => {
    if (!operator)
      return;

    const newFormularElements: FormularElement[] = new Array<FormularElement>().concat(formularElements);
    const newOperatorFormularElement = createOperatorFormularElement(operator, newFormularElements);

    newFormularElements.push(newOperatorFormularElement);
    updateFormularElements(newFormularElements);
  }

  // method is used to create an operator by the type
  // its mainly added metadata if needed
  const createOperator = (operator: Operator): void => {
    if (!operator)
      return;

    setOperatorToCreate(operator);
  }

  const onOperatCreateCalled = (operator?: Operator): void => {
    setOperatorToCreate(undefined);
    if (operator)
      addOperator(operator);
  };

  const addNumber = (number: number): void => {
    const newFormularElements: FormularElement[] = new Array<FormularElement>().concat(formularElements);
    const newNumberFormularElement = createNumberFormularElement(number.toString(), number, newFormularElements);
    if (!newNumberFormularElement)
      return;

    newFormularElements.push(newNumberFormularElement);
    updateFormularElements(newFormularElements);
  }

  const onAddVariableChange = (_event: ChangeEvent<unknown>, newVariable: Variable | null, reason: AutocompleteChangeReason) => {
    if (reason !== 'select-option')
      return;
    setSelectedVariable(newVariable);

    if (!newVariable)
      return;

    addVariable(newVariable);
  }

  const onAddOperatorChange = (_event: ChangeEvent<unknown>, newOperator: Operator | null, reason: AutocompleteChangeReason) => {
    if (reason !== 'select-option')
      return;
    setSelectedOperator(newOperator);

    if (!newOperator)
      return;

    createOperator(newOperator);
  }

  const onAddKpiValueConstantChange = (_event: ChangeEvent<unknown>, newSelectedConstant: Variable | null, reason: AutocompleteChangeReason) => {
    if (reason !== 'select-option')
      return;

    setSelectedConstant(newSelectedConstant);

    if (!newSelectedConstant)
      return;

    addVariable(newSelectedConstant);
  }

  const onClickAddOperator = () => {
    if (!selectedOperator)
      return;

    createOperator(selectedOperator);
  }

  const onClickAddNumber = () => {
    if (!numberValue || isNaN(numberValue))
      return;

    addNumber(numberValue);
  }

  const removeSelectedFormularElement = () => {
    if (selectedFormularElement == null || !formularElements || !formularElements.length)
      return;

    const newFormularElements: FormularElement[] = new Array<FormularElement>().concat(formularElements).filter((f: FormularElement) => f.id !== selectedFormularElement.id);
    updateFormularElements(newFormularElements);
    setSelectedFormularElement(null);
  }

  const onClickRemoveElement = () => {
    removeSelectedFormularElement();
  }

  const onFormularElementItemClicked = (item: FormularElement) => {
    setSelectedFormularElement(selectedFormularElement?.id === item.id ? null : item);
  }

  const formularElementRenderer = (item: FormularElement): JSX.Element => {
    const isSelectedItem: boolean = selectedFormularElement?.id === item.id;
    return (
      <div
        className={classNames(classes.formularElementItem, {
          [classes.selectedFormularElementItem]: isSelectedItem
        })}
        onClick={() => onFormularElementItemClicked(item)}
      >
        <div className={classes.formularElementItemTitle}>{item.label}</div>
      </div>
    );
  };

  const handleRLDDChange = (items: RLDDItem[]) => {
    const newFormularElements: FormularElement[] = items as FormularElement[];
    if (!newFormularElements)
      return;

    updateFormularElements(newFormularElements);
  }

  return (
    <>
      {label != null && <FormLabel component="legend">{label}</FormLabel>}
      <div className={classes.controlBar}>
        {
          showAddNewVariableButton &&
          <Button
            size="medium"
            color="primary"
            variant="outlined"
            id="addVariableButton"
            onClick={openNewVariablePopup ? openNewVariablePopup(addVariable) : undefined}
            className={classes.controlBarButton}
            startIcon={<AddIcon />} >
            {i18n.t('Components:formulaEditor.newVariable')}
          </Button>
        }
        {
          showVariablesInput &&
          <Autocomplete
            size="small"
            autoComplete
            options={variables.sort(sortVariablesAlphabetical)}
            getOptionLabel={option => option.label}
            value={selectedVariable}
            renderInput={params =>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
                className={classes.autocompleteInputButtonGroup}
              >
                <TextField
                  {...params}
                  variant="outlined"
                  label={i18n.t('Components:formulaEditor.addVariable')}
                  className={classes.autocompleteInputTextField}
                />
                <Button
                  id="addVariable"
                  title={i18n.t('Components:formulaEditor.addVariable')}
                  variant="outlined"
                  disabled={!selectedVariable}
                  onClick={() => { if (selectedVariable) addVariable(selectedVariable); }}
                >
                  <AddIcon />
                </Button>
              </ButtonGroup>

            }
            onChange={onAddVariableChange}
            className={classes.controlBarAutocomplete}
          />
        }
        {
          showConstantVariablesInput &&
          <Autocomplete
            size="small"
            id="addConstant"
            autoComplete
            options={constantVariables.sort(sortVariablesAlphabetical)}
            getOptionLabel={option => option.label}
            value={selectedConstant}
            renderInput={params =>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
                className={classes.autocompleteInputButtonGroup}
              >
                <TextField
                  {...params}
                  variant="outlined"
                  label={i18n.t('Components:formulaEditor.addConstant')}
                  className={classes.autocompleteInputTextField}
                />
                <Button
                  id="addConstant"
                  title={i18n.t('Components:formulaEditor.addConstant')}
                  variant="outlined"
                  disabled={!selectedConstant}
                  onClick={() => { if (selectedConstant) addVariable(selectedConstant); }}
                >
                  <AddIcon />
                </Button>
              </ButtonGroup>

            }
            onChange={onAddKpiValueConstantChange}
            className={classes.controlBarAutocomplete}
          />
        }
        {
          operators && operators.length > 0 &&
          <Autocomplete
            size="small"
            id="addOperatorAutocomplete"
            autoComplete
            options={operators}
            getOptionLabel={option => option.label}
            value={selectedOperator}
            renderInput={params =>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
                className={classes.autocompleteInputButtonGroup}
              >
                <TextField
                  {...params}
                  variant="outlined"
                  label={i18n.t('Components:formulaEditor.addOperator')}
                  className={classes.autocompleteInputTextField}
                />
                <Button
                  id="addOperator"
                  title={i18n.t('Components:formulaEditor.addOperator')}
                  variant="outlined"
                  disabled={!selectedOperator}
                  onClick={onClickAddOperator}
                >
                  <AddIcon />
                </Button>
              </ButtonGroup>

            }
            onChange={onAddOperatorChange}
            className={classes.controlBarAutocomplete}
          />
        }
        {
          showNumberInput &&
          <NumberFormatField
            value={numberValue}
            fullWidth
            className={classes.controlBarNumberField}
            numberformatprops={
              {
                decimalScale: 3
              }
            }
            textfieldinputprops={
              {
                endAdornment:
                  <IconButton
                    id="addNumber"
                    onClick={onClickAddNumber}
                    disabled={!numberValue || isNaN(numberValue)}
                    title={i18n.t('Components:formulaEditor.addNumber')}
                  >
                    <AddIcon />
                  </IconButton>
              }
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNumberValue(parseFloat(event.target.value))}
            placeholder={i18n.t('Components:formulaEditor.number')}
          />
        }
        {
          showRemoveButton &&
          <Button
            size="medium"
            color="primary"
            variant="outlined"
            id="removeElementButton"
            startIcon={<DeleteIcon />}
            onClick={onClickRemoveElement}
            className={classes.controlBarButton}
            disabled={!selectedFormularElement}
          >
            {i18n.t('Components:formulaEditor.remove')}
          </Button>
        }
        {customComponents}
      </div>
      <div className={classes.rLDDContainer}>
        <RLDD
          cssClasses={classes.rLDDElement}
          layout="horizontal"
          items={formularElements}
          itemRenderer={formularElementRenderer}
          onChange={handleRLDDChange}
        />
      </div>
      <div className={classes.calculationFormularValidationDiv}>{calculationFormularValidation}</div>
      <OperatorCreatorDialog
        variables={variables.concat(constantVariables)}
        operatorToCreate={operatorToCreate}
        onOperatCreateCalled={onOperatCreateCalled}
      />
    </>
  )
}

export default FormularEditor;