import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autocompleteInputButtonGroup: {
      display: 'flex',
      marginTop: theme.spacing(1)
    },
    autocompleteInputTextField: {
      flexGrow: 1
    },
    controlBarAutocomplete: {
      width: "300px",
    },
    elementsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.spacing(1)
    },
    elementChip: {
      margin: theme.spacing(1)
    },
  }),
);

export default useStyles;