import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selfservice_logotype: {
      color: "white",
      marginLeft: theme.spacing(2.5),
      marginRight: theme.spacing(2.5),
      fontWeight: 500,
      fontSize: 18,
      whiteSpace: "nowrap",
      flexGrow: 1,
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    selfservice_appBar: {
      width: "100vw",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    selfservice_toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: theme.palette.header.main
    },
    selfservice_hide: {
      display: "none",
    },
    selfservice_grow: {
      flexGrow: 1,
    },
    selfservice_search: {
      position: "relative",
      borderRadius: 25,
      paddingLeft: theme.spacing(2.5),
      width: 36,
      backgroundColor: fade(theme.palette.common.black, 0),
      transition: theme.transitions.create(["background-color", "width"]),
      "&:hover": {
        cursor: "pointer",
        backgroundColor: fade(theme.palette.common.black, 0.08),
      },
    },
    selfservice_searchFocused: {
      backgroundColor: fade(theme.palette.common.black, 0.08),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: 250,
      },
    },
    selfservice_searchIcon: {
      width: 36,
      right: 0,
      height: "100%",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: theme.transitions.create("right"),
      "&:hover": {
        cursor: "pointer",
      },
    },
    selfservice_searchIconOpened: {
      right: theme.spacing(1.25),
    },
    selfservice_inputRoot: {
      color: "inherit",
      width: "100%",
    },
    selfservice_inputInput: {
      height: 36,
      padding: 0,
      paddingRight: 36 + theme.spacing(1.25),
      width: "100%",
    },
    selfservice_messageContent: {
      display: "flex",
      flexDirection: "column",
    },
    selfservice_headerMenu: {
      marginTop: theme.spacing(7),
    },
    selfservice_headerMenuList: {
      display: "flex",
      flexDirection: "column",
    },
    selfservice_headerMenuItem: {
      "&:hover, &:focus": {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
      },
    },
    selfservice_headerMenuButton: {
      marginLeft: theme.spacing(2),
      padding: theme.spacing(0.5),
    },
    selfservice_headerMenuButtonCollapse: {
      marginRight: theme.spacing(2),
    },
    selfservice_headerIcon: {
      fontSize: 28,
      color: "rgba(255, 255, 255, 0.35)",
    },
    selfservice_headerIconCollapse: {
      color: "white",
    },
    selfservice_profileMenu: {
      minWidth: 265,
    },
    selfservice_profileMenuUser: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
    },
    selfservice_profileMenuItem: {
      color: theme.palette.text.hint,
    },
    selfservice_profileMenuIcon: {
      marginRight: theme.spacing(2),
      color: theme.palette.text.hint,
    },
    selfservice_profileMenuLink: {
      fontSize: 16,
      textDecoration: "none",
      "&:hover": {
        cursor: "pointer",
      },
    },
    selfservice_messageNotification: {
      height: "auto",
      display: "flex",
      alignItems: "center",
      "&:hover, &:focus": {
        backgroundColor: theme.palette.background.paper,
      },
    },
    selfservice_messageNotificationSide: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginRight: theme.spacing(2),
    },
    selfservice_messageNotificationBodySide: {
      alignItems: "flex-start",
      marginRight: 0,
    },
    selfservice_sendMessageButton: {
      margin: theme.spacing(4),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      textTransform: "none",
    },
    selfservice_sendButtonIcon: {
      marginLeft: theme.spacing(2),
    },
    selfservice_loadingContainer: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    selfservice_formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }),
);

export default useStyles;