import { CallKeyFacts } from "../../domain/CallInfo/CallKeyFacts";
import { Contact } from "../../domain/CallInfo/Contact";
import { Lead } from "../../domain/CallInfo/Lead";
import { CallProtocol } from "../../domain/CallInfo/CallProtocol";
import { ReduxEnums } from "../../enums";
import { ActionTypes } from "../actions";

interface InitialStateType {
  callKeyFacts?: CallKeyFacts
  contacts?: Contact[]
  leads?: Lead[]
  callProtocols?: CallProtocol[]
}

const initialState: InitialStateType = {
  callKeyFacts: undefined,
};

export default function callInfoPage(
  state = initialState,
  action: ActionTypes
): InitialStateType {
  switch (action.type) {
    case ReduxEnums.CallInfoPageActionsEnum.setCallKeyFacts:
      return {
        ...state,
        callKeyFacts: action.payload
          ? Object.assign({}, action.payload)
          : undefined,
      };
    case ReduxEnums.CallInfoPageActionsEnum.setContacts:
      return {
        ...state,
        contacts: action.payload
          ? Object.assign([], action.payload)
          : undefined,
      };
    case ReduxEnums.CallInfoPageActionsEnum.setLeads:
      return {
        ...state,
        leads: action.payload ? Object.assign([], action.payload) : undefined,
      };
    case ReduxEnums.CallInfoPageActionsEnum.setCallProtocols:
      return {
        ...state,
        callProtocols: action.payload
          ? Object.assign([], action.payload)
          : undefined,
      };
    default:
      return state;
  }
}
