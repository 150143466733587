import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    upsertParameterDialog: {
      maxWidth: 'none'
    },
    redAvatar: {
      backgroundColor: theme.palette.error.main
    },
    greenAvatar: {
      backgroundColor: theme.palette.success.main
    },
    yellowAvatar: {
      backgroundColor: theme.palette.warning.main
    },
    iconSvg: {
      boxSizing: 'content-box',
      cursor: 'pointer',
      color: theme.palette.text.primary,
      borderRadius: theme.shape.borderRadius,
      transition: theme.transitions.create(['background-color', 'box-shadow'], {
        duration: theme.transitions.duration.shortest,
      }),
      fontSize: 40,
      padding: theme.spacing(2),
      margin: theme.spacing(0.5, 0),
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
      },
    },
    icon: {
      display: 'inline-block',
      width: 86,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'center',
      color: theme.palette.text.secondary,
      margin: '0 4px',
      fontSize: 12,
      '& p': {
        margin: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  })
);

export default useStyles;