import { Button, ButtonGroup, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import Diagram, { createSchema, useSchema } from "beautiful-react-diagrams";
import { DiagramSchema, Link, Node } from "beautiful-react-diagrams/@types/DiagramSchema";
import React, { FunctionComponent, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropTargetMonitor, useDrop } from "react-dnd";
// import { features } from "../mockups/Data";

import 'beautiful-react-diagrams/styles.css';
import '../../../../../../css/BankParameterStoryDiagram.css';
import { RootState } from "../../../../../../store/reduxStore";
import { FeatureDragDrop, FeaturesToolbar, ItemTypes } from "./components";
import { Feature, Story, StoryFeature, StoryFeatureConnection } from "../../../../../../domain/StoryFeature";
import { currentStorySchemaBuilder } from "../../../../../../services/Story/storySchemaBuilder";
import { isApiCallError } from "../../../../../../domain/Api/ApiError";
import { currentStoryApiService } from "../../../../../../services/Story/StoryApi";
import { BankParameterPageActions } from "../../../../../../store/actions";
import { currentFeatureApiService } from "../../../../../../services/FeatureApiService";
import i18n from "i18next";

export type UncontrolledDiagramProps = {
  schema: DiagramSchema<unknown>
  storyId: number
}

type CollectedDropProps = {
  canDrop: boolean
  isOver: boolean
}

const useStoryDesignerPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: '10px'
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

const BankParameterStoryDiagram: FunctionComponent<UncontrolledDiagramProps> = (props: UncontrolledDiagramProps) => {
  const classes = useStoryDesignerPageStyles();
  const dispatch = useDispatch();

  const deleteNodeFromSchema = (id?: string) => {
    if (id == null)
      return;

    const nodeToRemove = schema.nodes.find(node => node.id === id);
    if (!nodeToRemove)
      return;

    removeNode(nodeToRemove);
  }

  const currentSchema = props.schema;
  if (currentSchema.nodes) {
    currentSchema.nodes.forEach((node: any) => {
      if (node.data)
        node.data.onDeleteClick = deleteNodeFromSchema;
    })
  }
  const [schema, { onChange, addNode, removeNode }] = useSchema(createSchema<unknown>(currentSchema));//useSchema(props.schema)

  const moveToNext5 = (x: number): number => { return Math.ceil(x / 10) * 10 };

  const onDiagramChange = (schemaChanges: DiagramSchema<unknown>): void => {
    const newSchema = Object.assign({}, schemaChanges)
    if (newSchema.nodes && !newSchema.links && schema.nodes) {
      newSchema.nodes.forEach((nC: Node<unknown>) => {
        nC.coordinates = [moveToNext5(nC.coordinates[0]), moveToNext5(nC.coordinates[1])]//[moveToNext5(nC.coordinates[0]), moveToNext5(nC.coordinates[1])];
      })
      // if only nodes changes, then a node was moved
      // get node, which coordinates changed
      // let nodeChanged = schemaChanges.nodes.find((nC: Node<unknown>) => schema.nodes.find((nO: Node<unknown>) => nO.id === nC.id && (nO.coordinates[0] !== nC.coordinates[0] || nO.coordinates[1] !== nC.coordinates[1])));
      // if (nodeChanged) {
      // }
    }
    onChange(newSchema)
  }

  const setSelectedAddFeature = (feature: Feature | null) => {
    dispatch(BankParameterPageActions.setSelectedAddFeature(feature));
  }

  const setSelectFeatures = (features: Feature[]) => {
    dispatch(BankParameterPageActions.storeSelectFeatures(features));
  }

  const loadAddingFeatures = async () => {
    const features = await currentFeatureApiService.getFeatures();
    if (!features || isApiCallError(features))
      return;
    setSelectFeatures(features);
    if (features && features.length)
      setSelectedAddFeature(features[0])
  }
  const loadAddingFeaturesRef = useRef(loadAddingFeatures);


  const story = useSelector((state: RootState) => state.bankParameterPage.story);
  const setStory = (story: Story | null) => {
    dispatch(BankParameterPageActions.setStory(story));
  }
  const loadStory = async () => {
    const story = await currentStoryApiService.getStory(props.storyId);
    if (!story || isApiCallError(story))
      return;
    setStory(story);
  }
  const loadStoryRef = useRef(loadStory);


  const storyFeatures = useSelector((state: RootState) => state.bankParameterPage.parameterStoryFeatures);
  const setStoryFeatures = (storyFeatures: StoryFeature[]) => {
    dispatch(BankParameterPageActions.setParameterStoryFeatures(storyFeatures));
  }

  useEffect(() => {
    loadAddingFeaturesRef.current();
    loadStoryRef.current();
  }, []);

  const dropZoneRef = React.createRef<HTMLDivElement>();

  const [{ canDrop, isOver }, drop] = useDrop<FeatureDragDrop, void, CollectedDropProps>({
    accept: ItemTypes.FEATURE,
    drop: (item: FeatureDragDrop, monitor: DropTargetMonitor): void | undefined => {
      const { feature } = item;
      if (!feature)
        return;
      // console.log(monitor.cor)
      // drop.
      const monitorOffset = monitor.getClientOffset();

      if (dropZoneRef.current && monitorOffset) {
        monitorOffset.x -= dropZoneRef.current.offsetLeft;
        monitorOffset.y -= dropZoneRef.current.offsetTop;
      }

      if (monitorOffset) {
        monitorOffset.x = moveToNext5(monitorOffset.x);
        monitorOffset.y = moveToNext5(monitorOffset.y);
      }
      addNodeByFeature(feature, monitorOffset ? [monitorOffset.x, monitorOffset.y] : undefined);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  drop(dropZoneRef)
  const isActive = canDrop && isOver

  const addNodeByFeature = async (feature: Feature, coordinates?: number[]): Promise<void> => {
    if (!feature || !schema || !schema.nodes || !storyFeatures)
      return;

    let xCoordinate = 0;
    let yCoordinate = 0;
    const schemaNodesLength = schema.nodes.length;
    if (coordinates && coordinates.length === 2) {
      xCoordinate = coordinates[0];
      yCoordinate = coordinates[1];
    }
    else {
      xCoordinate = schemaNodesLength ? schema.nodes[schemaNodesLength - 1].coordinates[0] + 200 : 0;
      yCoordinate = schemaNodesLength ? schema.nodes[schemaNodesLength - 1].coordinates[1] : 0;
    }
    const createdDate: Date = new Date();

    const newStoryFeature: StoryFeature = {
      createdDate: createdDate,
      featureId: feature.id,
      id: (storyFeatures.length ? Math.min(Math.min(...storyFeatures.map((sF: StoryFeature) => sF.id)), 0) : 0) - 1,
      storyId: props.storyId,
      xcoordinate: xCoordinate,
      ycoordinate: yCoordinate,
      lastModifiedDate: createdDate,
      createdById: '',
      lastModifiedById: ''
    };

    const nextNodeResponse = await currentStorySchemaBuilder.getFeatureNodeDtoForStoryFeatureAndFeature(newStoryFeature, feature, schemaNodesLength === 0, false);
    if (nextNodeResponse && isApiCallError(nextNodeResponse))
      return;
    addNode(nextNodeResponse as Node<unknown>);
    //first updating story features and then adding node, will add node twice, don't know why
    setStoryFeatures(storyFeatures.concat([newStoryFeature]))
  }

  // const onAddClick = async () => {
  //   if (!selectedAddFeature || !schema || !schema.nodes)
  //     return;

  //   await addNodeByFeature(selectedAddFeature);
  // }

  const onSaveClick = async () => {
    if (!storyFeatures || !schema)
      return;

    const storyFeaturesForSaving: StoryFeature[] = [];
    if (schema.nodes.length) {
      schema.nodes.forEach((node: Node<unknown>) => {
        if (!node || !node.id)
          return;

        const currentStoryFeatureId = currentStorySchemaBuilder.getStoryFeatureIdForNodeId(node.id);
        if (currentStoryFeatureId == null) {
          return;
        }

        const currentStoryFeature = storyFeatures.find((sF: StoryFeature) => sF.id === currentStoryFeatureId);
        if (!currentStoryFeature) {
          return;
        }

        currentStoryFeature.xcoordinate = node.coordinates[0];
        currentStoryFeature.ycoordinate = node.coordinates[1];

        storyFeaturesForSaving.push(currentStoryFeature);

      })
    }

    const storyFeatureConnectionsForSaving: StoryFeatureConnection[] = [];
    if (schema.links?.length) {
      let sFCId = -1;
      schema.links.forEach((link: Link) => {
        if (!link || !link.input || !link.output) {
          return;
        }

        const triggeredStoryFeatureId = currentStorySchemaBuilder.getStoryFeatureIdForInputId(link.input);
        const trigger = currentStorySchemaBuilder.getStoryFeatureIdAndOutputIdForOutputId(link.output);
        if (triggeredStoryFeatureId == null || !trigger
          || !storyFeatures.find((sF: StoryFeature) => sF.id === triggeredStoryFeatureId)
          || !storyFeatures.find((sF: StoryFeature) => sF.id === trigger.storyFeatureId)) {
          return;
        }

        const currentDate = new Date();
        storyFeatureConnectionsForSaving.push({
          createdDate: currentDate,
          id: sFCId--,
          triggerOutputId: trigger.outputId,
          triggerStoryFeatureId: trigger.storyFeatureId,
          triggeredStoryFeatureId: triggeredStoryFeatureId,
          lastModifiedById: '',
          createdById: '',
          lastModifiedDate: currentDate
        })
      })
    }

    await currentStoryApiService.updateStoryFeaturesAndConnectionsForStory(props.storyId, storyFeaturesForSaving, storyFeatureConnectionsForSaving);
    if (story)
      await currentStoryApiService.updateStory(story);
  }

  let dropZoneHeightPx: number = dropZoneRef && dropZoneRef.current && dropZoneRef.current.style && dropZoneRef.current.style.height && !isNaN(parseFloat(dropZoneRef.current.style.height.replace('px', '')))
    ? parseFloat(dropZoneRef.current.style.height.replace('px', ''))
    : 500;
  if (schema && schema.nodes) {
    const maxHeight = Math.max(...schema.nodes.map((n: Node<unknown>) => n.coordinates[1] + 80 + (n.outputs?.length ?? 0) * 70));
    if (maxHeight > dropZoneHeightPx)
      dropZoneHeightPx = maxHeight + 40;
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={4} >
          <ButtonGroup
            color="default"
            aria-label="outlined default button group"
          >
            <Button variant="contained"
              type="button"
              onClick={onSaveClick}
            >
              {i18n.t("Components:storyDiagram.save")}
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <FeaturesToolbar />
        </Grid>
        <Grid item xs={10} >
          <div
            style={{
              height: `${dropZoneHeightPx}px`, resize: 'vertical', overflow: 'auto'
            }}
            ref={dropZoneRef}
            className={`${isActive ? 'dropZoneActive' : ''}`}
          >
            <Diagram
              schema={schema}
              onChange={onDiagramChange}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default BankParameterStoryDiagram;