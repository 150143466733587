import { CancelToken } from "axios";
import _ from "lodash";
import { ApiError } from "../domain/Api";
import { ODataOptions, ODataWrapper } from "../domain/Api/OData";
import { ParametrisationRule } from "../domain/Parametrisation";
import { TrafficLight } from "../enums/Parametrisation";
import { ApiService } from "./ApiService";

export class ParametrisationRuleApi extends ApiService {

  async getParametrisationRules(): Promise<ParametrisationRule[] | ApiError> {
    try {
      return await this.GetOData<ParametrisationRule>('ParametrisationRule', {}, []);
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async getSpecificParametrisationRules(oDataOptions: ODataOptions, cancelToken?: CancelToken): Promise<ODataWrapper<ParametrisationRule> | ApiError> {
    try {
      return await this.GetODataObjectsWithOptions<ParametrisationRule>('ParametrisationRule', oDataOptions, [], cancelToken);
    }
    catch (error) {
      return error as ApiError;
    }
  }

  async updateParametrisationRule(parametrisationRule: ParametrisationRule): Promise<ParametrisationRule | ApiError> {
    try {
      // materialtable adds prop tableData to objects and
      // odata delta inside patch method doesn't accept too much props and typescript can't create objects of generic types, so use this workaround :-| 
      const model: ParametrisationRule = {
        condition: '',
        displayName: '',
        hint: '',
        iconKey: '',
        id: 0,
        isDefaultRule: true,
        readableCondition: '',
        trafficLight: TrafficLight.green
      };

      const reducedParametrisationRule: ParametrisationRule = _.pick(parametrisationRule, _.keys(model)) as ParametrisationRule;
      return await this.OdataPatch<ParametrisationRule, ParametrisationRule>(parametrisationRule.id, 'ParametrisationRule', reducedParametrisationRule);
    }
    catch (error) {
      return error as ApiError;
    }
  }


  async getParametrisationRulesByIds(parametrisationRuleIds: number[]): Promise<ParametrisationRule[] | ApiError> {
    try {
      if (!parametrisationRuleIds?.length)
        return new Array<ParametrisationRule>();

      return await this.GetOData<ParametrisationRule>('ParametrisationRule', { filter: [{ expression: `Id in (${parametrisationRuleIds.join(',')})` }] }, []);
    }
    catch (error) {
      return error as ApiError;
    }
  }
}

export const currentParametrisationRuleApiService = new ParametrisationRuleApi();