export enum KpiSectionType {
  kpi = "Kpi",
  help = "Help"
}

export enum KpiIndicator {
  green = "Green",
  red = "Red"
}

export function getKpiIndicatorLabel(enumValue: KpiIndicator): string {
  switch (enumValue) {
    case KpiIndicator.green:
      return "Grün";
    case KpiIndicator.red:
      return "Rot";
  }
}

export enum KpiCheckStatus {
  pending = "pending",
  pending_risc = "pending_risc",
  risc_completed = "risc_completed",
  completed = "completed",
  failed = "failed"
}