import { TimelineDataStandard, TimelineTrack } from "../../components/Timeline/domain";
import { FormRequestTypes } from "../../components/Timeline/TimelineEnums";
import { currentTimelineHelpers } from "../../components/Timeline/TimelineHelpers";
import { ReduxEnums } from "../../enums"
import { ActionTypes } from "../actions"

const currentYear = new Date().getFullYear()
interface InitialStateType {
  timelineData: TimelineDataStandard[]
  timelineTracks: TimelineTrack[]
  yearsToLoad: number[]
}

const initialState: InitialStateType = {
  timelineData: [],
  timelineTracks: [],
  yearsToLoad: [currentYear]

};

export default function startPage(
  state = initialState,
  action: ActionTypes
): InitialStateType {
  switch (action.type) {

    case ReduxEnums.StartPageActionsEnum.updateYearsToLoad: {
      return {
        ...state,
        yearsToLoad: action.payload
      }
    }

    case ReduxEnums.StartPageActionsEnum.updateTimeline: {

      const { item, formEntry } = action.payload

      const cloneArray = new Array<TimelineDataStandard>().concat(state.timelineData)

      if (formEntry === FormRequestTypes.Edit) {
        const findIndex = cloneArray.findIndex(data => data.id === item.id)
        cloneArray[findIndex] =  item
        return {
          ...state,
          timelineData: cloneArray
        }
      }
      //Insert is default.
      const resultArray = cloneArray.concat(item)
      return {
        ...state,
        timelineData: resultArray
      }
    }

    case ReduxEnums.StartPageActionsEnum.pushDataToTimeline: {
      if (action.payload == null) {
        const emptyData = new Array<TimelineDataStandard>()
        const emptyTrack = new Array<TimelineTrack>()
        return {
          ...state,
          timelineData: emptyData,
          timelineTracks: emptyTrack
        }
      }
      const { data, tracks } = action.payload

      const rawData = [...state.timelineData, ...data]
      const uniqueData = currentTimelineHelpers.uniqueData(rawData)

      const rawTracks = [...state.timelineTracks, ...tracks]
      const uniqueTracks = currentTimelineHelpers.uniqueTracks(rawTracks)

      return {
        ...state,
        timelineData: uniqueData,
        timelineTracks: uniqueTracks
      }
    }
    default:
      return state;
  }
}