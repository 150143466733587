import React, { FunctionComponent } from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// components
import { Typography } from '../../components/MaterialUiAdmin/Wrappers'

export type PageTitleProps = {
  title?: string
  titleKey?: string
  buttonTitle?: string
}

const PageTitle: FunctionComponent<PageTitleProps> = ({title, titleKey, buttonTitle}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h1">
        {title ? title : titleKey ? t(titleKey) : ''}
      </Typography>
      {buttonTitle && (
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          size="large"
          color="secondary"
        >
          {buttonTitle}
        </Button>
      )}
    </div>
  );
}

export default PageTitle;