import React, { FunctionComponent } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { Button, FilledInputProps, InputProps, OutlinedInputProps, TextField, TextFieldProps } from '@material-ui/core';
import useStyles from './NumberFormatField.styles';
import classNames from 'classnames';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface NumberFormatCustomProps extends NumberFormatProps {
  inputRef: (instance: NumberFormat | null) => void
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  let { thousandSeparator, decimalSeparator, decimalScale } = props;
  const { inputRef, ...other } = props;
  const classes = useStyles();

  if (!thousandSeparator)
    thousandSeparator = '.';

  if (!decimalSeparator)
    decimalSeparator = ',';

  if (decimalScale == null)
    decimalScale = 2;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isNumericString
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      decimalScale={decimalScale}
      className={classNames(props.className, classes.textAlignRight)}
    />
  );
}

export type NumberFormatFieldProps = TextFieldProps &
{
  numberformatprops?: NumberFormatProps
  textfieldinputprops?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>
  min?: number
  max?: number
  textAlignment?: 'left' | 'right' | 'center'
  upDownButtons?: boolean
  buttonVariant?: 'text' | 'outlined' | 'contained'
  buttonColor?: 'default' | 'inherit' | 'primary' | 'secondary'
  step?: number
  value: number | null | undefined
  onChangeValue?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, value: number) => void
};

const getNumber = (value: string | undefined) => parseFloat(value?.replace(/\./g, '').replace(',', '.') ?? '0');

const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

const NumberFormatField: FunctionComponent<NumberFormatFieldProps> = (props) => {
  const { onChange, onChangeValue, numberformatprops: numberFormatProps, textfieldinputprops, min, max, textAlignment, upDownButtons, buttonVariant, buttonColor, step, value, disabled } = props;
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((!onChange && !onChangeValue) || props.disabled)
      return;

    if ((!event || !event.target || event.target.value == null) && onChange) {
      onChange(event);
      return;
    }

    let numberValue: number = getNumber(event.target.value);
    numberValue = clamp(numberValue, min ?? Number.MIN_SAFE_INTEGER, max ?? Number.MAX_SAFE_INTEGER);

    event.target.value = numberValue.toString();

    if (onChange)
      onChange(event);

    if (onChangeValue)
      onChangeValue(event, numberValue);
  };

  const increaseValue = () => {
    if ((!onChange && !onChangeValue) || disabled)
      return;

    let numberValue: number = value ?? 0;

    numberValue += step || 1;
    numberValue = clamp(numberValue, min ?? Number.MIN_VALUE, max ?? Number.MAX_VALUE);

    if (onChange)
      onChange({ target: { value: numberValue } } as unknown as React.ChangeEvent<HTMLInputElement>);
    if (onChangeValue)
      onChangeValue({ target: { value: numberValue } } as unknown as React.ChangeEvent<HTMLInputElement>, numberValue);
  };

  const decreaseValue = () => {
    if ((!onChange && !onChangeValue) || disabled)
      return;

    let numberValue: number = value ?? 0;

    numberValue -= step || 1;
    numberValue = clamp(numberValue, min ?? Number.MIN_VALUE, max ?? Number.MAX_VALUE);

    if (onChange)
      onChange({ target: { value: numberValue } } as unknown as React.ChangeEvent<HTMLInputElement>);
    if (onChangeValue)
      onChangeValue({ target: { value: numberValue } } as unknown as React.ChangeEvent<HTMLInputElement>, numberValue);
  };

  const drawTextField = () => {
    let className = "";
    if (textAlignment === 'left')
      className = props.className ? classNames(props.className, classes.textAlignLeft) : classes.textAlignLeft;
    else
      className = props.className ? classNames(props.className, classes.textAlignRight) : classes.textAlignRight;

    return (
      <TextField
        {...props}
        className={className}
        InputProps={{
          ...textfieldinputprops,
          inputComponent: NumberFormatCustom as any,
          inputProps: numberFormatProps,
        }}
        onChange={handleChange}
      />
    );
  };

  if (upDownButtons)
    return (
      <div className={classes.upDownButtonsRoot}>
        {drawTextField()}
        <div className={classes.upDownButtons}>
          <Button
            onClick={increaseValue}
            size="small"
            disabled={disabled}
            className={classes.upDownButton}
            color={buttonColor || "primary"}
            variant={buttonVariant || "text"}>
            <ExpandLessIcon fontSize='small' />
          </Button>
          <Button
            onClick={decreaseValue}
            size="small"
            disabled={disabled}
            className={classes.upDownButton}
            color={buttonColor || "primary"}
            variant={buttonVariant || "text"}>
            <ExpandMoreIcon fontSize='small' />
          </Button>
        </div>
      </div>
    );

  return drawTextField();
};

export default NumberFormatField;