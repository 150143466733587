import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(() => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  bankLogo: {
    maxHeight: 50,
    maxWidth: 200
  },
  toolbarTitle: {
    flexGrow: 1,
  },
}));
