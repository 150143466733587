import { Parameter } from "../domain/Parametrisation";

export enum ParameterType {
  totalAmount = 'totalAmount',
  postalCode = 'postalCode',
  legalForm = 'legalForm',
  crefo = 'crefo',
  foundingYear = 'foundingYear',
  turnoverClass = 'turnoverClass',
  financingProduct = 'financingProduct',
  minimumNumberOfEmployees = 'minimumNumberOfEmployees',
  excludedIndustry = 'excludedIndustry',
  term = 'term'
}

export enum TrafficLight {
  green = "green",
  yellow = "yellow",
  red = "red"
}

export function getTrafficLightColorLabel(trafficLight: TrafficLight): string {
  switch (trafficLight) {
    case TrafficLight.green: return 'Grün';
    case TrafficLight.yellow: return 'Gelb';
    case TrafficLight.red: return 'Rot';
    default: return '';
  }
}

export enum ParametrisationConditionOperator {
  greater = "greater",
  greaterEqual = "greaterEqual",
  less = "less",
  lessEqual = "lessEqual",
  in = "in",
  notIn = "notIn"
}

export function getParametrisationConditionOperatorLabel(parametrisationConditionOperator: ParametrisationConditionOperator): string {
  switch (parametrisationConditionOperator) {
    case ParametrisationConditionOperator.greater: return '>';
    case ParametrisationConditionOperator.greaterEqual: return '>=';
    case ParametrisationConditionOperator.less: return '<';
    case ParametrisationConditionOperator.lessEqual: return '<=';
    case ParametrisationConditionOperator.in: return 'Enthalten in';
    case ParametrisationConditionOperator.notIn: return 'Nicht enthalten in';
    default: return '';
  }
}

// returns all operators, which couldn't be used for parameter type
export function getExcludedParametrisationConditionOperatorForParameter(parameter: Parameter): ParametrisationConditionOperator[] {
  // operators, which can't have mathematical comparisions
  if (!isNumericParameter(parameter))
    return [ParametrisationConditionOperator.greater, ParametrisationConditionOperator.greaterEqual,
      ParametrisationConditionOperator.less, ParametrisationConditionOperator.lessEqual];

  // parameters, which can have all
  return [];
}

// operators, which can't have mathematical comparisions
export function isNumericParameter(parameter: Parameter): boolean {
  switch (parameter.type) {
    // numeric parameters
    case ParameterType.totalAmount:
    case ParameterType.minimumNumberOfEmployees:
    case ParameterType.foundingYear:
    case ParameterType.crefo:
      return true;

    // not numeric parameters
    case ParameterType.excludedIndustry:
    case ParameterType.financingProduct:
    case ParameterType.legalForm:
    case ParameterType.postalCode:
    case ParameterType.term:
    case ParameterType.turnoverClass:
    default:
      return false;
  }
}

export enum UpsertParametrisationRuleDialogType {
  add,
  edit
}