import { Card, CardContent, CardHeader, IconButton, Menu, MenuItem } from "@material-ui/core";
import React, { useState, ElementType, ReactNode } from "react";
import { FeaturePort } from "../FeaturePort";
import useStyles from './FeatureNode.styles'
import { Feature, StoryFeature } from "../../../domain/StoryFeature";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSelector } from "react-redux";
import { RootState } from "../../../store/reduxStore";
import classNames from "classnames";
import { FeatureNodeMetadata } from "../../../domain/BankParameterPage";
import { Node } from 'beautiful-react-diagrams/@types/DiagramSchema';
import { FeatureType } from "../../../enums/Feature";
import { useHistory } from 'react-router-dom';
import * as H from 'history';
import { RoutesConstants } from "../../../constants";
import { Bank, BankVisibilityParameterSets } from "../../../domain/Bank";
import { useTranslation } from "react-i18next";

export type FeatureNodeData = {
  storyFeature: StoryFeature
  feature: Feature
  onDeleteClick: (id?: string) => void
  storyFeatureSaved: boolean
}

const FeatureNode = (props: Omit<Node<unknown>, 'coordinates'>): ElementType | ReactNode => {
  const classes = useStyles();
  const history: H.History<H.LocationState> = useHistory();

  const selectedBank: Bank | undefined = useSelector((state: RootState) => state.bankParameterPage.selectedBank);
  const selectedParameter: BankVisibilityParameterSets | undefined = useSelector((state: RootState) => state.bankParameterPage.selectedParameter);
  const featureNodeToEdit: FeatureNodeMetadata | null = useSelector((state: RootState) => state.bankParameterPage.featureNodeToEdit);
  const { t } = useTranslation("Components");

  const [anchorFeatureActionMenuEl, setAnchorFeatureActionMenuEl] = useState<null | HTMLElement>(null);

  const handleFeatureActionMenuOpenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorFeatureActionMenuEl(event.currentTarget);
  };

  const handleFeatureActionMenuClose = () => {
    setAnchorFeatureActionMenuEl(null);
  };

  const { id, inputs, outputs, data } = props;
  const parsedData = data as FeatureNodeData;

  let feature: Feature | null = null;
  if (parsedData.feature) {
    feature = parsedData.feature as Feature;
  }
  else {
    feature = data as Feature
  }

  const storyFeature = parsedData.storyFeature as StoryFeature;


  const handleEditStoryFeatureClicked = () => {
    if (!feature || !selectedBank || !selectedParameter || !storyFeature)
      return;

    if (feature.type === FeatureType.None)
      return;

    history.push(`${RoutesConstants.routePath.banksPage}/${selectedBank.id}/${selectedParameter.id}/${storyFeature.id}/${feature.type}`);
  }

  const { name } = feature;
  return (
    <Card className={classNames(classes.root, {
      [classes.storyFeatureEdited]: storyFeature && featureNodeToEdit && featureNodeToEdit.storyFeature && storyFeature.id === featureNodeToEdit.storyFeature.id
    })
    }>
      <CardHeader
        action={
          <>
            <IconButton aria-label="settings" onClick={handleFeatureActionMenuOpenClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="featureActionMenu"
              anchorEl={anchorFeatureActionMenuEl}
              keepMounted
              open={Boolean(anchorFeatureActionMenuEl)}
              onClose={handleFeatureActionMenuClose}
            >
              <MenuItem
                onClick={() => { handleEditStoryFeatureClicked(); handleFeatureActionMenuClose(); }}
                disabled={!parsedData.storyFeatureSaved}
                title={parsedData.storyFeatureSaved ? t('Components:featureNode.editFeature') : t('Components:featureNode.cantEdit')}
              >
                {t('Components:featureNode.edit')}
              </MenuItem>
              {parsedData.onDeleteClick && <MenuItem onClick={() => { if (parsedData.onDeleteClick) { parsedData.onDeleteClick(id); } handleFeatureActionMenuClose(); }}>{t('Components:featureNode.delete')}</MenuItem>}
            </Menu>
          </>
        }
        title={name ?? t('Components:featureNode.unknown')}
      />
      <CardContent>
        <div className="bi-port-wrapper" >
          {
            inputs &&
            <div className="bi-input-ports">
              {inputs.map((port: any, index: number) =>
                <FeaturePort
                  key={index}
                  portElement={port}
                />
              )}
            </div>
          }
          {
            outputs &&
            <div className="bi-output-ports" style={{ textAlign: 'right' }}>
              {outputs.map((port: any, index: number) =>
                <FeaturePort
                  key={index}
                  portElement={port}
                />
              )}
            </div>
          }
        </div>
      </CardContent>
    </Card>
  )
};

export default FeatureNode;