import { getUserRoles } from "../auth/authProvider";
import { DashboardEnums } from '../enums/index';

export enum AppRoleTypes {
  sales,
  salesAdmin,
  fulfillment,
  finance,
  admin,
  connect,
  beta,
  customerSuccess,
  marketing,
  productUnit
}

export function getUserRoleEnumCase(userRoleString: string): AppRoleTypes | undefined {
  if (!userRoleString)
    return;

  switch (userRoleString) {
    case window.REACT_APP_CONFIGURATION_AZURE_APPROLES.sales:
      return AppRoleTypes.sales;
    case window.REACT_APP_CONFIGURATION_AZURE_APPROLES.salesAdmin:
      return AppRoleTypes.salesAdmin;
    case window.REACT_APP_CONFIGURATION_AZURE_APPROLES.fulfillment:
      return AppRoleTypes.fulfillment;
    case window.REACT_APP_CONFIGURATION_AZURE_APPROLES.finance:
      return AppRoleTypes.finance;
    case window.REACT_APP_CONFIGURATION_AZURE_APPROLES.admin:
      return AppRoleTypes.admin;
    case window.REACT_APP_CONFIGURATION_AZURE_APPROLES.connect:
      return AppRoleTypes.connect;
    case window.REACT_APP_CONFIGURATION_AZURE_APPROLES.beta:
      return AppRoleTypes.beta;
    case window.REACT_APP_CONFIGURATION_AZURE_APPROLES.customerSuccess:
      return AppRoleTypes.customerSuccess;
    case window.REACT_APP_CONFIGURATION_AZURE_APPROLES.marketing:
      return AppRoleTypes.marketing;
    case window.REACT_APP_CONFIGURATION_AZURE_APPROLES.productUnit:
      return AppRoleTypes.productUnit;
  }
}

/**
 * Function checks if there is at least one userRole in allowedAppRoleTypes.
 * @param allowedAppRoleTypes roles, which are allowed
 * @param userRoles roles, which the user has
 * @returns boolean
 */
export function checkAllowedAppRoleTypesInUserRoles(allowedAppRoleTypes: AppRoleTypes[] | undefined, userRoles: AppRoleTypes[]): boolean {

  if (!allowedAppRoleTypes || !allowedAppRoleTypes.length)
    return true;

  return allowedAppRoleTypes.some(role => userRoles.some(userRole => role === userRole));
}

/**
 * Function checks if there is at least one userRole from the current User in allowedAppRoleTypes.
 * @param allowedAppRoleTypes roles, which are allowed
 * @returns boolean
 */
export function checkAllowedAppRoleTypesInCurrentUserRoles(allowedAppRoleTypes: AppRoleTypes[] | undefined): boolean {

  if (!allowedAppRoleTypes || !allowedAppRoleTypes.length)
    return true;

  const userRoles = getUserRoles();

  return checkAllowedAppRoleTypesInUserRoles(allowedAppRoleTypes, userRoles);
}

/**
 * Function checks if current user has allowed roles for widgets of dashboard
 * @param widgetMap 
 * @param userRoles 
 * @returns Array of type DashboardEnums.WidgetKeys with all allowed Widget keys
 */
export function checkCurrentUserRoleForWidgets(widgetMap: Map<DashboardEnums.WidgetKeys, AppRoleTypes[]>, userRoles: AppRoleTypes[]): DashboardEnums.WidgetKeys[] {

  return Array.from( widgetMap.keys()).filter((widgetKey: DashboardEnums.WidgetKeys) => checkAllowedAppRoleTypesInUserRoles(widgetMap.get(widgetKey), userRoles));
}
