import { Bank, BankVisibilityParameterSets } from '../../../domain/Bank'
import { KpiCheck, KpiSection } from '../../../domain/Kpi'
import { Tender } from '../../../domain/Marketplace'
import { ReduxEnums } from '../../../enums'
import { KpiCheckPageActionTypes } from './types'

export const setSelectedBank = (bank: Bank | undefined): KpiCheckPageActionTypes => ({
  type: ReduxEnums.KpiCheckPageActionsEnum.setSelectedBank,
  payload: bank
})

export const setSelectedParameter = (parameter?: BankVisibilityParameterSets): KpiCheckPageActionTypes => ({
  type: ReduxEnums.KpiCheckPageActionsEnum.setSelectedParameter,
  payload: parameter
})

export const setSelectedTender = (tender?: Tender): KpiCheckPageActionTypes => ({
  type: ReduxEnums.KpiCheckPageActionsEnum.setSelectedTender,
  payload: tender
})

export const setKpiCheck = (kpiCheck?: KpiCheck): KpiCheckPageActionTypes => ({
  type: ReduxEnums.KpiCheckPageActionsEnum.setKpiCheck,
  payload: kpiCheck
})

export const setKpiSections = (kpiSections: KpiSection[]): KpiCheckPageActionTypes => ({
  type: ReduxEnums.KpiCheckPageActionsEnum.setKpiSections,
  payload: kpiSections
})

export const changeKpiValueCalculationCounter = (increment: number): KpiCheckPageActionTypes => ({
  type: ReduxEnums.KpiCheckPageActionsEnum.changeKpiValueCalculationCounter,
  payload: increment
})

export const setCompleteKpisValidation = (completeKpisValidation: string): KpiCheckPageActionTypes => ({
  type: ReduxEnums.KpiCheckPageActionsEnum.setCompleteKpisValidation,
  payload: completeKpisValidation
})