export interface AxiosRequestRejectObj {
  message?: string
  name?: string
}

export default class ApiError {
  identificationType = "ImAnError";

  constructor(public message?: string, public axiosRequestRejectObj?: AxiosRequestRejectObj) {
  }

  getMessage(): string {
    let returnValue = "";
    const { message, axiosRequestRejectObj } = this;

    if (message)
      returnValue += message;

    if (axiosRequestRejectObj) {
      const { message: axiosMessage, name } = axiosRequestRejectObj;

      if (name)
        returnValue += (returnValue === "" ? "" : " ") + name;

      if (axiosMessage)
        returnValue += (returnValue === "" ? "" : " ") + axiosMessage;
    }

    return returnValue;
  }
}

export function isApiCallError<T>(response: T | ApiError): response is ApiError {
  return (response as ApiError).identificationType === "ImAnError";
}