import React, { FunctionComponent, } from "react";
import { Route, Switch } from 'react-router-dom';
import { RoutesConstants } from "../../constants";
import BanksPageHeaderInfo from "../../pages/Banks/BanksPageHeaderInfo";
import { BankParameterPageHeaderInfo } from "../../pages/BankParameter";
import { ParametrisationFeatureConfigurationPageHeaderInfo } from "../../pages/ParametrisationFeatureConfiguration";
import i18n from "i18next";
import Typography from "@material-ui/core/Typography";

const HeaderInfo: FunctionComponent = () => {

  return (
    <Switch>
      <Route path={`${RoutesConstants.routePath.banksPage}/:bankId?`} exact={true}>
        <BanksPageHeaderInfo />
      </Route>
      <Route path={`${RoutesConstants.routePath.bankParameterPage}`} exact={true}>
        <BankParameterPageHeaderInfo />
      </Route>
      <Route path={`${RoutesConstants.routePath.parametrisationFeatureConfigurationPage}`} exact={true}>
        <ParametrisationFeatureConfigurationPageHeaderInfo />
      </Route>
      <Route path="*">
        <Typography
        >
          {i18n.t('HeaderInfo:selfService')}
        </Typography>
      </Route>
    </Switch>
  )
}

export default HeaderInfo;
