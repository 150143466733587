import { DiagramSchema } from "beautiful-react-diagrams/@types/DiagramSchema";
import { Dispatch } from "react";
import { Bank, BankVisibilityParameterSets } from "../../domain/Bank";
import { Story, StoryFeature } from "../../domain/StoryFeature";
import { currentStorySchemaBuilder } from "../../services/Story/storySchemaBuilder";
import { setParameterDiagramSchema, setParameterStoryFeatures, setSelectedBank, setSelectedParameter, setStory } from "../../store/actions/bankParameterPage/actions";
import { toggleIsPageLoading } from "../../store/actions/uiActions/actions";

const selectParameterDiagramSchema = (diagramSchema: DiagramSchema<unknown> | null, dispatch: Dispatch<any>) => {
  dispatch(setParameterDiagramSchema(diagramSchema));
}
const selectParameterStoryFeatures = (storyFeatures: StoryFeature[] | null, dispatch: Dispatch<any>) => {
  dispatch(setParameterStoryFeatures(storyFeatures));
}
const selectStory = (story: Story | null, dispatch: Dispatch<any>) => {
  dispatch(setStory(story));
}
const setIsPageLoading = (isLoading: boolean, dispatch: Dispatch<any>) => {
  dispatch(toggleIsPageLoading(isLoading));
}
const selectBankParameter = (parameter: BankVisibilityParameterSets | undefined, dispatch: Dispatch<any>) => {
  dispatch(setSelectedParameter(parameter));
}
const selectBank = (bank: Bank | undefined, dispatch: Dispatch<any>) => {
  dispatch(setSelectedBank(bank));
}

export async function loadStoryForBankParameter(
  parameter: BankVisibilityParameterSets,
  dispatch: Dispatch<any>
): Promise<void> {
  const diagramm = await currentStorySchemaBuilder.getDiagrammSchemaForBankParameterSetsId(parameter.id);
  if (diagramm?.schema && diagramm?.storyFeatures && diagramm?.story) {
    selectParameterDiagramSchema(diagramm.schema, dispatch);
    selectParameterStoryFeatures(diagramm.storyFeatures, dispatch);
    selectStory(diagramm.story, dispatch);
  }

  setIsPageLoading(false, dispatch);
}

export function resetStoryForBankParameter(dispatch: Dispatch<any>): void {
  selectParameterDiagramSchema(null, dispatch);
  selectParameterStoryFeatures(null, dispatch);
  selectStory(null, dispatch);
  selectBankParameter(undefined, dispatch);
  selectBank(undefined, dispatch);
}