import React, { FunctionComponent } from "react";
import useStyles from './NoBankParameterStoryAvailable.styles';
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { BankVisibilityParameterSets } from "../../../../../../domain/Bank";
import { RootState } from "../../../../../../store/reduxStore";
import { currentBankParameterStoryService } from "../../../../../../services/BankParameterStoryService";
import { toggleIsPageLoading } from "../../../../../../store/actions/uiActions/actions";
import { isApiCallError } from "../../../../../../domain/Api/ApiError";
import { loadStoryForBankParameter } from "../../../../BankParameterPageHelperFunctions";
import { useTranslation } from "react-i18next";

const NoBankParameterStoryAvailable: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('BankParameter');

  const selectedParameter: BankVisibilityParameterSets | undefined = useSelector((state: RootState) => state.bankParameterPage.selectedParameter);

  const setIsPageLoading = (isLoading: boolean) => {
    dispatch(toggleIsPageLoading(isLoading));
  }

  const onCreateParameterConfigurationClick = async () => {
    if (!selectedParameter)
      return;

    setIsPageLoading(true);
    const response = await currentBankParameterStoryService.crateBankParameterStory(selectedParameter.id);
    if (response && isApiCallError(response)) {
      setIsPageLoading(false);
      return;
    }
    await loadStoryForBankParameter(selectedParameter, dispatch);
  }

  return (
    <div className={classes.noSelectedBankDiv} >
      <h1>{t('BankParameter:noConfig')}</h1>
      {
        selectedParameter &&
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={onCreateParameterConfigurationClick}
          className={classes.createConfigurationButton}
        >
          {t('BankParameter:createConfig')}
        </Button>
      }
    </div>
  );
};

export default NoBankParameterStoryAvailable;

