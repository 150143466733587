import { getTrafficLightColorLabel, TrafficLight } from "../../enums/Parametrisation";

type TrafficLightSelectOption = {
  label: string
  value: TrafficLight
}
export default TrafficLightSelectOption;

export function getAllTrafficLightObjects(): TrafficLightSelectOption[] {
  return Object.values(TrafficLight)
    .filter((value: string | TrafficLight) => (value as TrafficLight) != null)
    .map((value: string | TrafficLight) => {
      const tLCValue: TrafficLight = value as TrafficLight;
      const option: TrafficLightSelectOption = {
        label: getTrafficLightColorLabel(tLCValue),
        value: tLCValue
      };
      return option;
    });
}