import React, { ChangeEvent, FunctionComponent, useRef, useState } from "react";
import { InOperatorFormularElementMetaData, Operator, sortVariablesAlphabetical, Variable } from "../../../../../../domain/FormularEditor";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { Autocomplete } from "@material-ui/lab";
import { ButtonGroup, Chip, DialogContentText, FormHelperText, TextField } from "@material-ui/core";
import useStyles from "./OperatorNotInDialog.styles";
import AddIcon from '@material-ui/icons/Add';
import { FormularEditorConstants } from "../../../../../../constants";
import i18n from "i18next";

/*example for dialogClasses
        dialogClasses={{
            paper: classes.addOperatorInCreateDialog,
        }}
*/
export type OperatorNotInDialogProps = {
  dialogClasses?: Record<'paper', string>
  open: boolean
  onClose: (operator?: Operator) => void
  operator: Operator
  variables: Variable[]
}

const OperatorNotInDialog: FunctionComponent<OperatorNotInDialogProps> = ({ dialogClasses, open, onClose, operator: propOperator, variables }) => {
  const classes = useStyles();
  const [selectedElementToCheck, setSelectedElementToCheck] = useState<Variable | string | null>(null);
  const [selectedElementAddToElements, setSelectedElementAddToElements] = useState<Variable | string | null>(null);
  const [elements, setElements] = useState<(string | Variable)[]>([]);

  const elementToCheckAutocomplete = useRef<HTMLElement>(null);

  const operatorIsValid: boolean = selectedElementToCheck != null && elements != null && elements.length > 0;

  const clearInputs = () => {
    setSelectedElementToCheck(null);
    setSelectedElementAddToElements(null);
    setElements(new Array<string | Variable>());
  }

  const handleCancel = () => {
    onClose();
    clearInputs();
  };

  const handleOk = () => {
    if (selectedElementToCheck == null || elements == null || !elements.length)
      return;

    const newOperator = Object.assign({}, propOperator);
    const newMetaData: InOperatorFormularElementMetaData = {
      elementToCheck: selectedElementToCheck,
      elements
    };
    newOperator.metaData = newMetaData;

    const labelFunction = FormularEditorConstants.operatorLabelMap.get(newOperator.operatorType)
    if (labelFunction)
      newOperator.label = labelFunction(newOperator.metaData);

    onClose(newOperator);
    clearInputs();
  };

  const onElementToCheckChange = (_event: ChangeEvent<unknown>, newVariable: Variable | string | null) => {
    setSelectedElementToCheck(newVariable);
  }

  const addElementToElements = (element: string | Variable) => {
    setElements(elements.concat(element));
  }

  const onElementAddToElementsChange = (addVaraiable: boolean) => (_event: ChangeEvent<unknown>, newVariable: Variable | string | null) => {
    setSelectedElementAddToElements(newVariable);

    if (addVaraiable && newVariable)
      addElementToElements(newVariable);
  }

  const onElementDeleteClick = (elementToDeleteIndex: number) => (): void => {
    setElements(elements.filter((el: string | Variable, index: number) => index !== elementToDeleteIndex));
  }

  const handleEntering = (): void => {
    if (elementToCheckAutocomplete.current != null) {
      elementToCheckAutocomplete.current.focus();
    }
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="add-operator-in-dialog-title"
      open={open}
      classes={dialogClasses}
      onEntering={handleEntering}
    >
      <DialogTitle id="add-operator-in-dialog-title">{i18n.t('Components:operatorDialog.notInOperator')}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="not-in-operator-description">
          {i18n.t('Components:operatorDialog.notInDescription')}
        </DialogContentText>
        <Autocomplete
          size="small"
          id="addElementToCheckAutocomplete"
          ref={elementToCheckAutocomplete}
          autoComplete
          options={variables.sort(sortVariablesAlphabetical)}
          getOptionLabel={(option: Variable | string) => (option as Variable)?.label ?? option.toString()}
          value={selectedElementToCheck}
          freeSolo
          renderInput={params =>
            <TextField
              {...params}
              variant="outlined"
              label={i18n.t('Components:operatorDialog.toBeChecked')}
              helperText={selectedElementToCheck != null ? "" : `${i18n.t('Components:operatorDialog.valueRequired')}`}
              error={selectedElementToCheck == null}
            />

          }
          onChange={onElementToCheckChange}
          onInputChange={onElementToCheckChange}
          className={classes.controlBarAutocomplete}
          autoSelect={true}
        />
        <ButtonGroup
          color="primary"
          aria-label="outlined primary button group"
          className={classes.autocompleteInputButtonGroup}
        >
          <Autocomplete
            size="small"
            id="addElementsAutocomplete"
            autoComplete
            options={variables.sort(sortVariablesAlphabetical)}
            getOptionLabel={(option: Variable | string) => (option as Variable)?.label ?? option.toString()}
            freeSolo
            value={selectedElementAddToElements}
            renderInput={params =>
              <TextField
                {...params}
                variant="outlined"
                label={i18n.t('Components:operatorDialog.addElement')}
                className={classes.autocompleteInputTextField}
                error={elements.length < 1}
              />

            }
            onChange={onElementAddToElementsChange(true)}
            onInputChange={onElementAddToElementsChange(false)}
            className={classes.controlBarAutocomplete}
          />
          <Button
            id="addElement"
            title={i18n.t('Components:operatorDialog.addElement')}
            variant="outlined"
            disabled={!selectedElementAddToElements}
            onClick={() => { if (selectedElementAddToElements) addElementToElements(selectedElementAddToElements); }}
          >
            <AddIcon />
          </Button>
        </ButtonGroup>
        <div className={classes.elementsContainer}>
          {
            elements.map((item: string | Variable, index: number) =>
              <Chip
                label={(item as Variable)?.label ?? item}
                onDelete={onElementDeleteClick(index)}
                className={classes.elementChip}
                key={index}
              />)
          }
        </div>
        {elements.length < 1 && <FormHelperText error>{i18n.t('Components:operatorDialog.requiredEl')}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="secondary">
          {i18n.t('Components:operatorDialog.cancel')}
        </Button>
        <Button
          onClick={handleOk}
          color="primary"
          disabled={!operatorIsValid}
        >
          {i18n.t('Components:operatorDialog.add')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OperatorNotInDialog;