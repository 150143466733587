import React, { useState, FunctionComponent, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getTheme } from '../themes';
import Layout from "./components/Layout";
import { redirectRequest, publicClientApplication } from "../auth/authProvider";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { InteractionType, EventMessage } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { useDispatch } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';

const App: FunctionComponent = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkState, setDarkState] = useState<boolean>(prefersDarkMode);
  const theme = getTheme(darkState);
  const dispatch = useDispatch();

  //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
  useEffect(() => {
    publicClientApplication.enableAccountStorageEvents();
    const callbackId = publicClientApplication.addEventCallback((message: EventMessage) => {
      dispatch({ type: message.eventType, payload: message.payload });
    });

    return () => {
      publicClientApplication.disableAccountStorageEvents();
      if (callbackId) {
        publicClientApplication.removeEventCallback(callbackId);
      }
    }
  }, []);

  useEffect(() => {
    setDarkState(prefersDarkMode);
  }, [prefersDarkMode]);

  return (
    <React.StrictMode>
      <MsalProvider instance={publicClientApplication}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={redirectRequest}>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                TransitionComponent={Slide as React.ComponentType}
              >
                <Layout
                  darkState={darkState}
                  setDarkState={setDarkState}
                />
              </SnackbarProvider>
            </MsalAuthenticationTemplate>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MsalProvider>
    </React.StrictMode >
  );
}

export default App;