import { combineReducers } from "redux";
import authentication from "./authentication";
import ui from "./ui";
import banksPage from "./banksPage";
import bankParameterPage from "./bankParameterPage";
import parametrisationFeatureConfigurationPage from "./parametrisationFeatureConfigurationPage";
import creditProcessPage from "./creditProcessPage";
import kpiCheckPage from "./kpiCheckPage";
import callInfoPage from "./callInfoPage";
import datacenterPage from "./datacenterPage";
import startPage from './startPage'

export interface PayloadAction<T> {
  type: string
  payload: T
}

export default combineReducers({
  authentication,
  ui,
  banksPage,
  bankParameterPage,
  parametrisationFeatureConfigurationPage,
  creditProcessPage,
  kpiCheckPage,
  callInfoPage,
  datacenterPage,
  startPage
});
