import { Bank, BankVisibilityParameterSets } from '../../../domain/Bank'
import { ReduxEnums } from '../../../enums'
import { BankPageActionTypes } from './types'

export const setSelectedBank = (bank?: Bank): BankPageActionTypes => ({
  type: ReduxEnums.BanksPageActionsEnum.setSelectedBank,
  payload: bank
})

export const setSelectedParameter = (parameter?: BankVisibilityParameterSets): BankPageActionTypes => ({
  type: ReduxEnums.BanksPageActionsEnum.setSelectedParameter,
  payload: parameter
})