import React, { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import useStyles from "./UpsertParametrisationRuleDialog.styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/reduxStore";
import { ParametrisationFeatureConfigurationPageActions } from "../../../../store/actions";
import { getAllTrafficLightObjects, Parameter, ParametrisationRule, TrafficLightSelectOption } from "../../../../domain/Parametrisation";
import { TrafficLight, UpsertParametrisationRuleDialogType } from "../../../../enums/Parametrisation";
import { DialogContent, Grid, TextField } from "@material-ui/core";
import { Variable } from "../../../../domain/FormularEditor";
import { SelectOption } from "../../../../domain/UI";
import { ParameterConstants } from "../../../../constants";
import { parameterData } from "../../../../demoData/parametrisation/parameter";
import { AvatarMaterialUiIconPicker } from "../../../../components/AvatarMaterialUiIconPicker";
import classNames from "classnames";
import { TextParameterCreator } from "../../../../components/TextParameterCreator";
import { Autocomplete } from "@material-ui/lab";
import { FormularEditor } from "../../../../components/FormularEditor";
import { currentParametrisationFeatureApiService } from "../../../../services/ParametrisationFeatureApi";
import { StoryFeature } from "../../../../domain/StoryFeature";
import { currentParametrisationRuleApiService } from "../../../../services/ParametrisationRuleApi";
import { useTranslation } from 'react-i18next';

export type UpsertParametrisationRuleDialogProps = {
  dialogClasses: Record<'paper', string>
  keepMounted: boolean
}

const UpsertParametrisationRuleDialog: FunctionComponent<UpsertParametrisationRuleDialogProps> = ({ dialogClasses, keepMounted }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ruleNameTxtRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('ParametrisationFeatureConfig');

  const selectedStoryFeature: StoryFeature | undefined = useSelector((state: RootState) => state.parametrisationFeatureConfigurationPage.selectedStoryFeature);

  const showCreateNewParametrisationRuleDialog: boolean = useSelector((state: RootState) => state.parametrisationFeatureConfigurationPage.showUpsertParametrisationRuleDialog);
  const setShowCreateNewParametrisationRuleDialog = (showDialog: boolean) => {
    dispatch(ParametrisationFeatureConfigurationPageActions.setShowUpsertParametrisationRuleDialog(showDialog));
  }

  const setParametrisationConnectedParametrisationRulesReload = (reloadDate?: Date) => {
    dispatch(ParametrisationFeatureConfigurationPageActions.setParametrisationConnectedParametrisationRulesReload(reloadDate));
  };

  const [upsertType, setUpsertType] = useState<UpsertParametrisationRuleDialogType>(UpsertParametrisationRuleDialogType.add);
  const parametrisationRuleToEdit: ParametrisationRule | undefined = useSelector((state: RootState) => state.parametrisationFeatureConfigurationPage.parametrisationRuleToEdit);

  const setParametrisationRuleToEdit = (parametrisationRuleToEdit?: ParametrisationRule) => {
    dispatch(ParametrisationFeatureConfigurationPageActions.setParametrisationRuleToEdit(parametrisationRuleToEdit));
  }

  const [parametrisationRule, setParametrisationRule] = useState<Partial<ParametrisationRule>>({});

  const [trafficLightsColors, setTrafficLightsColors] = useState<TrafficLightSelectOption[]>([]);
  const selectedTraficLight: TrafficLightSelectOption | null = trafficLightsColors.find((option: TrafficLightSelectOption) => option.value === parametrisationRule.trafficLight) ?? null;
  const [formularEditorVariables, setFormularEditorVariables] = useState<Variable[]>([]);
  const [formularEditorConstants, setFormularEditorConstants] = useState<Variable[]>([]);
  const [parameterEntries, setParameterEntries] = useState<SelectOption<string>[]>([]);

  const ruleIsValid: boolean = parametrisationRule.iconKey != null && parametrisationRule.trafficLight != null
    && parametrisationRule.condition != null && parametrisationRule.condition !== ''
    && parametrisationRule.hint != null && parametrisationRule.hint !== '' && parametrisationRule.displayName != null && parametrisationRule.displayName != '';

  useEffect(() => {
    setTrafficLightsColors(getAllTrafficLightObjects());

    const parameterEntries = ParameterConstants.financialServiceProviderParametrisationParameters;
    setParameterEntries(parameterEntries);

    setFormularEditorVariables(parameterData.map((parameter: Parameter) => ({
      id: `_Parameter_${parameter.id}_`,
      label: parameter.displayName
    })));

    setFormularEditorConstants(parameterEntries.map((parameterEmtry: SelectOption<string>) => ({
      id: parameterEmtry.value,
      label: parameterEmtry.label
    })))
  }, []);

  useEffect(() => {
    if (parametrisationRuleToEdit) {
      setParametrisationRule(parametrisationRuleToEdit);
      setUpsertType(UpsertParametrisationRuleDialogType.edit);
    }
    else {
      setUpsertType(UpsertParametrisationRuleDialogType.add);
    }
  }, [parametrisationRuleToEdit])


  const handleEntering = () => {
    if (ruleNameTxtRef.current != null) {
      ruleNameTxtRef.current.focus();
    }
  };

  const resetSelection = () => {
    setParametrisationRule({});
  }

  const handleCancel = () => {
    resetSelection();
    setParametrisationConnectedParametrisationRulesReload(new Date());
    setShowCreateNewParametrisationRuleDialog(false);
  };

  const handleOk = async (): Promise<void> => {
    if (!ruleIsValid || !selectedStoryFeature || !parametrisationRule)
      return;

    switch (upsertType) {
      case UpsertParametrisationRuleDialogType.add:
        await currentParametrisationFeatureApiService.createParametrisationRuleForParametrisationFeature({
          storyFeatureId: selectedStoryFeature.id,
          parametrisationRule: parametrisationRule as ParametrisationRule
        })
        break;

      case UpsertParametrisationRuleDialogType.edit:
        await currentParametrisationRuleApiService.updateParametrisationRule(parametrisationRule as ParametrisationRule);
        setParametrisationRuleToEdit(undefined);
        break;

      default:
        return;
    }

    resetSelection();
    setParametrisationConnectedParametrisationRulesReload(new Date());
    setShowCreateNewParametrisationRuleDialog(false);
  };


  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      onEntering={handleEntering}
      aria-labelledby="add-parametrisation-rules-dialog-title"
      open={showCreateNewParametrisationRuleDialog}
      classes={dialogClasses}
      keepMounted={keepMounted}
    >
      <DialogContent>
        <div className={classNames(classes.dialogContentRow, classes.dialogContentGrid)}>
          <Grid container spacing={1} alignItems="flex-end"
          >
            <Grid item xs={2}>
              <AvatarMaterialUiIconPicker
                selectedIconKey={parametrisationRule.iconKey ?? null}
                onSelectedIconKeyChange={(newIconKey: string) => {
                  const updateParametrisationRule = Object.assign({}, parametrisationRule);
                  updateParametrisationRule.iconKey = newIconKey;
                  setParametrisationRule(updateParametrisationRule);
                }}
                avatarClassName={classNames(classes.avatar,
                  {
                    [classes.greenAvatar]: parametrisationRule.trafficLight === TrafficLight.green,
                    [classes.yellowAvatar]: parametrisationRule.trafficLight === TrafficLight.yellow,
                    [classes.redAvatar]: parametrisationRule.trafficLight === TrafficLight.red,
                  }
                )}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                label={t('ParametrisationFeatureConfig:upsertRuleDialog.ruleName')}
                value={parametrisationRule.displayName ?? ''}
                onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  const updateParametrisationRule = Object.assign({}, parametrisationRule);
                  updateParametrisationRule.displayName = event.target.value;
                  setParametrisationRule(updateParametrisationRule);
                }}
                fullWidth
                ref={ruleNameTxtRef}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classNames(classes.dialogContentRow, classes.formularEditorRow)}>
          <FormularEditor
            calculationFormular={parametrisationRule.condition ?? ""}
            onCalculationFormularChanged={(newFormal: string, readableFormular?: string) => {
              const updateParametrisationRule = Object.assign({}, parametrisationRule);
              updateParametrisationRule.condition = newFormal;
              updateParametrisationRule.readableCondition = readableFormular;
              setParametrisationRule(updateParametrisationRule);
            }}
            operatorType={"all"}
            showRemoveButton={true}
            showVariablesInput={true}
            variables={formularEditorVariables}
            showConstantVariablesInput={true}
            constantVariables={formularEditorConstants}
            logicalTerm={true}
            showNumberInput={true}
            label={t('ParametrisationFeatureConfig:upsertRuleDialog.condition')}
          />
        </div>
        <div className={classes.dialogContentRow}>
          <Autocomplete
            options={trafficLightsColors}
            getOptionLabel={(option: TrafficLightSelectOption) => option.label}
            fullWidth
            renderInput={(params) =>
              <TextField {...params} label={t('ParametrisationFeatureConfig:upsertRuleDialog.trafficLight')}
                error={selectedTraficLight == null}
                helperText={selectedTraficLight != null ? "" : t('ParametrisationFeatureConfig:upsertRuleDialog.needValue')}
              />}
            value={selectedTraficLight}
            onChange={(event: any, newValue: TrafficLightSelectOption | null) => {
              const updateParametrisationRule = Object.assign({}, parametrisationRule);
              updateParametrisationRule.trafficLight = newValue?.value;
              setParametrisationRule(updateParametrisationRule);
            }}
          />
        </div>
        <div className={classes.dialogContentRow}>
          <TextParameterCreator
            parameters={parameterEntries}
            textValue={parametrisationRule.hint}
            onTextValueChange={(newHint: string) => {
              const updateParametrisationRule = Object.assign({}, parametrisationRule);
              updateParametrisationRule.hint = newHint;
              setParametrisationRule(updateParametrisationRule);
            }}
            label={t('ParametrisationFeatureConfig:upsertRuleDialog.hint')}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="secondary">
          {t('ParametrisationFeatureConfig:upsertRuleDialog.cancel')}
        </Button>
        <Button
          onClick={handleOk}
          color="primary"
          disabled={!ruleIsValid}
        >
          {
            upsertType == UpsertParametrisationRuleDialogType.edit
              ? t('ParametrisationFeatureConfig:upsertRuleDialog.save')
              : t('ParametrisationFeatureConfig:upsertRuleDialog.create')
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpsertParametrisationRuleDialog;