import { createFormularElement, FormularElement, Variable } from ".";
import { FormularElementType } from "../../enums/FormularEditor";

type VariableFormularElement = FormularElement & {
  variable: Variable
};

export default VariableFormularElement;

export function createVariableFormularElement(variable: Variable, id: number | FormularElement[]): VariableFormularElement {

  return {
    ...createFormularElement(variable.label, id),
    type: FormularElementType.variable,
    variable: variable
  }
}