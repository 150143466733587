import React, { FunctionComponent } from "react";
import {
  Breadcrumbs,
} from "@material-ui/core";
import { Typography } from '../../components/MaterialUiAdmin/Wrappers'
import { PageTitle } from '../../components/PageTitle';
import { Helmet } from 'react-helmet';
import { ExtendedRouteProps } from "../../domain/App";
import { SidebarLinkProps } from "../../app/components/SidebarLink";
import { getSidebarStructure } from "../../pages/routes";
import useStyles from './BasePage.styles'
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export type BasePageProps = {
  title?: string
  titleKey?: string
  route?: ExtendedRouteProps
}

const BasePage: FunctionComponent<BasePageProps> = ({ title, titleKey, children, route }) => {
  const classes = useStyles();
  const { t } = useTranslation('RoutesConstants', {useSuspense: false});

  const getBreadCrumb = function (route: ExtendedRouteProps): SidebarLinkProps[] | null {
    const structure: SidebarLinkProps[] = getSidebarStructure();
    const structureLength = structure.length;
    if (!route.path)
      return null;
    const searchedLink: string = route.path?.toString();
    for (let i = 0; i < structureLength; i++) {
      const structureElement: SidebarLinkProps = structure[i];
      const returnValue: SidebarLinkProps[] | null = getBreadCrumbRecursive(structureElement, searchedLink);
      if (!returnValue || !returnValue.length)
        continue;

      return returnValue;
    }

    return null;
  }

  const getBreadCrumbRecursive = function (sidenarLink: SidebarLinkProps, searchedLink: string): SidebarLinkProps[] | null {
    if (!sidenarLink)
      return null;

    // current sidebar link is searched one
    if (sidenarLink.link === searchedLink)
      return [sidenarLink];

    const childrenLength = sidenarLink.children ? sidenarLink.children.length : 0

    // not child => end of search
    // vs not checking the ? :, that's why !sidenarLink.children
    if (!sidenarLink.children || childrenLength < 1)
      return null;

    for (let i = 0; i < childrenLength; i++) {
      const currentChild: SidebarLinkProps = sidenarLink.children[i];
      const childReturn: SidebarLinkProps[] | null = getBreadCrumbRecursive(currentChild, searchedLink);
      // child doesnt find the searched value => go to next child
      if (!childReturn || !childReturn.length)
        continue;

      // child found value, concat current link with child
      let returnValues = [sidenarLink];
      returnValues = returnValues.concat(childReturn);
      return returnValues;
    }

    // no child has searched value
    return null;
  }

  const breadCrumbs: SidebarLinkProps[] | null = route ? getBreadCrumb(route) : null;
  const breadCrumbsLength: number = breadCrumbs ? breadCrumbs.length : 0;

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{title ? title : titleKey ? t(titleKey) : ''}</title>
      </Helmet>
      <div className={classes.titleContainer}>
        < PageTitle titleKey={titleKey} />
        {
          breadCrumbs &&
          <Breadcrumbs aria-label="breadcrumb" maxItems={2} className={classes.breadcrumbContainer}
            classes={{
              separator: classes.breadcrumbSeparator
            }}
          >
            {
              breadCrumbs.map((value: SidebarLinkProps, index: number) =>
                <Typography
                  className={classNames(classes.breadcrumbTypography, {
                    [classes.breadcrumbLastTypography]: index === breadCrumbsLength - 1,
                  })}
                  weight={index === breadCrumbsLength - 1 ? "bold" : "medium"}
                  size="md"
                  key={index}
                >
                  {value.label ? t(value.label) : ''}
                </Typography>)
            }
          </Breadcrumbs>
        }
      </div>
      {children}
    </div>
  );
};
export default BasePage;
