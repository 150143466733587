import React, { FunctionComponent, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bank, BankVisibilityParameterSets } from "../../domain/Bank";
import { RootState } from "../../store/reduxStore";
import { setSelectedParameter } from "../../store/actions/banksPage/actions";
import { BankHeaderInformation } from "../../components/Banks";

const BanksPageHeaderInfo: FunctionComponent = () => {
  const dispatch = useDispatch();

  const selectedBank: Bank | undefined = useSelector((state: RootState) => state.banksPage.selectedBank);
  const selectedParameter: BankVisibilityParameterSets | undefined = useSelector((state: RootState) => state.banksPage.selectedParameter);
  const selectParameter = (parameter?: BankVisibilityParameterSets) => {
    dispatch(setSelectedParameter(parameter));
  }

  const bankBreadCrumbClicked = () => {
    selectParameter(undefined);
  }

  return (
    <BankHeaderInformation
      selectedBank={selectedBank}
      onSelectedBankClick={bankBreadCrumbClicked}
      selectedParameter={selectedParameter}
    />
  );
}

export default BanksPageHeaderInfo;
