import { RLDDItem } from "react-list-drag-and-drop/lib/RLDD";
import { FormularElementType } from "../../enums/FormularEditor";

interface FormularElement extends RLDDItem{
  label: string
  type: FormularElementType
  // value: any,
  // name: string
}

export default FormularElement;

export function getNewIdForFormularElement(id: number | FormularElement[]): number {
  return Array.isArray(id)
    ? id.length
      ? Math.max(...(id as FormularElement[]).map((f: FormularElement) => f.id)) + 1
      : 0
    : id;
}

export function createFormularElement(label: string, id: number | FormularElement[]): FormularElement {
  const newId: number = getNewIdForFormularElement(id);

  return {
    type: FormularElementType.unknown,
    label,
    id: newId
  }
}