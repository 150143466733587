import { ApiError } from './';

export default class ApiResponse<T> {
  data?: T | null;
  apiCallWasSuccessful: boolean;
  errors: ApiError[];

  constructor(apiCallWasSuccessful: boolean, data?: T | null, errors: ApiError[] = []) {
    this.data = data;
    this.errors = errors;
    this.apiCallWasSuccessful = apiCallWasSuccessful;
  }
}