import { Accordion, AccordionDetails, AccordionSummary, Typography, Paper, InputBase, IconButton } from "@material-ui/core";
import React, { useState, FunctionComponent } from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { Feature } from "../../../../../../../../domain/StoryFeature";
import useStyles from './FeaturesToolbar.styles';
import { RootState } from "../../../../../../../../store/reduxStore";
import { BankParameterPageActions } from "../../../../../../../../store/actions";
import { FeatureControlBox } from "./components";
import { useTranslation } from "react-i18next";


const FeaturesToolbar: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('BankParameter');

  const selectFeatures = useSelector((state: RootState) => state.bankParameterPage.selectFeatures);

  const selectStoryFeaturesFilter = useSelector((state: RootState) => state.bankParameterPage.selectStoryFeaturesFilter);
  const setSelectStoryFeaturesFilter = (filter: string | null) => {
    dispatch(BankParameterPageActions.setSelectStoryFeaturesFilter(filter));
  }

  const shownFeatures: Feature[] = selectStoryFeaturesFilter && selectStoryFeaturesFilter !== ''
    ? selectFeatures.filter((f: Feature) => f && f.name.toLowerCase().includes(selectStoryFeaturesFilter.toLowerCase()))
    : selectFeatures;

  const [controlsExpanded, setControlsExpanded] = useState<boolean>(true);

  return (
    <div className={classes.root}>
      <Paper component="form" className={classes.rootSearch}>
        <InputBase
          className={classes.input}
          placeholder={t('BankParameter:searchFeature')}
          inputProps={{ 'aria-label': t('BankParameter:searchFeatureInput') }}
          value={selectStoryFeaturesFilter ?? ''}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => { setSelectStoryFeaturesFilter(event.target.value) }}
        />
        <IconButton type="button" className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
        <IconButton type="button" className={classes.iconButton} aria-label="search" onClick={() => { setSelectStoryFeaturesFilter(null) }}>
          <ClearIcon />
        </IconButton>
      </Paper>
      <Accordion
        expanded={controlsExpanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => { setControlsExpanded(!controlsExpanded) }}
        >
          <Typography className={classes.heading}>Controls</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {
              shownFeatures.map((feature: Feature, index: number) => <FeatureControlBox feature={feature} key={index} />)
            }
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default FeaturesToolbar;