import React, { FunctionComponent, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Bank, BankVisibilityParameterSets } from "../../domain/Bank";
import { ParametrisationFeatureConfigurationPageActions } from "../../store/actions";
import { toggleIsPageLoading } from "../../store/actions/uiActions/actions";
import { RootState } from "../../store/reduxStore";
import useStyles from './ParametrisationFeatureConfigurationPage.styles'
import { ImportParametrisationRulesDialog, ParametrisationRulesViewTable, UpsertParametrisationRuleDialog } from "./components";
import { StoryFeature } from "../../domain/StoryFeature";
import { currentStoryFeatureApiService } from "../../services/Story/StoryFeatureApi";
import ApiError, { isApiCallError } from "../../domain/Api/ApiError";
import { AlertObject } from "../../domain/Parametrisation";
import { currentMarketplaceApiService } from "../../services/MarketplaceApi";
import { useTranslation } from 'react-i18next';

type ParametrisationFeatureConfigurationPageUrlParams = {
  bankId: string | undefined
  productId: string | undefined
  storyFeatureId: string | undefined
}

const ParametrisationFeatureConfigurationPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation('ParametrisationFeatureConfig');

  const setIsPageLoading = (isLoading: boolean) => {
    dispatch(toggleIsPageLoading(isLoading));
  }

  const setAlertObject = (alertObject?: AlertObject) => {
    dispatch(ParametrisationFeatureConfigurationPageActions.setAlertObject(alertObject));
  }

  const { bankId: urlBankId, productId: urlProductId, storyFeatureId: urlStoryFeatureId } = useParams<ParametrisationFeatureConfigurationPageUrlParams>();
  const storyFeatureId = Number(urlStoryFeatureId);
  const selectedBank: Bank | undefined = useSelector((state: RootState) => state.parametrisationFeatureConfigurationPage.selectedBank);
  const selectBank = (bank?: Bank) => {
    dispatch(ParametrisationFeatureConfigurationPageActions.setSelectedBank(bank));
  }

  const selectedParameter: BankVisibilityParameterSets | undefined = useSelector((state: RootState) => state.parametrisationFeatureConfigurationPage.selectedParameter);
  const setSelectedParameter = (parameter?: BankVisibilityParameterSets) => {
    dispatch(ParametrisationFeatureConfigurationPageActions.setSelectedParameter(parameter));
  }

  const setSelectedStoryFeature = (storyFeature?: StoryFeature) => {
    dispatch(ParametrisationFeatureConfigurationPageActions.setSelectedStoryFeature(storyFeature));
  }

  useEffect(
    () => {
      const loadData = async () => {
        setIsPageLoading(true);
        setAlertObject(undefined);
        let searchBank: Bank | undefined;
        let searchParameter: BankVisibilityParameterSets | undefined;
        if (urlBankId && urlProductId) {
          const loadResponse = await currentMarketplaceApiService.getBankAndBankVisibilityParameter(Number(urlProductId));
          if (loadResponse && !isApiCallError(loadResponse)) {
            searchBank = loadResponse.bank;
            searchParameter = loadResponse.bankVisibilityParameter
          }
        }
        selectBank(searchBank);
        setSelectedParameter(searchParameter);

        let searchStoryFeature: StoryFeature | undefined;
        if (!isNaN(storyFeatureId)) {
          const getStoryFeatureResponse = await currentStoryFeatureApiService.getStoryFeatureWithFeature(storyFeatureId);
          if (!getStoryFeatureResponse || isApiCallError(getStoryFeatureResponse)) {
            setAlertObject({
              message: `${t('ParametrisationFeatureConfig:configPage.loadFail')} ${(getStoryFeatureResponse ? (getStoryFeatureResponse as ApiError).getMessage() : '')}`,
              severity: "error"
            })
          }
          else
            searchStoryFeature = getStoryFeatureResponse as StoryFeature;
        }
        setSelectedStoryFeature(searchStoryFeature);

        // if (searchStoryFeature)
        // await loadParametrisationRulesOnPageLoad(searchStoryFeature);

        setIsPageLoading(false);
      }
      loadData();

      return () => {
        setSelectedStoryFeature(undefined);
      }
    },
    [urlBankId, urlProductId, urlStoryFeatureId]
  )

  return (
    <div className={classes.root}>
      {selectedParameter
        ? <>
          <Helmet>
            <title>{
              `
              ${selectedBank ? `${selectedBank.attributes["display-name"].trim()} -  ` : ''}
              ${selectedParameter ? `${selectedParameter.attributes.title} - ` : ''}
              ${t('ParametrisationFeatureConfig:configPage.param')}
              `
            }</title>
          </Helmet>
          <div className={classes.grow}>
            <ParametrisationRulesViewTable />
          </div>
          <UpsertParametrisationRuleDialog
            dialogClasses={{
              paper: classes.upsertParametrisationRuleDialog
            }}
            keepMounted
          />
          <ImportParametrisationRulesDialog
            dialogClasses={{
              paper: classes.importParametrisationRulesDialog
            }}
            keepMounted
          />
        </>
        : <p>{t('ParametrisationFeatureConfig:configPage.loading')}</p>
      }
    </div>

  );
};

export default ParametrisationFeatureConfigurationPage;

