// import tinycolor from "tinycolor2";

import { ThemeOptions } from "@material-ui/core";

export const lightTheme: ThemeOptions = {
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  direction: "ltr",
  mixins: {
    toolbar: {
      minHeight: 56,
      "@media (min-width:0px) and (orientation: landscape)": {
        minHeight: 48,
      },
      "@media (min-width:600px)": {
        minHeight: 64,
      },
    },
  },
  overrides: {
    MuiTableContainer: {
      root: {
        background: "#fff",
      },
    },
  },
  palette: {
    //Compeon yellow shades
    type: "light",
    primary: {
      main: "#0081ed",
      light: "#bdc9cc",
      dark: "#0062b3",
      contrastText: "#fff",
    },
    //Compeon blue shades
    secondary: {
      main: "#fab400",
      light: "#ffcc4d",
      dark: "#e6a400",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    background: {
      //Use default for items only e.g. <Cards>, Icons, etc...
      default: "#fff",
      transparentPaper: "#fff",
    },
    header: {
      main: "#fab400",
      light: "",
      dark: "",
      contrastText: "#fff",
    },
    link: {
      main: "#0081ed",
    },
  },
  props: {},
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: "6rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: "3.75rem",
      lineHeight: 1.2,
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: 1.167,
      letterSpacing: "0em",
    },
    h4: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
    },
    h5: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em",
    },
    h6: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
    subtitle1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.75,
      letterSpacing: "0.00938em",
    },
    subtitle2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.57,
      letterSpacing: "0.00714em",
    },
    body1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    body2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.43,
      letterSpacing: "0.01071em",
    },
    button: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      textTransform: "uppercase",
    },
    caption: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: "0.03333em",
    },
    overline: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 2.66,
      letterSpacing: "0.08333em",
      textTransform: "uppercase",
    },
  },
  shape: {
    borderRadius: 4,
  },
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  content: {
    background:
      "linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url('https://images-cms.compeon.de/spai/w_1920+q_glossy+ret_img/https://www.compeon.de/wp-content/uploads/2017/12/Parallax.jpg') repeat center center",
    // background: `linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url('${cOTeamsClassic}') no-repeat center center`,
    backgroundSize: "cover",
  },
  pageTitle: {
    color: "#fff",
  },
  drawerFooter: {
    background:
      "url(https://www.compeon.de/wp-content/uploads/2017/07/Logo-Desktop.png) center center",
    height: "50px",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  customShadows: {
    widget:
      "0px 0px 0px 0px #E8EAFC, 0 0px 0px 0px #B2B2B21A, 0 0px 0px 0 #9A9A9A1A",
    widgetDark:
      "0px 0px 0px 0px #4558A3B3, 0 0px 0px 0px #B2B2B21A, 0 0px 0px 0 #9A9A9A1A",
    widgetWide:
      "0px 0px 0px 0px #E8EAFC, 0 0px 0px 0px #B2B2B21A, 0 0px 0px 0 #9A9A9A1A",
  },
  compeonLogo: {
    color: '#00283e'
  },
  chonky: {
    colors: {
      debugRed: "#fabdbd",
      debugBlue: "#bdd8fa",
      debugGreen: "#d2fabd",
      debugPurple: "#d2bdfa",
      debugYellow: "#fae9bd",
      datacenterBlue: "#09f",

      textActive: "#09f",
    },
    fontSizes: {
      rootPrimary: 15,
    },
    margins: {
      rootLayoutMargin: 8,
    },
    toolbar: {
      size: 30,
      lineHeight: "30px", // `px` suffix is required for `line-height` fields to work
      fontSize: 15,
      buttonRadius: 4,
    },
    dnd: {
      canDropColor: "green",
      cannotDropColor: "red",
      canDropMask: "rgba(180, 235, 180, 0.75)",
      cannotDropMask: "rgba(235, 180, 180, 0.75)",
      fileListCanDropMaskOne: "rgba(180, 235, 180, 0.1)",
      fileListCanDropMaskTwo: "rgba(180, 235, 180, 0.2)",
      fileListCannotDropMaskOne: "rgba(235, 180, 180, 0.1)",
      fileListCannotDropMaskTwo: "rgba(235, 180, 180, 0.2)",
    },
    dragLayer: {
      border: "solid 2px #09f",
      padding: "7px 10px",
      borderRadius: 2,
    },
    fileList: {
      desktopGridGutter: 8,
      mobileGridGutter: 5,
    },
    gridFileEntry: {
      childrenCountSize: "1.6em",
      iconColorFocused: "#000",
      iconSize: "2.4em",
      iconColor: "#fff",
      borderRadius: 5,
      fontSize: 14,

      fileColorTint: "rgba(255, 255, 255, 0.4)",
      folderBackColorTint: "rgba(255, 255, 255, 0.1)",
      folderFrontColorTint: "rgba(255, 255, 255, 0.4)",
    },
    listFileEntry: {
      propertyFontSize: 14,
      iconFontSize: "1.1em",
      iconBorderRadius: 5,
      fontSize: 14,
      propertyHeaderFontSize: 12,
    },
  },
};
