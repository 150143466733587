import { ApiEnums } from '../../enums';
import { ErrorCodeConstants } from "../../constants";
import { ApiCallRelationship } from './'
import { Dictionary } from 'lodash';

export default class ApiBaseResponse {
  errorCode: number;
  errorMessage: string;
  status: ApiEnums.ResponseStatus;
  relationships: Dictionary<ApiCallRelationship>

  constructor(status: ApiEnums.ResponseStatus, relationships: Dictionary<ApiCallRelationship>, errorMessage: string, errorCode: number = ErrorCodeConstants.success) {
    this.status = status;
    this.errorCode = errorCode;
    this.relationships = relationships;
    this.errorMessage = errorMessage;
  }
}